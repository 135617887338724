<template>
  <div>
    <v-navigation-drawer class="v-navigation-drawer" width="380" v-model="drawer" app color="primary">

      <v-list-item class="block px-7">
        <v-list-item-avatar>
          <v-icon large>mdi-domain</v-icon>
        </v-list-item-avatar>

        <v-list-item-title class="admin-title">AdminMilana</v-list-item-title>

      </v-list-item>
      <v-divider class="divider"></v-divider>
      <v-list-item class="block px-7">
        <v-list-item-avatar>
          <v-img :src="$getAvatarImage(me)"></v-img>
        </v-list-item-avatar>

        <v-list-item-title class="user-name" v-if="me">{{ $getUserName(me) }}</v-list-item-title>
        <v-btn v-if="!$auth.isAdmin" outlined dark @click="changeRoute($auth.isOperator ? '/operator-info' : '/agency-info')">INFO</v-btn>

      </v-list-item>


      <v-divider class="divider"></v-divider>

      <v-autocomplete
          class="ml-5 mr-5 mt-5"
          v-model="value"
          :items="links.map(e => e.items).flat()"
          @change="changeRoute"
          item-text="text"
          item-value="route"
          dark
          solo-inverted
          :label="this.$t('translation.GLOBAL_VALUE.search')"
          clearable

      ></v-autocomplete>
      <v-divider class="divider"></v-divider>

      <div class="component">
        <v-btn
            v-if="$auth.isAdmin || $auth.isOperator"
            outlined
            dark
            class="navigation-btn"
            @click="changeRoute('/')"
        >
          <v-icon class="mr-5 ml-5">mdi-monitor-dashboard</v-icon>
          {{ this.$t('translation.NAVBAR.DASHBOARD.items.dashboard_page') }}
        </v-btn>
        <v-btn
            v-if="$auth.isOperator"
            outlined
            dark
            class="navigation-btn"
            @click="changeRoute('/users')"
        >
          <v-icon class="mr-5 ml-5">mdi-account-group</v-icon>
          {{ this.$t('translation.NAVBAR.MANAGE_USERS.items.users_page') }}
        </v-btn>
        <v-btn
            v-if="!$auth.isAdmin && !$auth.isOperator"
            outlined
            dark
            class="navigation-btn"
            @click="changeRoute('/agencies')"
        >
          <v-icon class="mr-5 ml-5">mdi-clipboard-list</v-icon>
          {{ translation.MANAGE_AGENCY.title }}
        </v-btn>
        <v-menu offset-x v-for="link in links" :key="link.title">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                dark
                class="navigation-btn"
            >
              <v-icon class="mr-5 ml-5">{{ link.titleIcon }}</v-icon>
              {{ link.title }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in link.items" :key="item.text" :to="item.route" link>
              <v-list-item-action>
                <v-icon v-text="link.icon"> {{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="link-text"> {{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
            v-if="this.$auth.isAdmin"
            outlined
            dark
            class="navigation-btn"
            @click="changeRoute('/configuration')"
        >
          <v-icon class="mr-5 ml-5">mdi-cog</v-icon>
          {{ this.$t('translation.NAVBAR.CONFIGURATION.items.configuration_page') }}
        </v-btn>
        <v-btn
            v-if="this.$auth.isAdmin"
            outlined
            dark
            class="navigation-btn"
            @click="changeRoute('/campaigns')"
        >
          <v-icon class="mr-5 ml-5">mdi-animation</v-icon>
          Campaigns
        </v-btn>
        <v-btn
            v-if="this.$auth.isAdmin"
            outlined
            dark
            class="navigation-btn"
            @click="changeRoute('/email-manager')"
        >
          <v-icon class="mr-5 ml-5">mdi-email-sync</v-icon>
          {{ this.$t('translation.NAVBAR.EMAIL_MANAGER.items.email_manager_page') }}
        </v-btn>
        <v-btn
            v-if="this.$auth.isAdmin"
            outlined
            dark
            class="navigation-btn"
            @click="changeRoute('/key-edit')"
        >
          <v-icon class="mr-5 ml-5">mdi-translate</v-icon>
          Translation
        </v-btn>
        <v-btn
            v-if="!$auth.isAdmin && !$auth.isOperator"
            outlined
            dark
            class="navigation-btn"
            @click="changeRoute('/agency-notifications')"
        >
          <v-icon class="mr-5 ml-5">mdi-bell-ring</v-icon>
          NOTIFICATIONS
        </v-btn>


      </div>
    </v-navigation-drawer>

    <nav>
      <v-app-bar flat app color="white" class="app-bar">
        <v-app-bar-nav-icon class="test" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-uppercase grey--text">
          <router-link :to="{name: 'dashboard'}" class="link">{{ translation.btn_home }}</router-link>
          <router-link :to="{name: 'faq-page'}" class="ml-5 link">FAQ</router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="mr-3"
        ></v-progress-circular>
        <v-select
            v-model="selectedLanguage"
            hide-details
            text
            dense
            color="primary"
            :items="languages"
            item-text="text"
            item-value="value"
            outlined
            @change="changeLanguage"
            class="languages"
        >
        </v-select>
        <v-btn text color="primary" @click="logout">
          <span>{{ translation.btn_logout }}</span>
          <v-icon right>mdi-exit-to-app</v-icon>
        </v-btn>
      </v-app-bar>
    </nav>

  </div>
</template>

<script>
import {removeUserAuth} from '@/providers/auth';
import {mapGetters} from 'vuex';
import store from "@/store";
import {api} from "@/providers/api";

export default {
  name: 'MyNavbar',
  data: () => ({
    loading: false,
    drawer: true,
    mini: true,
    value: '',
    selectedLanguage: '',

  }),


  methods: {

    async changeLanguage(val) {
      try {
        this.loading = true
        await api.patch(`/admin/language-translates/language/${this.$store.state.translation.code}`, {
          isDefault: false,
          isActive: false
        })
        await api.patch(`/admin/language-translates/language/${val}`, {
          isDefault: true,
          isActive: true
        })
        await this.$store.dispatch('fetchTranslation')
        if (val === 'en') {
          return this.$i18n.locale = 'en'
        }
        if (val === 'ua') {
          return this.$i18n.locale = 'ua'
        }
        if (val === 'ru') {
          return this.$i18n.locale = 'ru'
        }

      } catch (e) {
        console.log('Error', e)
      } finally {
        this.loading = false
      }


    },


    changeRoute(item) {
      this.$router.push({path: item})
    },

    logout() {
      removeUserAuth();
    },

  },

  computed: {

    ...mapGetters('User', ['me']),
    $devDeployTarget() {
      return process.env.VUE_APP_DEPLOY_TARGET === 'dev'
    },
    languages() {
      let arr = []
      switch (this.$store.state.translation.code) {
        case 'en':
          arr = [
            {text: 'English', value: 'en'},
            {text: 'Ukrainian', value: 'ua'},
            {text: 'Russian', value: 'ru'},
          ]
          break
        case 'ua':
          arr = [
            {text: 'Англійська', value: 'en'},
            {text: 'Українська', value: 'ua'},
            {text: 'Російська', value: 'ru'},
          ]
          break
        case 'ru':
          arr = [
            {text: 'Английский', value: 'en'},
            {text: 'Украинский', value: 'ua'},
            {text: 'Русский', value: 'ru'},
          ]
          break
      }
      return arr
    },
    translation() {
      return this.$t('translation.NAVBAR')
    },
    links() {
      const isAdmin = this.$auth.isAdmin;
      const isOperator = this.$auth.isOperator;
      console.log('isOperator', isOperator)
      const translations = this.$t('translation');

      const userLinks = isAdmin
          ? [
            {
              title: translations.NAVBAR.MANAGE_USERS.title,
              titleIcon: 'mdi-account-switch',
              items: [
                {icon: 'mdi-account-group', text: translations.NAVBAR.MANAGE_USERS.items.users_page, route: '/users'},
                {
                  icon: 'mdi-account-details',
                  text: translations.NAVBAR.MANAGE_USERS.items.role_manager_page,
                  route: '/role-manager'
                },
                {
                  icon: 'mdi-account-circle',
                  text: translations.NAVBAR.MANAGE_USERS.items.moderation_page,
                  route: '/moderation-users-log'
                }
              ]
            }
          ]
          : [];

      const agencyLinks = isAdmin
          ? [
            {
              title: translations.NAVBAR.MANAGE_AGENCY.title,
              titleIcon: 'mdi-clipboard-list',
              items: [
                {
                  icon: 'mdi-format-list-bulleted-square',
                  text: translations.NAVBAR.MANAGE_AGENCY.items.agency_page,
                  route: '/agencies'
                },
                {
                  icon: 'mdi-file-sign',
                  text: translations.NAVBAR.MANAGE_AGENCY.items.agency_contract_file,
                  route: '/agency-contract'
                },
                {
                  icon: 'mdi-account-details',
                  text: translations.NAVBAR.MANAGE_AGENCY.items.agency_application,
                  route: '/agency-created-by-users'
                }
              ]
            }
          ]
          : [];

      const logLinks = [
        {
          title: translations.NAVBAR.LOGS.title,
          titleIcon: 'mdi-table-multiple',
          items: [
            {icon: 'mdi-chat', text: translations.NAVBAR.LOGS.items.chat_log_page, route: '/chat-log'},
            {
              icon: 'mdi-clipboard-text-clock-outline',
              text: translations.NAVBAR.LOGS.items.chat_dates_log_page,
              route: '/chat-dates'
            },
            // {icon: 'mdi-post', text: translations.NAVBAR.LOGS.items.blog_log_page, route: '/blog-log'},
            {icon: 'mdi-thumb-up', text: translations.NAVBAR.LOGS.items.like_log_page, route: '/like-log'},
            {icon: 'mdi-email', text: 'Letter Log', route: '/letter-log'},
            // {
            //   icon: 'mdi-card-account-mail',
            //   text: translations.NAVBAR.LOGS.items.contact_log_page,
            //   route: '/contact-log'
            // },
            {icon: 'mdi-gift', text: translations.NAVBAR.LOGS.items.flirt_log_page, route: '/flirt-logs'}
          ].filter(r => isAdmin || isOperator || !['/blog-log', '/like-log', '/contact-log', '/letter-log',].includes(r.route))
        }
      ];

      const paymentLinks = isAdmin
          ? [
            {
              titleIcon: 'mdi-account-credit-card',
              title: translations.NAVBAR.PAYMENTS.title,
              items: [
                {
                  icon: 'mdi-animation',
                  text: translations.NAVBAR.PAYMENTS.items.membership_log_page,
                  route: '/memberships'
                },
                {
                  icon: 'mdi-cash-clock',
                  text: translations.NAVBAR.PAYMENTS.items.payments_dates_log_page,
                  route: '/payments'
                },
                {
                  icon: 'mdi-calculator-variant',
                  text: translations.NAVBAR.PAYMENTS.items.commission_prices_page,
                  route: '/commission-prices'
                },
                {icon: 'mdi-cash', text: translations.NAVBAR.PAYMENTS.items.withdrawal_page, route: '/withdrawal'},
                {icon: 'mdi-cash-sync', text: "Credits price", route: '/credits-price'}
              ]
            }
          ]
          : [];

      const giftLinks = isAdmin
          ? [
            {
              titleIcon: 'mdi-gift-open',
              title: translations.NAVBAR.GIFT_SECTION.title,
              items: [
                {
                  icon: 'mdi-wallet-giftcard',
                  text: translations.NAVBAR.GIFT_SECTION.items.gift_shop_page,
                  route: '/girl-shop'
                }
              ]
            }
          ]
          : [];
      const feedbackLinks = isAdmin
          ? [
            {
              titleIcon: 'mdi-send-variant-clock',
              title: 'feedback',
              items: [
                {
                  icon: 'mdi-cancel',
                  text: 'Subscription Cancel',
                  route: '/subscription-cancel'
                }
              ]
            }
          ]
          : [];

      const contentLinks = isAdmin
          ? [
            {
              titleIcon: 'mdi-content-save-cog',
              title: translations.NAVBAR.CONTENT_MANAGER.title,
              items: [
                {
                  icon: 'mdi-frequently-asked-questions',
                  text: translations.NAVBAR.CONTENT_MANAGER.items.faq_page,
                  route: '/faq'
                },
                {icon: 'mdi-robot-outline', text: translations.NAVBAR.CONTENT_MANAGER.items.bot_page, route: '/bot'},
                {icon: 'mdi-emoticon', text: 'Emoji', route: '/emoji'},
              ]
            }
          ]
          : [];

      return [
        ...userLinks,
        ...agencyLinks,
        ...logLinks,
        ...paymentLinks,
        ...giftLinks,
        ...feedbackLinks,
        ...contentLinks
      ];
    }
  },

  async created() {
    await this.$store.dispatch('fetchCurrentRate')
    await this.$store.dispatch('fetchTranslations')
    this.$store.state.translations.forEach(item => {
      this.$i18n.setLocaleMessage(item.code, item)
    })
    this.selectedLanguage = this.$store.state.translation.code


  }
};
</script>

<style scoped lang="scss">
//.active_list .v-list-group .v-list-item--active {
//  color: #f4f4f4 !important;
//}
//.v-list-title{
//  color: white;
//  i{
//    color: white !important;
//  }
//}
.languages {
  max-width: 200px;
}

.component {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.navigation-btn {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}


.link {
  color: #2f55b4;
  text-decoration: none;
  font-weight: normal;
}

.block {
  padding-top: 4px;
  padding-bottom: 4px;

  .theme--light.v-icon {
    color: white;
  }

  .user-name {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
  }

  .admin-title {
    color: #fff;
    font-size: 20px;
  }
}

.links {
  border: 1px solid white;
  border-radius: 10px;
  margin: 10px;

  &::v-deep {

    .theme--light.v-list-item {
      border-radius: 10px;

    }

    .theme--light.v-list-item.v-list-item--active {
      background: yellow !important;

    }


    .v-list-group__header__append-icon {
      //display: none !important;

      i {
        color: white !important;
      }
    }
  }

  .sub-list {

  }

  .link-title {
    padding-top: 10px;
    padding-bottom: 0;
    padding-left: 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
  }

  .theme--light.v-icon {
    color: white;
  }

  .v-list-title {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
  }


  .v-list, .v-list-item {
    //margin: 5px;
  }

  .v-list .v-list-item--active {
    //margin-top: 5px;
    //margin-bottom: 5px;
    //border-radius: 10px;
    color: #2f55b4;
    background: #fff;

    .title, .link-text {
      color: #2f55b4;
    }

    .theme--light.v-icon {
      color: #2f55b4;
    }
  }

  .v-list-item--active:hover {
    //border-radius: 10px;
    //color: #2f55b4 !important;
    //background: #fff !important;
    //
    //.title, .link-text {
    //  color: #2f55b4 !important;
    //}
    //
    //.theme--light.v-icon {
    //  color: #2f55b4 !important;
    //}
  }

  .v-list-item--active::before {
    background: white;
  }

  .v-list-item:hover {
    //border-radius: 10px;
    //background: #fff;

    //.title, .link-text {
    //  color: #2f55b4
    //}
    //
    //.theme--light.v-icon {
    //  color: #2f55b4;
    //}
  }


  .title, .link-text {
    color: #fff;
    font-weight: bold;
  }
}

.divider {
  background: #fff
}

</style>
