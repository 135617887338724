<template>
  <div>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
              :headers="headers"
              :items="items"
              item-key="id"
              show-expand
              class="elevation-1"
              :expanded.sync="expanded"
              :single-expand="singleExpand"
              :loading="giftLoading"

      >

        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">{{ translationsGiftShop.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog
                    v-model="dialog"
                    max-width="500px"
            >
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-btn
                        color="white"
                        outlined
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                >
                  {{ translationsGiftShop.btn_new_gift }}
                </v-btn>
              </template>
              <v-card class="modal-add">
                <v-row>


                  <v-col cols="5">
                    <v-file-input
                            show-size
                            :label="translationsGiftShop.modal_file_label"
                            v-model="formFile"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                            :label="translationsGiftShop.modal_price_label"
                            v-model="price"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                            color="primary"
                            elevation="2"
                            @click="addGift"
                    >{{ translationsGiftShop.modal_btn_add }}
                    </v-btn>
                  </v-col>
                </v-row>

              </v-card>
            </v-dialog>
          </v-toolbar>


        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="row">
              <div class="user-img">
                <img :src="$getImage(item.file)">
              </div>

              <v-col>
                <v-text-field v-model="item.price" ></v-text-field >
                <v-btn color="green" dark @click="editGift(item)">{{ translations.GLOBAL_VALUE.save }}</v-btn>
                <v-btn class="red" dark @click="deleteGift(item.id)">{{ translations.GLOBAL_VALUE.remove }}</v-btn>
              </v-col>
            </div>

          </td>
        </template>

        <template v-slot:[`item.file`]="{ item }">
          <div class="user-img">
            <img :src="$getImage(item.file)">
          </div>
        </template>
      </v-data-table>
    </v-container>

  </div>
</template>

<script>
import {getGifts, deleteGift, postEditGift, postAddGift} from "@/api/giftRequest";


export default {
  name: 'GiftShop',
  data() {
    return { items: [], formFile: null , price: 0,
      headers: [
        {
          value: 'id',
          text: 'ID'
        },
        {
          value: 'file',
          text: 'FILE'
        },
        {
          value: 'price',
          text: 'PRICE'
        }
      ],
      expanded: [],
      singleExpand: true,
      dialog: false,
      giftLoading: false
    };
  },
  computed: {
    translations() {
      return this.$t('translation')
    },
    translationsGiftShop() {
      return this.translations.GIFT_SHOP.table
    }
  },
  created() {
    this.headers = [
      {
        value: 'id',
        text: 'ID'
      },
      {
        value: 'file',
        text: this.translationsGiftShop.headers.file
      },
      {
        value: 'price',
        text: this.translationsGiftShop.headers.price
      }
    ]
    this.fetchGiftsList();
  },
  methods: {
    async fetchGiftsList() {
      this.giftLoading = true
      this.items = await getGifts();
      this.giftLoading = false
      return this.items;
    },
    async deleteGift(id) {
      await deleteGift(id);
      this.fetchGiftsList();
    },
    async editGift(item) {
      await postEditGift(item.id, +item.price)
    },

    async addGift() {
      const formData = new FormData();
      formData.append('image', this.formFile);
      await postAddGift(this.price, formData)
      this.fetchGiftsList()
      this.formFile = null
      this.price = 0
      this.dialog = false
    },


  }
};
</script>

<style scoped lang="scss">
.v-card {
  /*display: flex;*/
  /*align-items: flex-start;*/
  background: none;

  .card-action{
    span{
      font-weight: bold;
    }
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
  .user-img{
    img{
      height: 150px;
      width: 150px;
    }
  }
  .row{
    margin: 10px;
    align-items: center;
  }
  .v-text-field{
    width: 300px;
  }

  .v-btn{
    margin-right: 10px !important;
  }
.title {
  color: #f4f6f9;
}
  .modal-add{
    background: white;

  }
</style>
