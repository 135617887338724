<template>
  <v-container class="my-5">
    <v-card width="max-content">
      <v-card-title>Telegram Bot</v-card-title>
      <v-card-actions>
        <v-btn
            color="primary"
            @click="openTelegramBot"
            outlined
            :loading="loading"
        >
          Open
        </v-btn>
      </v-card-actions>
      <v-card-subtitle class="pb-0">Notification settings</v-card-subtitle>
      <v-card-actions class="flex-column align-start">
        <v-switch
            label="Men Activities"
            hide-details
            @change="changeMenActivities"
            :disabled="switchLoading.men_activities"
            :input-value="findSwitch(TYPES.OPERATOR_NOTIFICATIONS_MAN_ACTIVITIES)"
        ></v-switch>
        <v-switch
            label="Ladies Activities"
            hide-details
            @change="changeLadiesActivities"
            :disabled="switchLoading.ladies_activities"
            :input-value="findSwitch(TYPES.OPERATOR_NOTIFICATIONS_WOMAN_ACTIVITIES)"
        ></v-switch>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

import {api} from "@/providers/api";
import {mapGetters} from "vuex";

export default {
  name: "OperatorInfo",
  data: () => ({
    loading: false,
    settings: {},
    switchLoading: {
      men_activities: false,
      ladies_activities: false,
    },
    TYPES: {
      OPERATOR_NOTIFICATIONS_MAN_ACTIVITIES: 'OPERATOR_NOTIFICATIONS_MAN_ACTIVITIES',
      OPERATOR_NOTIFICATIONS_WOMAN_ACTIVITIES: 'OPERATOR_NOTIFICATIONS_WOMAN_ACTIVITIES',
    }
  }),
  computed: {
    ...mapGetters('User', ['me']),
    findSwitch() {
      return type => this.settings.find(e => e.type === type)?.isEnabled
    },
  },
  async created() {
    await this.getUserSettings()
  },
  methods: {
    async getUserSettings() {
      this.settings = await api.get(`/users/${this.me.id}/notification-settings`)
    },
    async changeMenActivities(value) {
      this.switchLoading.men_activities = true
      await this.checkTypeInSettings(this.TYPES.OPERATOR_NOTIFICATIONS_MAN_ACTIVITIES, value)
      this.switchLoading.men_activities = false
    },
    async changeLadiesActivities(value) {
      this.switchLoading.ladies_activities = true
      await this.checkTypeInSettings(this.TYPES.OPERATOR_NOTIFICATIONS_WOMAN_ACTIVITIES, value)
      this.switchLoading.ladies_activities = false
    },
    async checkTypeInSettings(typeToCheck, isEnabled) {
      const found = this.settings.some(setting => setting.type === typeToCheck);
      if (found) {
        const { id } = this.settings.find(setting => setting.type === typeToCheck);
        await this.editTelegramSetting(id, isEnabled)
      } else {
        await this.createTelegramSetting(typeToCheck)
        await this.getUserSettings()
      }
    },
    async createTelegramSetting(type) {
      await api.post('/users-notifications/settings', {
        "isEnabled": true,
        "type": type,
        "channel": "TELEGRAM",
        "notifyMeLater": "DEFAULT"
      })
    },
    async editTelegramSetting(id, isEnabled) {
      await api.patch(`/users-notifications/settings/${id}`, {
        isEnabled
      })
    },

    async openTelegramBot() {
      this.loading = true
      const {link} = await api.get('/telegram-bot/link')
      const url = `${link}?start=${this.me.id}`;
      window.open(url, '_blank');
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">

</style>