<template>
  <v-data-table
      :footer-props="{'items-per-page-options': [10, 50, 100]}"
      :items-per-page="10"
      :headers="headersChat"
      :items="items"
      :server-items-length="totalItems"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      key="id"
      @click:row="pushToMessage"
  >

    <template v-slot:[`item.chat.members[0].name`]="{ item }">
      <div class="user-img" @click.stop="userPush(item.chat.members[0])">
        <img :src="$getAvatarImage(item.chat.members[0])">
        {{ item.chat.members[0].name }}

        <v-icon
            v-if="item.chat.members[0].id !== me.id && !$auth.isAdmin && !$auth.isOperator"
            class="ml-3"
            color="primary"
            @click.stop="$switchProfileUser(item.chat.members[0].id)"
        >
          mdi-account-arrow-left
        </v-icon>
      </div>
    </template>

    <template v-slot:[`item.chat.members[1].name`]="{ item }">
      <div v-if="item.chat.members.length > 1" class="user-img" @click.stop="userPush(item.chat.members[1])">
        <img :src="$getAvatarImage(item.chat.members[1])">
        {{ item.chat.members[1].name }}

        <v-icon
            v-if="item.chat.members[1].id !== me.id && !$auth.isAdmin && !$auth.isOperator"
            class="ml-3"
            color="primary"
            @click.stop="$switchProfileUser(item.chat.members[1].id)"
        >
          mdi-account-arrow-left
        </v-icon>
      </div>
    </template>

    <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>

    <template v-slot:[`item.gift.file`]="{ item }">
      <v-img :src="$getImage(item.gift.file)" max-height="50"
             max-width="50"></v-img>

    </template>
  </v-data-table>
</template>

<script>
import {getUserMessage} from "@/api/usersRequest";
import router from "@/routes/router";
import {mapGetters} from "vuex";
import {api} from "@/providers/api";

export default {
  name: "UserLogChatTable",
  props: {
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headersChat: [],
    options: {},
    totalItems: 0,
    loading: true,
    items: []
  }),
  watch: {
    refresh(status) {
      this.loading = status
      if (status) {
        this.options.page = 1
        this.syncDataFromApi()
      }
    },
    options: {
      handler() {
        this.syncDataFromApi()
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('User', ['me']),
    translations() {
      return this.$t('translation.USERS.USER_LOG.tabs_items.chat_table.headers')
    }
  },
  created() {
    this.headersChat =  [
      {
        text: this.translations.member_1,
        sortable: false,
        value: 'chat.members[0].name',
      },
      {
        text: this.translations.member_2,
        sortable: false,
        value: 'chat.members[1].name',
      },
      {
        text: this.translations.creator,
        sortable: false,
        value: 'chat.creator.name',
      },
      {
        text: this.translations.last_message,
        sortable: false,
        value: 'message.text',
      },

    ]
  },
  methods: {
    userPush(user) {
      if (Number(this.$route.params.id) !== user.id) {
        if (this.$auth.isAdmin) {
          router.push({name: 'user-log', params: {id: user.id}})
          this.$emit('reloadPage')
        }
        else {
          if (user.agency && Number(user.agency.agencyId) === Number(this.$route.params.id)) {
            router.push({name: 'user-log', params: {id: user.id}})
            this.$emit('reloadPage')
          }
        }
      }

    },
    async syncDataFromApi() {
      this.loading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options
        const response = await api.get(`/admin/chats/`, {
          params: {
            ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
            limit: limit === -1 ? 100 : limit,
            page,
            user_id: this.$route.params.id

          },

        })

        const {data: items, total} = response
        this.items = items;
        this.totalItems = total
      } catch (e) {
        console.log('Error', e)
      } finally {
        this.loading = false
        this.$emit('refreshStop', 'chat')
      }
    },

    async pushToMessage(message) {

      await getUserMessage(message.chat.id)

      await router.push({ name: 'user-messages-chat', params: { id: message.chat.id } });
    }
  }
}
</script>

<style scoped lang="scss">
.user-img {
  display: flex;
  align-items: center;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.title {
  color: #f4f6f9;
}

.photo-img {
  margin-top: 3px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
  }

}
</style>