<template>
  <div>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 50, 100]
      }"
          :items-per-page.sync="itemsPerPage"
          :headers="header"
          :items="chatDates"
          class="elevation-1"
          :server-items-length="totalItemsChatDates"
          :options.sync="chatDatesOptions"
          :loading="chatDatedLoading"
          :page.sync="page"
      >
        <template v-slot:top>
          <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>

          <v-toolbar flat color="primary" dark>
            <v-toolbar-title class="title">{{ translationsChatDatesLog.title }}</v-toolbar-title>

            <v-spacer/>
            <v-btn dark icon @click="toggleSearchFields">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-toolbar>

          <TableSearchFields
              v-if="showSearchFields"
              :searchFields="searchFields"
              @add-field="addSearchField"
              @remove-field="removeSearchField"
              @update-fields="updateSearchCriteria"
              @clear-fields="clearFields"
          />
        </template>


        <template v-slot:[`item.to`]="{ item }">
          <div class="user-img" @click="userOpen(item.to)">
            <img :src="$getAvatarImage(item.to)">
            {{ $getUserName(item.to) }}
            <v-icon
                v-if="item.to.id !== me.id && !$auth.isAdmin && !$auth.isOperator"
                class="ml-3"
                color="primary"
                @click.stop="$switchProfileUser(item.to.id)"
            >
              mdi-account-arrow-left
            </v-icon>
          </div>

        </template>

        <template v-slot:[`item.from`]="{ item }">
          <div class="user-img" @click="userOpen(item.from)">
            <img :src="$getAvatarImage(item.from)">
            {{ $getUserName(item.from) }}
            <v-icon
                v-if="item.from.id !== me.id && !$auth.isAdmin  && !$auth.isOperator"
                class="ml-3"
                color="primary"
                @click.stop="$switchProfileUser(item.from.id)"
            >
              mdi-account-arrow-left
            </v-icon>
          </div>

        </template>

        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
        <template v-slot:[`item.start`]="{ item }">{{ new Date(item.start) | date('Pp') }}</template>
        <template v-slot:[`item.end`]="{ item }">{{ new Date(item.end) | date('Pp') }}</template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip
              :color="getColor(item.status)"
              dark
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:footer>
          <div class="input-page">
            <v-row align="center" justify="start" align-content="center" class="mx-2">
              <v-col cols="12" md="3">
                <v-text-field
                    single-line
                    hide-details
                    label="Go to page"
                    v-model="pageInput"
                    @change="updatePage"
                    type="number"
                    min="1"
                    :max="pageCount"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  Pages: {{ page }} / {{ pageCount }}
                </span>
              </v-col>
            </v-row>
          </div>

        </template>

        <!--        <template v-slot:[`item.actions`]="{ item }" >-->
        <!--          <v-icon @click="deleteItem(item)">-->
        <!--            mdi-delete-->
        <!--          </v-icon>-->
        <!--        </template>-->
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {api} from "@/providers/api";
import DeleteModal from "@/components/DeleteModal";
import {getUserFlirt, getUserLike} from "@/api/usersRequest";
import router from "@/routes/router";
import {mapGetters} from "vuex";
import {getLikes} from "@/api/likeRequest";
import {getChatDates} from "@/api/chatDatesRequest";
import TableSearchFields from "@/components/TableSearchFields.vue";

export default {
  name: "ChatDatesLog",
  components: {TableSearchFields, DeleteModal},
  data: () => ({
    page: 1,
    pageInput: 1,
    dialogDelete: false,
    selected: [],
    header: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'User from',
        sortable: false,
        value: 'from'
      },
      {
        text: 'User to',
        sortable: false,
        value: 'to'
      },
      {
        text: 'CreatedAt',
        sortable: false,
        value: 'createdAt'
      },
      {
        text: 'Time Start',
        sortable: false,
        value: 'start'
      },
      {
        text: 'Time End',
        sortable: false,
        value: 'end'
      },
      {
        text: 'Status',
        sortable: false,
        value: 'status'
      },
    ],
    chatDates: [],
    statusSelect: [
      {value: 'Accepted'},
      {value: 'Declined'},
      {value: 'Pending'},
    ],
    deleteId: null,

    chatDatedLoading: true,
    totalItemsChatDates: 0,
    chatDatesOptions: {},
    itemsPerPage: 10,

    showSearchFields: false,
    searchFields: {
      id: [{value: '', multiple: true, name: 'id'}],
      from: [{value: '', multiple: true, name: 'from'}],
      to: [{value: '', multiple: true, name: 'to'}],
      created: [{value: '', multiple: false, name: 'created'}],
      time_Start: [{value: '', multiple: false, name: 'timeStart'}],
      time_End: [{value: '', multiple: false, name: 'timeEnd'}],
      status: [{value: '', multiple: false, name: 'status', items: ['pending', 'declined', 'accepted'], isSelect: true}],
    },
    searchCriteria: []
  }),
  computed: {
    ...mapGetters('User', ['me']),
    translations() {
      return this.$t('translation')
    },
    translationsChatDatesLog() {
      return this.translations.CHAT_DATES_LOG.table
    },
    pageCount() {
      return Math.ceil(this.totalItemsChatDates / this.itemsPerPage);
    },
  },
  created() {
    this.header = [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'FROM',
        sortable: true,
        value: 'from'
      },
      {
        text: 'TO',
        sortable: true,
        value: 'to'
      },
      {
        text: 'CREATED',
        sortable: true,
        value: 'createdAt'
      },
      {
        text: 'TIME START',
        sortable: true,
        value: 'start'
      },
      {
        text: 'TIME END',
        sortable: true,
        value: 'end'
      },
      {
        text: 'STATUS',
        sortable: false,
        value: 'status'
      },
    ],
        this.fetchChatDates()

  },
  watch: {
    chatDatesOptions: {
      handler(change) {
        console.log('chatDates payments', change)
        this.syncChatDatesFromApi()
      },
      deep: true
    },
    page(newVal) {
      this.pageInput = newVal;
    },
  },

  methods: {
    clearFields() {
      this.searchFields = {
        id: [{value: '', multiple: true, name: 'id'}],
        from: [{value: '', multiple: true, name: 'from'}],
        to: [{value: '', multiple: true, name: 'to'}],
        created: [{value: '', multiple: false, name: 'created'}],
        time_Start: [{value: '', multiple: false, name: 'timeStart'}],
        time_End: [{value: '', multiple: false, name: 'timeEnd'}],
        status: [{value: '', multiple: false, name: 'status', items: ['pending', 'declined', 'accepted'], isSelect: true}],
      }
    },
    toggleSearchFields() {
      this.showSearchFields = !this.showSearchFields;
    },
    addSearchField(data) {
      const {searchFields, category} = data
      this.searchFields = searchFields
      this.searchFields[category].push({value: ''});
    },
    removeSearchField(data) {
      const {category, index} = data
      this.searchFields[category].splice(index, 1);
    },
    async updateSearchCriteria(data) {
      const {searchFields, searchCriteria} = data
      this.searchFields = searchFields
      this.searchCriteria = searchCriteria
      if (this.page !== 1) {
        this.page = 1
      } else {
        await this.syncChatDatesFromApi();
      }
    },
    updatePage() {
      let page = parseInt(this.pageInput, 10);
      if (page > 0 && page <= this.totalItemsChatDates) {
        this.page = page;
      } else {
        this.pageInput = this.page;
      }
    },
    async syncChatDatesFromApi() {
      this.chatDatedLoading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.chatDatesOptions

        const response = await getChatDates({
          params: {
            ...sortBy.length ? {
              createdAtSort: sortBy.map((s, i) => (sortDesc[i] ? 'DESC' : 'ASC'))[0],
              sortField: sortBy[0]
            } : '',
            limit: limit === -1 ? 100 : limit,
            page,
            ...this.searchCriteria
          },

        })

        const {data: items, total} = response

        this.chatDates = items;
        this.totalItemsChatDates = total
      } catch (e) {
        console.log('chatDated error', e)
      } finally {
        this.chatDatedLoading = false
      }
    },


    async fetchChatDates() {
      //
      // this.chatDates = await api.get('/admin/chat-dates').then(r => r.data)
      // console.log(await api.get('/admin/chat-dates'));
      // return this.chatDates

    },

    async userOpen(user) {
      const dataLike = await getUserLike(user.id);
      const dataFlirt = await getUserFlirt(user.id);
      const obj = {
        like: {
          dataLike
        },
        flirt: {
          dataFlirt
        }
      };
      router.push({name: 'user-log', params: {id: user.id, targetUser: user, obj: obj}})
    },

    getColor(status) {
      if (status === 'pending') return 'orange'
      else if (status === 'accepted') return 'green'
      else return 'red'
    },

    statusChange(item) {
      console.log(item)
    },


    deleteItem(item) {
      this.dialogDelete = true
      this.deleteId = item.id
    },
    deleteItemConfirm() {
      this.chatDates = this.chatDates.filter(v => v.id !== this.deleteId)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
    },
  },
}
</script>

<style scoped lang="scss">
img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.title {
  color: #f4f6f9;
}

.photo-img {
  margin-top: 3px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
  }

}
</style>
