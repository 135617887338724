<template>
  <div>
    <PreloaderPage v-if="activityLoading" class="preloader-line"/>
    <v-container :class="{'disabled' : activityLoading}">
      <MyBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-tabs v-model="currentTab" class="mb-3" background-color="#f4f6f9" color="primary">
        <v-tab to="staff">
          Staff
        </v-tab>

        <v-tab to="transactions">
          {{ translationsAgencyLog.breadcrumbs.transactions }}
        </v-tab>

        <v-tab to="chats">
          Chats
        </v-tab>
        <v-tab to="activities">
          {{ translationsAgencyLog.breadcrumbs.activities }}

        </v-tab>
        <v-tab v-if="!this.$auth.isAdmin" to="withdrawal">
          {{ translationsAgencyLog.breadcrumbs.withdrawal }}
        </v-tab>
        <v-tab to="stats">
          {{ translationsAgencyLog.breadcrumbs.stats }}
        </v-tab>
        <v-tab to="settings">
          {{ translationsAgencyLog.breadcrumbs.settings }}
        </v-tab>
        <v-tab to="pay-out">
          {{ translationsAgencyLog.breadcrumbs.pay_out }}
        </v-tab>
      </v-tabs>
    </v-container>
    <v-container  class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headersTransaction"
          :items="transactionsFilter"
          :server-items-length="totalItems"
          :options.sync="options"
          :loading="transactionsLoading"
          item-key="name"
          class="elevation-2"

      >
        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
        <template v-slot:[`item.parentTransaction`]="{ item }">
          {{ categoryFormat(item.parentTransaction) }}
        </template>

        <template v-slot:[`item.parentTransaction.fromBalance.owner.email`]="{ item }">
          <v-list-item
              @click="userPush(item.parentTransaction.fromBalance.owner)"
              class="pa-0"
              dense
          >
            <v-list-item-avatar size="35" class="mr-3">
              <v-list-item-avatar class="mr-0">
                <v-img :src="$getAvatarImage(item.parentTransaction.fromBalance.owner)"></v-img>
              </v-list-item-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ item.parentTransaction.fromBalance.owner.name }}</v-list-item-title>

              <v-list-item-subtitle v-if="$auth.isAdmin"> {{ item.parentTransaction.fromBalance.owner.email }}</v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>

        </template>

        <template v-slot:[`item.destinationUser.email`]="{ item }">
          <v-list-item
              class="pa-0"
              dense
              @click="userPush(item.destinationUser)"
          >
            <v-list-item-avatar size="35" class="mr-3">
              <v-list-item-avatar class="mr-0">
                <v-img :src="$getAvatarImage(item.parentTransaction.destinationUser)"></v-img>
              </v-list-item-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ item.parentTransaction.destinationUser.name }}</v-list-item-title>

              <v-list-item-subtitle> {{ item.parentTransaction.destinationUser.email }}</v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
        </template>

      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {getAgencyTransactionsId} from "@/api/agencyRequest";
  import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import {mapGetters} from "vuex";
import {api} from "@/providers/api";
import PreloaderPage from "@/components/PreloaderPage.vue";

  export default {
    name: "AgencyLogTransactions",
    components: {PreloaderPage, MyBreadcrumbs},
    data: () => ({
      transactions: [],
      agencyName: '',
      currentTab: 1,
      options: {},
      totalItems: 0,
      transactionsLoading: false,
      activityLoading: false,
      headersTransaction: [
        { text: 'ID', align: 'start', value: 'id', sortable: true },
        { text: 'Name', value: 'destinationBalance.owner.name', sortable: true },
        { text: 'Email', value: 'destinationBalance.owner.email', sortable: true },
        { text: 'Category', value: 'category', sortable: true },
        { text: 'Amount', value: 'amount', sortable: true },
        { text: 'Created', value: 'createdAt', sortable: true },
      ],

    }),
    async created() {
      this.headersTransaction = [
        { text: 'ID', align: 'start', value: 'id', sortable: true },
        { text: 'From', value: 'parentTransaction.fromBalance.owner.email', sortable: true },
        { text: 'To', value: 'destinationUser.email', sortable: true },
        { text: 'Category', value: 'parentTransaction', sortable: true },
        { text: this.translationsAgencyLogTransactions.headers.amount + ' ($)', value: 'amount', sortable: true },
        { text: this.translationsAgencyLogTransactions.headers.created_at, value: 'createdAt', sortable: true },
      ]
      if (this.currentAgency?.name === undefined) {
        this.activityLoading = true;
        await this.$store.dispatch("Agencies/fetchAgencyById", {id: this.$route.params.id})
        this.activityLoading = false;
      }

    },
    watch: {
      options: {
        handler() {
          this.fetchTransactions()
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters('Agencies', [
        'getterDataById', 'getterAgencyById'
      ]),
      transactionsFilter() {
        return !this.transactionsLoading ? this.transactions.map(item => ({
          ...item,
          amount: this.amountFormat(item)
        })) : []
      },
      translations() {
        return this.$t('translation')
      },
      translationsAgencyLog() {
        return this.translations.AGENCY.AGENCY_LOG
      },
      translationsAgencyLogTransactions() {
        return this.translationsAgencyLog.table_transactions
      },
      currentAgency() {
        return this.getterAgencyById(+this.$route.params.id)
      },

      breadcrumbs() {
        return this.$auth.isAdmin ?  [
          { text: this.translations.NAVBAR.DASHBOARD.title, href: '/' },
          { text: this.translations.AGENCY.AGENCIES.title, href: '/agencies' },
          { text: `${this.currentAgency?.name || '-'} #${this.$route.params.id || '-'}`, href: 'staff' },
          { text: this.translationsAgencyLogTransactions.title, disabled: true,}
        ] : [
          {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id || '-'}`, href: 'staff'},
          {text: this.translationsAgencyLogTransactions.title, disabled: true,}
        ];
      },
    },
    methods: {
      amountFormat(item) {
        let amount = Number(item.amount);
        if (item?.chatMonetizationSession) {
          amount = Number(item.chatMonetizationSession.girlTotal);
        }
        return `${amount.toFixed(2)}`;
      },
      userPush(user) {
        if (this.$auth.isAdmin) {
          this.$router.push({name: 'user-log', params: {id: user.id}})

        }
      },
      fromFormat(item) {
        if (item === null) {
          return '';
        }
        else {
          return item.fromBalance.owner.email
        }
      },

      categoryFormat(item) {
        if (item === null) {
          return '';
        }
        if (item.chatId) {
          return 'Chat';
        }
        if (item.tariffId) {
          return 'Tariff';
        }
        if (item.flirtId) {
          return 'Flirt';
        }
        if (item.introductionId) {
          return 'Introduce';
        }
        if (item.videoCallId) {
          return 'Video Chat';
        }
        if (item.photoBuyId) {
          return 'Photo';
        }

        return item.category;
      },


      async fetchTransactions() {
        this.transactionsLoading = true
        try {
          const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options
          const response = await api.get(`/admin/agencies/${this.$route.params.id}/transactions`, {
            params: {
              ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
              limit: limit === -1 ? 100 : limit,
              page,

            },

          })

          const {data: items, total} = response
          this.transactions = items;
          this.totalItems = total
        } catch (e) {
          console.log('Error', e)
        } finally {
          this.transactionsLoading = false
        }
      }
    }
  }
</script>

<style scoped>
.preloader-line {
  position: absolute;
  top: 0;
}

.disabled {
  opacity: .6;
  pointer-events: none;
}
</style>