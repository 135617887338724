<template>
  <div>
    <PreloaderPage v-if="preloader"/>
    <v-container v-else class="my-5">
      <MyBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-card>
        <v-row
            class="pa-4"
            justify="space-between"
        >


          <v-row
              class="pa-4"
              justify="space-between">


            <v-col cols="2" class="user-main">
              <v-card
                  :key="user.id"
                  class="pt-6"
                  outlined
              >
                <v-card-text>IP: {{ ip }}</v-card-text>

                <v-select
                    v-if="adminCheck"
                    :items="statusItems"
                    v-model="statusSelect"
                    :label="translationsUserLog.status_label"
                    dense
                    outlined
                    @change="changeStatusUser"
                ></v-select>
                <v-card-text v-else>
                  {{ statusSelect }}
                </v-card-text>


                <!--                <v-card-text v-if="user.profile">-->

                <!--&lt;!&ndash;                  <h3 class="text mb-2">&ndash;&gt;-->
                <!--&lt;!&ndash;                    {{ user.email }}&ndash;&gt;-->
                <!--&lt;!&ndash;                  </h3>&ndash;&gt;-->
                <!--&lt;!&ndash;                  <v-btn @click="editUser(user.id)" dark color="primary">EDIT</v-btn>&ndash;&gt;-->

                <!--                </v-card-text>-->
                <div class="user" v-if="user.profile">
                  <v-icon
                      v-if="user.id !== me.id && !$auth.isAdmin && !$auth.isOperator"
                      class="icon-login"
                      color="white"
                      @click.stop="$switchProfileUser(user.id)"
                  >
                    mdi-account-arrow-left
                  </v-icon>
                  <v-avatar

                      size="150"
                  >
                    <v-img
                        :src="$getAvatarImage(user)"
                        class="mb-6"
                    ></v-img>
                    <div class="online" v-if="getterUserOnline(user.id)"></div>

                  </v-avatar>
                  <h3 class="text mb-2">
                    {{ user.profile.name }} {{ user.profile.surname }}
                  </h3>
                  <h3 class="text mb-2"> {{ user.email }}</h3>
                  <v-btn v-if="!$auth.isOperator" @click="editUser(user.id)" dark color="primary">{{ translationsUserLog.edit_btn }}</v-btn>
                </div>

                <v-card-text>
                  <div v-for="role in user.roles" :key="role.id" class="pa-1">
                    <v-chip color="purple" dark>
                      {{ role.name }}
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>

            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="10" class="user-info">
              <v-row align="start">
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>ID</v-list-item-title>
                      <v-list-item-subtitle v-if="user.id">{{ user.id }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.phone }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.phone">{{ user.phone }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.balance }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.balance">{{ $getBalance(user.balance) }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.date_of_birth }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.birthday">{{ user.profile.birthday }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row align="start">
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.children }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.children">{{ user.profile.children }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.education }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.education">{{ user.profile.education }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.occupation }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.occupation">{{ user.profile.occupation }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.hair_color }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.hairColor">{{ user.profile.hairColor }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row align="start">
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.religion }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.religion">{{ user.profile.religion }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.city }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.city">{{ user.profile.city }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.marital }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.marital">{{ user.profile.marital }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.eye_color }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.eyeColor">{{ user.profile.eyeColor }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row align="start">
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.weight }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.weight">{{ user.profile.weight }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.height }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.height">{{ user.profile.height }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.state }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.state">{{ user.profile.state }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.age }}</v-list-item-title>
                      <v-list-item-subtitle v-if="user.profile.birthday">{{ $calculateAge(user.profile.birthday) }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-else>{{ translations.GLOBAL_VALUE.no_data }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row align="start">
                <v-col>
                  <v-list-item :disabled="$auth.isOperator" two-line>
                    <span>{{ translationsUserLog.user_info.user_rating }}</span>
                    <v-rating
                        @input="changeUserRating"
                        background-color="warning lighten-1"
                        color="blue"
                        hover
                        length="10"
                        size="25"
                        v-model="user.profile.rating"
                    ></v-rating>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row v-if="!user.profile.isBoy && getAgencyId && !$auth.isOperator" align="start">
                <v-col>
                  <v-list-item two-line>
                    <span>Agency:</span>
                    <router-link class="ml-2 agency-link" :to="`/agencies/${getAgencyId}`">
                      {{ agencyInfo }}
                    </router-link>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

        </v-row>
      </v-card>
      <v-tabs background-color="#f4f6f9" color="primary">
        <v-tab @click="refreshTab('like')">{{ translationsUserLog.tabs_name.like }}</v-tab>
        <v-tab-item>
          <UserLogLikeTable :refresh="refreshTabs.like" @refreshStop="refreshStop" @reloadPage="reloadPage" />
        </v-tab-item>

        <v-tab @click="refreshTab('flirt')">{{ translationsUserLog.tabs_name.flirt }}</v-tab>
        <v-tab-item>
          <UserLogFlirtTable :refresh="refreshTabs.flirt" @refreshStop="refreshStop" @reloadPage="reloadPage"/>
        </v-tab-item>

        <v-tab @click="refreshTab('chat')">{{ translationsUserLog.tabs_name.chat }}</v-tab>
        <v-tab-item>
          <UserLogChatTable :refresh="refreshTabs.chat" @refreshStop="refreshStop" @reloadPage="reloadPage"/>
        </v-tab-item>

        <v-tab @click="refreshTab('transaction')">{{ translationsUserLog.tabs_name.transaction }}</v-tab>

        <v-tab-item >
          <UserManLogTransactionTable v-if="user.profile.isBoy" :refresh="refreshTabs.transaction" @refreshStop="refreshStop"/>
          <UserWomanLogTransactionTable v-else :refresh="refreshTabs.transaction" @refreshStop="refreshStop" />

        </v-tab-item>

        <v-tab @click="refreshTab('credit')">Credit History</v-tab>

        <v-tab-item >
          <UserManLogCreditHistoryTable v-if="user.profile.isBoy" :refresh="refreshTabs.credit" @refreshStop="refreshStop"/>
          <UserWomanLogCreditHistoryTable v-else :user="user" :refresh="refreshTabs.credit" @refreshStop="refreshStop" />
        </v-tab-item>

        <v-tab>Media</v-tab>

        <v-tab-item>
          <UserLogPhoto/>
        </v-tab-item>

        <v-tab @click="refreshTab('moderation')" v-if="$auth.isAdmin">Moderation Log</v-tab>
        <v-tab-item>
          <UserLogModerationTable :refresh="refreshTabs.moderation" @refreshStop="refreshStop"/>

        </v-tab-item>


        <v-tab @click="refreshTab('sub')" v-if="user.profile.isBoy">Subscription</v-tab>

        <v-tab-item v-if="user.profile.isBoy">
          <UserLogSubscription :refresh="refreshTabs.sub" @refreshStop="refreshStop"/>
        </v-tab-item>

        <v-tab v-if="!user.profile.isBoy">{{ translationsUserLog.tabs_name.stats }}</v-tab>
        <v-tab-item v-if="!user.profile.isBoy">
          <v-row class="ma-5">
            <v-col cols="12" class="user-info">
              <v-row align="start">
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.last_online_time }}</v-list-item-title>
                      <v-list-item-subtitle>01.10.2022</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ translationsUserLog.user_info.unread_message }}</v-list-item-title>
                      <v-list-item-subtitle>543</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>


              </v-row>
              <v-row align="start">
                <v-col>
                  <v-select v-model="select"
                            @change="changeTimeChart"
                            :items="items"
                            class="select-time-chart"
                  >
                  </v-select>
                </v-col>

              </v-row>
            </v-col>


            <v-col class="col-content" cols="12" md="6" lg="6">
              <BarChart :chart-data="userDataTime"/>
            </v-col>
            <v-col class="col-content" cols="12" md="6" lg="6">
              <BarChart :chart-data="userMessagesSent"/>
            </v-col>
            <v-col class="col-content" cols="12" md="6" lg="6">
              <BarChart :chart-data="userDataFlirtSent"/>
            </v-col>
            <v-col class="col-content" cols="12" md="6" lg="6">
              <BarChart :chart-data="userDataFlirtReceived"/>
            </v-col>
            <v-col class="col-content" cols="12" md="6" lg="6">
              <BarChart :chart-data="userDataEarnedOnService"/>
            </v-col>
            <v-col class="col-content" cols="12" md="6" lg="6">
              <BarChart :chart-data="userDataAgencyProfit"/>
            </v-col>
            <v-col class="col-content" cols="12" md="6" lg="6">
              <BarChart :chart-data="userDataUniqueMen"/>
            </v-col>
            <v-col class="col-content" cols="12" md="6" lg="6">
              <BarChart :chart-data="userDataChatSpent"/>
            </v-col>

            <v-col class="col-content" cols="12" md="6" lg="6">
              <PieChart :data-pie="pie"/>
            </v-col>
            <v-col class="col-content" cols="12" md="6" lg="6">
              <LineChart :data-line="line"/>
            </v-col>

          </v-row>
        </v-tab-item>



      </v-tabs>
    </v-container>
  </div>
</template>

<script>


import router from "@/routes/router";
import {
  getUserMessage,
  getUserChat,
  getUserFlirt,
  getUserId,
  getUserLike,
  getUserTransactions, changeRating, getUserIp
} from "@/api/usersRequest";
import {mapGetters} from "vuex";
import {api} from '@/providers/api';
import BarChart from "@/components/BarChart";
import PieChart from "@/components/PieChart";
import LineChart from "@/components/LineChart";
import UserLogLikeTable from "@/views/Users/UserLogLikeTable.vue";
import UserLogFlirtTable from "@/views/Users/UserLogFlirtTable";
import UserLogChatTable from "@/views/Users/UserLogChatTable";
import UserWomanLogCreditHistoryTable from "@/views/Users/UserWomanLogCreditHistoryTable";
import PreloaderPage from "@/components/PreloaderPage.vue";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import UserLogPhoto from "@/views/Users/UserLogPhoto.vue";
import UserLogModerationTable from "@/views/Users/UserLogModerationTable.vue";
import UserWomanLogTransactionTable from "@/views/Users/UserWomanLogTransactionTable.vue";
import UserManLogCreditHistoryTable from "@/views/Users/UserManLogCreditHistoryTable.vue";
import UserManLogTransactionTable from "@/views/Users/UserManLogTransactionTable.vue";
import UserLogSubscription from "@/views/Users/UserLogSubscription.vue";

export default {
  name: "UserLog",
  components: {
    UserLogSubscription,
    UserManLogTransactionTable,
    UserManLogCreditHistoryTable,
    UserWomanLogTransactionTable,
    UserLogModerationTable,
    UserLogPhoto,
    MyBreadcrumbs,
    PreloaderPage,
    UserWomanLogCreditHistoryTable,
    UserLogChatTable, UserLogFlirtTable, UserLogLikeTable, LineChart, PieChart, BarChart
  },
  props: {
    id: {
      type: Object,
      default: null,
    },
    targetUser: {
      type: Object,
      default: null,
    },
    obj: {
      type: Object,
      default: null,
    }
  },
  data: () => ({
    refreshTabs: {
      transaction: false,
      like: false,
      flirt: false,
      chat: false,
      credit: false,
      sub: false,
      moderation: false
    },
    flirtLoading: false,
    moderationLoading: false,
    likeLoading: false,
    chatLoading: false,
    transLoading: false,
    statusItems: ['REVIEW', 'ACTIVE', 'BLOCKED', 'IRRELEVANT'],
    pie: {},
    line: {},
    items: ['1 day', '7 days', '30 days', 'all days'],
    select: '',
    userDataTime: {},
    userMessagesSent: {},
    userDataFlirtReceived: {},
    userDataFlirtSent: {},
    userDataEarnedOnService: {},
    userDataAgencyProfit: {},
    userDataUniqueMen: {},
    userDataChatSpent: {},
    user: null,
    selectedChat: [],
    tab: null,
    itemsPerPageArray: [10, 15],
    search: '',
    filter: {},
    sortDesc: false,
    page: 1,
    itemsPerPage: 10,
    sortBy: 'name',
    active: [],
    avatar: null,

    open: [],
    users: [],
    selectedItem: null,
    selectedUser: [],
    selectedLike: [],
    selectedFlirt: [],
    selectedTrans: [],
    ip: 'loading',
    preloader: true,
    itemsModeration: [
        {id: 1, name: 'Kate', moderationDate: '1999-01-01'}
    ],
    headersModeration: [
      {text: 'ID', sortable: false, value: 'id'},
      {text: 'User', sortable: false, value: 'editor'},
      {text: 'Type', sortable: false, value: 'type'},
      {text: 'Moderation Date', sortable: false, value: 'createdAt'},
    ]
  }),

  async mounted() {
    this.preloader = false

    //Сюда надо с дєшборда передать пропс как сверху
    this.selectedTrans = [
      {
        id: 1,
        time: new Date(),
        amount: '100',
        category: 'tarrif',
      }
    ]

  },

  async created() {

    this.pie = {
      data: [123, 32, 52],
      labels: ['Like', 'Chat', 'Video chat'],
    },
        this.line = {
          label: this.translationsUserLog.label_line_chart,
          labels: ['01/07/22', '02/07/22', '03/07/22', '04/07/22', '05/07/22', '06/07/22', '07/07/22', '08/07/22'],
          data: [124, 534, 765, 412, 152, 435, 353, 153],
        },
        this.select = this.items[0]
    this.fetchOneDayChart()

    this.fetchUser()
    this.fetchDataByUser()

    // setTimeout(() => {
    //   this.preloader = false
    // }, 500)

  },

  computed: {
    ...mapGetters('User', ['me']),
    getAgencyId() {
      return this.user.agency?.agencyId
    },
    translationsUserLog() {
      return this.$t('translation.USERS.USER_LOG')
    },
    translations() {
      return this.$t('translation')
    },
    breadcrumbs() {
      return this.$auth.isAdmin ? [
        {text: this.translations.NAVBAR.DASHBOARD.title, href: '/'},
        {text: this.translations.NAVBAR.MANAGE_USERS.items.users_page, href: '/users'},
        {text: this.$route.params.id, disabled: true,}
      ] : [
        {text: this.translations.NAVBAR.MANAGE_AGENCY.items.agency_page, href: `/agencies`},
        {text: `${this.translations.GLOBAL_VALUE.user} ${this.$route.params.id}`, disabled: true},
      ];
    },
    adminCheck() {
      return this.$auth.isAdmin
    },
    statusSelect() {
      if (this.user.status === 0) return 'REVIEW'
      else if (this.user.status === 1) return 'ACTIVE'
      else if (this.user.status === 3) return 'IRRELEVANT'
      else return 'BLOCKED'
    },
    agencyInfo() {
      return this.user?.agency?.agency.name ? `${this.user?.agency?.agency.name}  ${this.user?.agency?.agency.owner ? `(${this.user?.agency?.agency.owner.email})` : ''}` : ''
    }

  },

  watch: {
    // 'user.profile.rating'(rating) {
    //   this.changeUserRating(rating)
    // },


  },

  methods: {

    async changeStatusUser(item) {
      let status;

      switch (item) {
        case 'REVIEW':
          status = 0;
          break;
        case 'ACTIVE':
          status = 1;
          break;
        case 'IRRELEVANT':
          status = 3;
          break;
        default:
          status = 2;
          break;
      }

      await api.post(`/admin/users/${this.$route.params.id}/status?status=${status}`);
    },

    async fetchDataByUser() {
      const userChat = await getUserChat(this.$route.params.id)
      this.selectedChat = userChat.data.filter(e => e.chat.members[1])
    },
    async fetchUser() {

      // const ip = (await getUserIp(this.$route.params.id)).ip
      if (this.targetUser) {
        this.user = this.targetUser
        this.selectedLike = this.obj.like.dataLike.data
        this.selectedFlirt = this.obj.flirt.dataFlirt.data

      } else {
        this.user = await getUserId(this.$route.params.id)
      }
      const cfIpCountry = this.user.cfIpCountry
          ? `ㅤ—ㅤ${this.$getCountyByCode(this.user.cfIpCountry.toLowerCase()).name}`
          : '';
      this.ip = this.user.ip ? this.user.ip + cfIpCountry : 'no data'

      console.log('USER', this.user)
    },

    refreshTab(name) {
      this.refreshTabs[name] = true
    },
    refreshStop(name) {
      this.refreshTabs[name] = false
    },

    async reloadPage() {
      this.preloader = true
      await this.fetchUser()
      await this.fetchDataByUser()
      this.preloader = false

    },


    fetchOneDayChart() {
      this.userDataTime = {
        labels: ['02.10.2022'],
        datasets: [
          {
            label: 'Hours online - ' + this.select,
            backgroundColor: '#ff6961',
            data: [24],
          }
        ]
      }
      this.userMessagesSent = {
        labels: ['02.10.2022'],
        datasets: [
          {
            label: 'Messages sent - ' + this.select,
            backgroundColor: '#ffb480',
            data: [524]
          }
        ]
      }
      this.userDataFlirtSent = {
        labels: ['02.10.2022'],
        datasets: [
          {
            label: 'Flirt sent - ' + this.select,
            backgroundColor: '#f8f38d',
            data: [524]
          }
        ]
      }
      this.userDataFlirtReceived = {
        labels: ['02.10.2022'],
        datasets: [
          {
            label: 'Flirt Received - ' + this.select,
            backgroundColor: '#42d6a4',
            data: [24],
          }
        ]
      }

      this.userDataEarnedOnService = {
        labels: ['02.10.2022'],
        datasets: [
          {
            label: 'Earned on the service - ' + this.select,
            backgroundColor: '#08cad1',
            data: [24],
          }
        ]
      }
      this.userDataAgencyProfit = {
        labels: ['02.10.2022'],
        datasets: [
          {
            label: 'Agency profit - ' + this.select,
            backgroundColor: '#59adf7',
            data: [524]
          }
        ]
      }
      this.userDataUniqueMen = {
        labels: ['02.10.2022'],
        datasets: [
          {
            label: 'Unique men - ' + this.select,
            backgroundColor: '#9d94ff',
            data: [524]
          }
        ]
      }
      this.userDataChatSpent = {
        labels: ['02.10.2022'],
        datasets: [
          {
            label: 'Time each spent in video chat - ' + this.select,
            backgroundColor: '#c780e8',
            data: [24],
          }
        ]
      }
    },
    fetch7DayChart() {
      this.userDataTime = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022'],
        datasets: [
          {
            label: 'Hours online - ' + this.select,
            backgroundColor: '#ff6961',
            data: [24, 4, 20, 13, 11, 10, 5]
          }
        ]
      }
      this.userMessagesSent = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022'],
        datasets: [
          {
            label: 'Messages sent - ' + this.select,
            backgroundColor: '#ffb480',
            data: [524, 41, 240, 313, 115, 150, 52]
          }
        ]
      }
      this.userDataFlirtSent = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022'],
        datasets: [
          {
            label: 'Flirt sent - ' + this.select,
            backgroundColor: '#f8f38d',
            data: [524, 41, 240, 313, 115, 150, 52]
          }
        ]
      }
      this.userDataFlirtReceived = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022'],
        datasets: [
          {
            label: 'Flirt Received - ' + this.select,
            backgroundColor: '#42d6a4',
            data: [44, 42, 23, 53, 16, 11, 1]
          }
        ]
      }

      this.userDataEarnedOnService = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022'],
        datasets: [
          {
            label: 'Earned on the service - ' + this.select,
            backgroundColor: '#08cad1',
            data: [524, 41, 240, 313, 115, 150, 52]
          }
        ]
      }
      this.userDataAgencyProfit = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022'],
        datasets: [
          {
            label: 'Agency profit - ' + this.select,
            backgroundColor: '#59adf7',
            data: [24, 4, 20, 13, 11, 10, 5]
          }
        ]
      }
      this.userDataUniqueMen = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022'],
        datasets: [
          {
            label: 'Unique men - ' + this.select,
            backgroundColor: '#9d94ff',
            data: [524, 41, 240, 313, 115, 150, 52]
          }
        ]
      }
      this.userDataChatSpent = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022'],
        datasets: [
          {
            label: 'Time each spent in video chat - ' + this.select,
            backgroundColor: '#c780e8',
            data: [24, 4, 20, 13, 11, 10, 5]
          }
        ]
      }
    },
    fetch30DayChart() {
      this.userDataTime = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Hours online - ' + this.select,
            backgroundColor: '#ff6961',
            data: [24, 4, 20, 13, 11, 10, 5, 14, 15, 13]
          }
        ]
      }
      this.userMessagesSent = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Messages sent - ' + this.select,
            backgroundColor: '#ffb480',
            data: [524, 41, 240, 313, 115, 150, 52, 54, 35, 135]
          }
        ]
      }

      this.userDataFlirtSent = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Flirt sent - ' + this.select,
            backgroundColor: '#f8f38d',
            data: [524, 41, 240, 313, 115, 150, 52, 54, 35, 135]
          }
        ]
      }
      this.userDataFlirtReceived = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Flirt Received - ' + this.select,
            backgroundColor: '#42d6a4',
            data: [24, 4, 20, 13, 11, 10, 5, 14, 15, 13]
          }
        ]
      }

      this.userDataEarnedOnService = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Earned on the service - ' + this.select,
            backgroundColor: '#08cad1',
            data: [524, 41, 240, 313, 115, 150, 52, 54, 35, 135]
          }
        ]
      }
      this.userDataAgencyProfit = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Agency profit - ' + this.select,
            backgroundColor: '#59adf7',
            data: [24, 4, 20, 13, 11, 10, 5, 14, 15, 13]
          }
        ]
      }
      this.userDataUniqueMen = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Unique men - ' + this.select,
            backgroundColor: '#9d94ff',
            data: [524, 41, 240, 313, 115, 150, 52, 54, 35, 135]
          }
        ]
      }
      this.userDataChatSpent = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Time each spent in video chat - ' + this.select,
            backgroundColor: '#c780e8',
            data: [24, 4, 20, 13, 11, 10, 5, 14, 15, 13]
          }
        ]
      }
    },
    fetchAllDayChart() {
      this.userDataTime = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Hours online - ' + this.select,
            backgroundColor: '#ff6961',
            data: [24, 4, 20, 13, 11, 10, 5, 14, 15, 13]
          }
        ]
      }
      this.userMessagesSent = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Messages sent - ' + this.select,
            backgroundColor: '#ffb480',
            data: [524, 41, 240, 313, 115, 150, 52, 54, 35, 135]
          }
        ]
      }
      this.userDataFlirtSent = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Flirt sent - ' + this.select,
            backgroundColor: '#f8f38d',
            data: [524, 41, 240, 313, 115, 150, 52, 54, 35, 135]
          }
        ]
      }
      this.userDataFlirtReceived = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Flirt Received - ' + this.select,
            backgroundColor: '#42d6a4',
            data: [24, 4, 20, 13, 11, 10, 5, 14, 15, 13]
          }
        ]
      }

      this.userDataEarnedOnService = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Earned on the service - ' + this.select,
            backgroundColor: '#08cad1',
            data: [524, 41, 240, 313, 115, 150, 52, 54, 35, 135]
          }
        ]
      }
      this.userDataAgencyProfit = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Agency profit - ' + this.select,
            backgroundColor: '#59adf7',
            data: [24, 4, 20, 13, 11, 10, 5, 14, 15, 13]
          }
        ]
      }
      this.userDataUniqueMen = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Unique men - ' + this.select,
            backgroundColor: '#9d94ff',
            data: [524, 41, 240, 313, 115, 150, 52, 54, 35, 135]
          }
        ]
      }
      this.userDataChatSpent = {
        labels: ['02.10.2022', '01.10.2022', '30.09.2022', '29.09.2022', '28.09.2022', '27.09.2022', '26.09.2022', '25.09.2022', '24.09.2022', '23.09.2022',],
        datasets: [
          {
            label: 'Time each spent in video chat - ' + this.select,
            backgroundColor: '#c780e8',
            data: [24, 4, 20, 13, 11, 10, 5, 14, 15, 13]
          }
        ]
      }
    },


    changeTimeChart(select) {
      if (select === '1 day') {

        this.fetchOneDayChart()

      } else if (select === '7 days') {

        this.fetch7DayChart()

      } else if (select === '30 days') {

        this.fetch30DayChart()

      } else if (select === 'all days') {

        this.fetchAllDayChart()

      }
    },

    editUser(id) {
      this.$router.push({path: `/users/${id}/edit`})
    }
    ,

    async changeUserRating(rating) {
      await changeRating(this.user.id, rating)
      await this.fetchUserModeration()
    }
  },


}
</script>

<style scoped lang="scss">
.icon-login {
  position: absolute;
  top: 0;
  right: 10px;
  background: #2f55b4;
  border-radius: 50%;
  padding: 5px;
}

.v-avatar-user {
  margin-right: 15px;
}

.card, .card-flirt {
  margin: 5px;
  width: 200px;
  max-height: 80px;
  color: #343a40;
  border: 1px solid #343a40;

  .v-card__title {
    text-transform: uppercase;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 15px;
  }

  .v-card__actions {
    padding: 0;

    .v-card__title {
      text-transform: uppercase;
      padding-top: 2px;
      padding-bottom: 2px;
      font-size: 15px;
    }
  }


  .v-icon {
    color: #343a40;
  }
}

.card-flirt {
  background: #1d4f9c;
}

.v-data-table {
  .user-img {
    display: flex;
    align-items: center;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 20px;
  }

  .title {
    color: #f4f6f9;
  }

  .photo-img {
    margin-top: 3px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      object-fit: cover;
    }

  }
}

.user-info {
  .row {
    margin: 3px;
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: none;
    }
  }
}

.user-main {
  .v-card {
    color: #343a40;
    border: none;

    .v-card__text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.online {
  background: #4fa526;
  width: 25px;
  height: 25px;
  position: absolute;
  border-radius: 100px;
  margin-left: 90px;
  border: 4px solid #fff;
  margin-top: 90px;
}

.card-stats {
  width: 450px;
  background: white;
  color: #2f55b4;
  margin: 15px;
  border: 2px solid #2f55b4;

  .v-card__title {
    text-transform: uppercase;
  }

  .v-icon {
    color: #2f55b4;
  }
}

.v-tabs {
  margin-top: 30px;
}

.select-time-chart {
  width: 300px;
}

.text {
  margin: 0 0 5px 0;
  font-size: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  width: 100%;
}

.user {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.online {
  background: #4fa526;
  width: 13px;
  height: 13px;
  position: absolute;
  border-radius: 100px;
  margin-left: 20px;
  border: 2px solid #fff;
  margin-top: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.agency-link{
  color: #2f55b4;
}
.user-title {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #2f55b4;
  }
}
</style>
