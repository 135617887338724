<template>
  <div>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headers"
          :items="this.members"
          sort-by="calories"
          class="elevation-1"
          :loading="membersLoading"
      >
        <template v-slot:top>
          <v-toolbar
              flat
              color="primary"
          >
            <v-toolbar-title class="title">{{ translations.table_membership.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="dialog"
            >
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-btn
                    color="white"
                    outlined
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  New Item
                </v-btn>
              </template>
              <v-card height="500">
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model="editedItem.name"
                            label="Name"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model.number="editedItem.price"
                            label="Tariff Price"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model.number="editedItem.liveVideoDiscount"
                            label="Live Video Discount"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model.number="editedItem.liveChatDiscount"
                            label="Live Chat Discount"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model.number="editedItem.videoDiscount"
                            label="Video Discount"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model.number="editedItem.photoDiscount"
                            label="Send Photo Discount"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model.number="editedItem.emojiDiscount"
                            label="Emoji Discount"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model.number="editedItem.introductionsLimit"
                            label="Free Intro Emails"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model.number="editedItem.followUpLimit"
                            label="Free Followup Emails"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model.number="editedItem.flirtsLimit"
                            label="Free Flirts"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model.number="editedItem.bonusCredits"
                            label="Bonus Credits"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-btn
                            right
                            color="primary"
                            text
                            @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                            right
                            color="primary"
                            text
                            @click="save"
                        >
                          Save
                        </v-btn>
                      </v-col>

                    </v-row>
                  </v-container>
                </v-card-text>

              </v-card>
            </v-dialog>
            <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>


      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headersUsers"
          :items="usersArr"
          item-key="name"
          class="table elevation-1 mt-5"
          :loading="userLoading"
          :server-items-length="userTotalItems"
          :options.sync="options"
      >
        <template v-slot:top>
          <v-toolbar
              flat
              dark
              color="primary"
          >
            <v-toolbar-title >Users</v-toolbar-title>
            <v-spacer/>
            <v-text-field v-model="searchEmail" placeholder="Email" dense outlined dark hide-details style="max-width: 400px" class="mr-3"/>
            <v-select
                filled
                v-model="selected"
                :items="members"
                item-text="name"
                item-value="name"
                label="Tariff"
                outlined
                dense
                hide-details
                dark
                persistent-hint
                single-line
                clearable
                style="max-width: 400px"
                @change="sortMembership"
            ></v-select>
          </v-toolbar>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span  class="cursor-pointer" @click="$openUser(item)">{{ item.name }}</span>
        </template>

        <template v-slot:[`item.email`]="{ item }">
          <span class="cursor-pointer" @click="$openUser(item)">{{ item.email }}</span>
        </template>
        <template v-slot:[`item.trial`]="{ item }">
          <span v-if="!item.membership.hasFirstPayment">
            {{
              new Date(item.membership.startDate) | date('Pp')
            }}
          </span>
        </template>
        <template v-slot:[`item.nextPayment`]="{ item }">
          <span v-if="item.membership.nextPaymentAt">{{ new Date(item.membership.nextPaymentAt) | date('Pp') }}</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span >{{ item.membership.membershipTariff.name }}</span>
        </template>

        <template v-slot:[`item.cancelled`]="{ item }">
          <span v-if="item.membership.isCanceled">
            {{
              new Date(item.membership.endDate) | date('Pp')
            }}
          </span>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>

import {addMember, getMembers, editMember, deleteMembership, getMembershipUsers} from "@/api/membershipRequest";
import DeleteModal from "@/components/DeleteModal";
import {debounce} from "lodash";


export default {
  name: "MyMemberships",
  components: {DeleteModal},
  data: () => ({
    selected: '',
    members: [],
    dialog: false,
    dialogDelete: false,
    membersLoading: false,
    sortListLoading: false,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: 'Price', value: 'price'},

      {text: 'Actions', value: 'actions', sortable: false},
    ],

    headersUsers: [
      {value: 'id', text: 'UserID', sortable: true},
      {value: 'name', text: 'Name', sortable: false},
      {value: 'email', text: 'Email', sortable: true},
      {value: 'createdAt', text: 'Registered at', sortable: true},
      {value: 'trial', text: 'Free Trial Started', sortable: true},
      {value: 'type', text: 'Current Subscription', sortable: true},
      {value: 'nextPayment', text: 'Next Payment', sortable: true},
      {value: 'cancelled', text: 'Cancelled', sortable: true},
    ],
    editedIndex: -1,
    membership: {
      membershipTariff: {
        name: ''
      }
    },
    editedItem: {
      emojiDiscount: '',
      flirtsLimit: '',
      name: '',
      price: '',
      liveVideoDiscount: '',
      liveChatDiscount: '',
      introductionsLimit: '',
      id: '',
      bonusCredits: '',
      caption: '',
      durationDays: '',
      followUpLimit: '',
      videoDiscount: '',
      photoDiscount: '',

    },
    defaultItem: {
      emojiDiscount: '',
      flirtsLimit: '',
      name: '',
      price: '',
      liveVideoDiscount: '',
      liveChatDiscount: '',
      introductionsLimit: '',
      id: '',
      bonusCredits: '',
      caption: '',
      durationDays: '',
      followUpLimit: '',
    },
    data: {},
    users: {},
    sortList: [],
    deleteId: null,

    userLoading: false,
    options: {},
    usersArr: [],
    userTotalItems: null,
    searchEmail: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    translations() {
      return this.$t('translation.MEMBERSHIP_LOG')
    }
  },
  watch: {
    options: {
      handler(changed) {
        console.log('syncUsersMembershipFromApi:change', changed);
        this.syncUsersMembershipFromApi();
      },
      deep: true
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    searchEmail() {
      this.debouncedSearch()
    },
  },

  created() {
    this.headers = [
      {
        text: this.translations.table_membership.headers.name,
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: this.translations.table_membership.headers.price, value: 'price'},

      {text: this.translations.table_membership.headers.actions, value: 'actions', sortable: false},
    ]
    this.fetchGiftsList();
  },

  methods: {
    debouncedSearch: debounce(function () {
      this.syncUsersMembershipFromApi();
    }, 400),
    async syncUsersMembershipFromApi() {
      this.userLoading = true;
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options;
        const response = await getMembershipUsers({
          ...sortBy.length ?
              {
                orderBy: sortBy[0],
                orderDirection: sortDesc[0] ? 'DESC' : 'ASC',
              }
              : [],
          limit: limit === -1 ? 100 : limit,
          page,
          ...this.searchEmail ? {email: this.searchEmail} : {},
          ...this.selected ? {subType: this.selected.toUpperCase()} : {}
        });
        const {items, meta} = response;


        this.usersArr = items;
        this.userTotalItems = meta.totalItems;

      } catch (e) {
        console.log('error contact', e);
      } finally {
        this.userLoading = false;
        console.log('finish?')

      }
    },
    async fetchGiftsList() {
      try {
        this.membersLoading = true
        this.members = await getMembers();
        return this.members;
      } catch (e) {
        console.log(e)
      } finally {
        this.membersLoading = false
      }

    },

    async sortMembership() {
      await this.syncUsersMembershipFromApi()
    },

    editItem(item) {
      this.editedIndex = this.members.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true

    },

    deleteItem(item) {
      this.editedIndex = this.members.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
      this.deleteId = item.id

    },

    async deleteItemConfirm() {
      this.users = this.members.filter(e => e.id !== this.deleteId)
      console.log(this.deleteId)
      await deleteMembership(this.deleteId)
      this.fetchGiftsList()
      this.closeDelete();
    },

    close(edit) {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        if (edit) {
          this.fetchGiftsList();
        }
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.members[this.editedIndex], this.editedItem)
        await editMember(this.editedItem.id, {
          name: this.editedItem.name,
          caption: this.editedItem.caption,
          price: Number(this.editedItem.price),
          bonusCredits: this.editedItem.bonusCredits,
          flirtsLimit: Number(this.editedItem.flirtsLimit),
          emojiDiscount: this.editedItem.emojiDiscount,
          introductionsLimit: this.editedItem.introductionsLimit,
          followUpLimit: this.editedItem.followUpLimit,
          liveChatDiscount: this.editedItem.liveChatDiscount,
          liveVideoDiscount: this.editedItem.liveVideoDiscount,
          videoDiscount: this.editedItem.videoDiscount,
          photoDiscount: this.editedItem.photoDiscount
        })
      } else {
        this.members.push(this.editedItem)
        const response = await addMember({
          name: this.editedItem.name,
          caption: this.editedItem.caption,
          price: Number(this.editedItem.price),
          flirtsLimit: Number(this.editedItem.flirtsLimit),
          freeCredits: 0,
          trialDurationsDays: 1,
          bonusCredits: Number(this.editedItem.bonusCredits),
          emojiDiscount: this.editedItem.emojiDiscount,
          introductionsLimit: Number(this.editedItem.introductionsLimit),
          followUpLimit: Number(this.editedItem.followUpLimit),
          liveChatDiscount: Number(this.editedItem.liveChatDiscount),
          liveVideoDiscount: Number(this.editedItem.liveVideoDiscount),
          videoDiscount: Number(this.editedItem.videoDiscount),
          photoDiscount: Number(this.editedItem.photoDiscount)
        })
        this.editedItem.id = response.id
      }
      this.close(true)
    },
  },
}
</script>

<style scoped lang="scss">
.title {
  color: #f4f6f9;
}
.cursor-pointer{
  cursor: pointer;
}
.select-container {
  margin-top: 20px;

  display: flex;
  align-items: center;

  .v-select {
    margin-right: 20px;
  }

  .v-btn {
    background: #343a40;
    color: #f4f6f9;
  }
}

.table {
  .user-img {
    display: flex;
    align-items: center;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 20px;
  }
}

</style>
