import { render, staticRenderFns } from "./AgencyLogTableWithdrawer.vue?vue&type=template&id=72b178b6&scoped=true"
import script from "./AgencyLogTableWithdrawer.vue?vue&type=script&lang=js"
export * from "./AgencyLogTableWithdrawer.vue?vue&type=script&lang=js"
import style0 from "./AgencyLogTableWithdrawer.vue?vue&type=style&index=0&id=72b178b6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b178b6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VChip,VContainer,VDataTable,VTab,VTabs})
