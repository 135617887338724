<template>
  <div>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 50, 100]
      }"
          :items-per-page.sync="itemsPerPage"
          :headers="headers"
          :items="users"
          item-key="name"
          class="elevation-1"
          :page.sync="page"
          :server-items-length="totalItemsMessages"
          :options.sync="messagesOptions"
          :loading="messagesLoading"

      >
        <template v-slot:top>
          <v-toolbar color="primary">
            <v-toolbar-title class="title">{{ translationsChatLog.table.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn dark icon @click="toggleSearchFields">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-toolbar>

          <TableSearchFields
              v-if="showSearchFields"
              :searchFields="searchFields"
              @add-field="addSearchField"
              @remove-field="removeSearchField"
              @update-fields="updateSearchCriteria"
              @clear-fields="clearFields"
          />

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">{{ translations.GLOBAL_VALUE.delete_modal.title }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">
                  {{ translations.GLOBAL_VALUE.delete_modal.cancel_btn }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  {{ translations.GLOBAL_VALUE.delete_modal.ok_btn }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--          <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteProps"/>-->

        </template>
        <template v-slot:[`item.from`]="{ item }">
          <div class="user-img cursor-pointer" @click="userPush(item.from)">
            <img :src="$getAvatarImage(item.avatar)">
            <!--            <div class="online"></div>-->
            {{ item.from.name }}
          </div>
        </template>

        <template v-slot:[`item.to`]="{ item }">
          <div class="user-img cursor-pointer" @click="userPush(item.all)">
            <img :src="$getAvatarImage(item.avatar2)">
            <!--            <div class="online"></div>-->
            {{ item.to }}

          </div>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
        <template v-slot:[`item.chatId`]="{ item }">{{ item.chat }}</template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              :disabled="$auth.isOperator"
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:footer>
          <div class="input-page">
            <v-row align="center" justify="start" align-content="center" class="mx-2">
              <v-col cols="12" md="3">
                <v-text-field
                    single-line
                    hide-details
                    label="Go to page"
                    v-model="pageInput"
                    @change="updatePage"
                    type="number"
                    min="1"
                    :max="pageCount"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  Pages: {{ page }} / {{ pageCount }}
                </span>
              </v-col>
            </v-row>
          </div>

        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>

import {getMessages, deleteMessages} from "@/api/messageRequest";
import {mapGetters} from "vuex";
import {getChatDates} from "@/api/chatDatesRequest";
import router from "@/routes/router";
import TableSearchFields from "@/components/TableSearchFields.vue";
// import DeleteModal from "@/components/DeleteModal";


export default {
  name: "ChatLog",
  components: {TableSearchFields},
  // components: {DeleteModal},
  data() {
    return {
      itemsPerPage: 10,
      page: 1,
      pageInput: 1,
      dialogDelete: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'CHAT-ID',
          align: 'start',
          value: 'chatId',
          sortable: false,
        },
        {
          text: 'FROM',
          value: 'from',
          sortable: false,
        },
        {
          text: 'TO',
          value: 'to',
          sortable: false,
        },
        {
          text: 'MESSAGE',
          value: 'message',
          sortable: false,
        },
        {
          text: 'CREATED',
          value: 'createdAt',
          sortable: false,
        },
        {
          text: 'ACTION',
          value: 'actions',
          sortable: false,
        },

      ],
      users: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        from: '',
        to: '',
        avatar: '',
        avatar2: '',
        photo: ''
      },
      deleteId: '',
      messagesLoading: true,
      totalItemsMessages: 0,
      messagesOptions: {},

      showSearchFields: false,
      searchFields: {
        id: [{value: '', multiple: true, name: 'id'}],
        chatId: [{value: '', multiple: false, name: 'chat_id'}],
        from: [{value: '', multiple: true, name: 'from'}],
        to: [{value: '', multiple: true, name: 'to'}],
        message: [{value: '', multiple: false, name: 'message'}],
        created: [{value: '', multiple: false, name: 'created'}]
      },
      searchCriteria: []
    }
  },
  computed: {
    ...mapGetters('User', ['me']),
    pageCount() {
      return Math.ceil(this.totalItemsMessages / this.itemsPerPage);
    },
    translations() {
      return this.$t('translation')
    },
    translationsChatLog() {
      return this.translations.CHAT_LOG
    }
  },
  watch: {
    messagesOptions: {
      handler(change) {
        console.log('messages payments', change)
        this.syncMessagesFromApi()
      },
      deep: true
    },

    page(newVal) {
      this.pageInput = newVal;
    },
  },

  async created() {
    this.headers = [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'CHAT ID',
        value: 'chatId',
        sortable: true,
      },
      {
        text: this.translationsChatLog.table.headers.from.toUpperCase(),
        value: 'from',
        sortable: true,
      },
      {
        text: this.translationsChatLog.table.headers.to.toUpperCase(),
        value: 'to',
        sortable: true,
      },
      {
        text: this.translationsChatLog.table.headers.message.toUpperCase(),
        value: 'message',
        sortable: true,
      },
      {
        text: this.translationsChatLog.table.headers.created_at.toUpperCase(),
        value: 'createdAt',
        sortable: true,
      },
      {
        text: this.translationsChatLog.table.headers.actions.toUpperCase(),
        value: 'actions',
        sortable: false,
      },
    ]
    this.fetchChat()
  },

  methods: {
    async clearFields() {
      this.searchFields = {
        id: [{value: '', multiple: true, name: 'id'}],
        chatId: [{value: '', multiple: false, name: 'chat_id'}],
        from: [{value: '', multiple: true, name: 'from'}],
        to: [{value: '', multiple: true, name: 'to'}],
        message: [{value: '', multiple: false, name: 'message'}],
        created: [{value: '', multiple: false, name: 'created'}]
      }
    },
    toggleSearchFields() {
      this.showSearchFields = !this.showSearchFields;
    },
    addSearchField(data) {
      const {searchFields, category} = data
      this.searchFields = searchFields
      this.searchFields[category].push({value: ''});
    },
    removeSearchField(data) {
      const {category, index} = data
      this.searchFields[category].splice(index, 1);
    },
    async updateSearchCriteria(data) {
      const {searchFields, searchCriteria} = data
      this.searchFields = searchFields
      this.searchCriteria = searchCriteria
      if (this.page !== 1) {
        this.page = 1
      } else {
        await this.syncMessagesFromApi();
      }
    },

    updatePage() {
      let page = parseInt(this.pageInput, 10);
      if (page > 0 && page <= this.totalItemsMessages) {
        this.page = page;
      } else {
        this.pageInput = this.page;
      }
    },
    userPush(user) {
      if (this.$auth.isAdmin || this.$auth.isOperator) {
        router.push({name: 'user-log', params: {id: user.id}})
        this.$emit('reloadPage')
      } else {
        if (user.agency && Number(user.agency.agencyId) === Number(this.me.agency.agencyId)) {
          router.push({name: 'user-log', params: {id: user.id}})
          this.$emit('reloadPage')
        }
      }

    },
    // async deleteProps() {
    //   await this.deleteItemConfirm()
    // },
    async syncMessagesFromApi() {
      let agency_id
      if (!this.$auth.isAdmin && !this.$auth.isOperator) {
        agency_id = this.$auth.user.agency.agency.id
      }

      this.messagesLoading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.messagesOptions


        const response = await getMessages({
          params: {
            ...agency_id ? {agency_id} : {},
            ...sortBy.length ? {
              createdAtSort: sortBy.map((s, i) => (sortDesc[i] ? 'DESC' : 'ASC'))[0],
              sortField: sortBy[0],
            } : '',
            limit: limit === -1 ? 100 : limit,
            page,
            ...this.searchCriteria

          }
        })

        const {items, meta} = response

        this.users = items
            .filter(e => e.chat.members.length === 2)
            .map(e => {
          const to = e.sender.id !== e.chat.members[0].id ?
              e.chat.members[0].profile :
              e.chat.members[1].profile;
          return {
            id: e.id,
            chat: e.chat.id,
            from: {
              ...e.sender,
              agency: e.sender.id === e.chat.members[0].id ? e.chat.members[0]?.agency : e.chat.members[1]?.agency
            },
            to: to.name,
            message: e.text,
            avatar: e.sender.profile,
            avatar2: to,
            createdAt: e.createdAt,
            all: e.sender.id !== e.chat.members[0].id ?
                e.chat.members[0] :
                e.chat.members[1]
          }
        })
        this.totalItemsMessages = meta.totalItems
      } catch (e) {
        console.log('message error', e)
      } finally {
        this.messagesLoading = false
      }
    },

    async fetchChat() {
      // let agency_id
      // if(!this.$auth.isAdmin) {
      //   agency_id = this.$auth.user.agency.agency.id
      // }
      // this.users = await getMessages({
      //   params: {
      //     ... agency_id ? {agency_id} : {}
      //   }
      // }).then(r => r.data.map(e => {
      //   const to = e.sender.id !== e.chat.members[0].id ?
      //       e.chat.members[0].profile :
      //       e.chat.members[1].profile;
      //   return {
      //     id: e.id,
      //     chat: e.chat.id,
      //     from: e.sender.profile.name,
      //     to: to.name,
      //     message: e.text,
      //     avatar: e.sender.profile.avatar,
      //     avatar2: to.avatar,
      //     createdAt: e.createdAt,
      //     all: to
      //   }
      // }))
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.deleteId = item.id
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      this.users = this.users.filter(e => e.id !== this.deleteId)
      await deleteMessages(this.deleteId)
      this.closeDelete()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },

}
</script>

<style scoped lang="scss">
.online {
  background: #4fa526;
  width: 13px;
  height: 13px;
  position: absolute;
  border-radius: 100px;
  margin-left: 20px;
  border: 2px solid #fff;
  margin-top: 20px;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.title {
  color: #f4f6f9;
}

.v-data-table {
  position: relative;
}

.input-page {
  position: absolute;
  width: 50%;
  bottom: 12px;

  .v-input {
    padding-top: 0 !important;
  }

  @media (max-width: 769px) {
    margin-bottom: 12px;
    position: relative;
  }
}
</style>
