<template>
  <v-data-table
      :footer-props="{'items-per-page-options': [10, 50, 100]}"
      :items-per-page="10"
      :headers="headers"
      :items="items"
      item-key="id"
      class="elevation-1"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
  >
    <template v-slot:top>
      <v-toolbar dark flat color="primary">
        <v-toolbar-title >Closing Account log</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-select
              label="Select status"
              hide-details
              dark
              dense
              outlined
              :items="status"
              class="mt-3"
              v-model="selectedStatus"
              @change="changeStatus"
          />
        </v-toolbar-items>
      </v-toolbar>

    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
          :color="getColor(item.status)"
          dark
      >
        <span v-if="item.status === 0">Waiting</span>
        <span v-if="item.status === 1">Approved</span>
        <span v-if="item.status === 2">Declined</span>
      </v-chip>
    </template>
    <template v-slot:[`item.user`]="{ item }">
      <router-link class="user-img" :to="{name: 'user-log', params: {id: item.user.id}}">
        <v-avatar size="30">
          <v-img :src="$getAvatarImage(item.user)"/>
        </v-avatar>
        {{ item.user.profile.name }}
        <v-icon
            v-if="item.user.id !== me.id && !$auth.isAdmin"
            class="ml-3"
            color="primary"
            @click.stop="$switchProfileUser(item.user.id)"
        >
          mdi-account-arrow-left
        </v-icon>
      </router-link>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div>
        <v-btn v-if="selectedStatus === 'Waiting'" class="mr-3" @click="acceptClick(item)" small dark color="green">Approve</v-btn>
        <v-btn v-if="selectedStatus === 'Waiting'" class="mr-3" @click="declineClick(item)" small dark color="red">Decline</v-btn>
      </div>
    </template>

  </v-data-table>
</template>

<script>
import {mapGetters} from "vuex";
import {getClosingAccounts} from "@/api/usersRequest";
import {api} from "@/providers/api";

export default {
  name: "UsersClosingAccountTable",
  data: () => ({
    status: ['Waiting', 'Approved', 'Declined'],
    selectedStatus: 'Waiting',
    totalItems: 0,
    options: {},
    loading: true,
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
        sortable: false
      },
      {
        text: 'User',
        value: 'user',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false
      },
    ],
    items: [],
  }),
  watch: {

    options: {
      handler(change) {
        this.syncUsersFromApi();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('User', ['me']),

  },

  methods: {
    changeStatus() {
      this.options.page = 1;
      this.syncUsersFromApi()
    },
    getColor(status) {
      if (status === 1)
        return 'green';
      else if (status === 2)
        return 'red';
      else return 'orange';
    },
    async acceptClick(item) {
      await api.patch(`/admin/users/change-account-requests/${item.id}`, {}, {
        params: {
          destination: 'DELETE',
          status: 'APPROVED'
        }
      })
      await this.syncUsersFromApi()
    },
    async declineClick(item) {
      await api.patch(`/admin/users/change-account-requests/${item.id}`, {}, {
        params: {
          destination: 'DELETE',
          status: 'DECLINED'
        }
      })
      await this.syncUsersFromApi()
    },


    async syncUsersFromApi() {
      this.loading = true;

      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options;
        const response = await getClosingAccounts({
          params: {
            status: this.selectedStatus.toUpperCase(),
            destination: 'DELETE',
            ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
            limit: limit === -1 ? 100 : limit,
            page
          }
        });

        const {items, meta} = response;
        console.log('totalItems', response)
        this.items = items;
        this.totalItems = meta.totalItems;
      } catch (e) {
        console.log('error contact', e);
      } finally {
        this.loading = false;
      }


    },

    userOpen(user) {
      this.$router.push({name: 'user-log', params: {id: user.user.id}});
    },

  },
}
</script>

<style scoped>
.user-img {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
</style>