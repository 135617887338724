<template>
  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex flex-column">
      <v-btn
          color="red lighten-2"
          dark
          class="mb-2"
          style="width: fit-content"
          v-bind="attrs"
          v-on="on"
      >
        Add profile for operating
      </v-btn>
        <v-progress-circular
            v-if="usersOperatingLoading"
            indeterminate
            color="primary"
        ></v-progress-circular>
        <div class="users-container d-flex flex-row flex-wrap">
          <router-link class="user-container" v-for="(item, index) in usersOperating" :key="index" :to="`/users/${item.id}`">
            <v-list-item-avatar>
              <img :src="$getAvatarImage(item)" alt="icon">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
            </v-list-item-content>
          </router-link>
        </div>
      </div>
    </template>

    <v-card>

      <div class="d-flex">
        <div class="search-users-item">
          <v-text-field hide-details dense outlined label="Search Users" v-model="search"></v-text-field>
          <div class="search-users-item-box">
            <v-progress-circular
                v-if="usersLoading"
                indeterminate
                color="primary"
            ></v-progress-circular>
            <v-list v-else>
              <v-list-item :class="{'selected-user': operatorIds.find(e => e === item.id)}" @click="addUserId(item)" v-for="(item, index) in items" :key="index">
                <v-list-item-avatar>
                  <img :src="$getAvatarImage(item)">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </div>
        <v-divider vertical/>
        <div class="search-users-item">
          <v-card-title class="pa-0">Ids List</v-card-title>
          <PreloaderPage class="preloader" v-if="usersDoneLoading"/>
          <div class="search-users-item-box" v-else>
            <v-chip
                class="mr-2 mb-2"
                close
                small
                color="purple"
                dark v-for="(item, index) in operatorIds"
                :key="index"
                @click:close="removeUserId(index)"
            >
              ID: {{ item }}
            </v-chip>
          </div>
        </div>
      </div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :loading="isLoadingSaveChanging"
            color="primary"
            text
            @click="saveChanging"
        >
          Save
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {getUserId} from "@/api/usersRequest";
import {api} from "@/providers/api";
import {subYears} from "date-fns";
import {debounce} from "lodash";
import PreloaderPage from "@/components/PreloaderPage.vue";

export default {
  name: "SearchUsers",
  props: {
    id: {
      type: Number
    }
  },
  components: {PreloaderPage},
  data: () => ({
    dialog: false,
    operatorIds: [],
    items: [],
    search: '',
    usersLoading: false,
    ageValue: [18, 99],
    isLoadingSaveChanging: false,
    usersDoneLoading: false,
    usersOperating: [],
    usersOperatingLoading: false
  }),
  watch: {
    search(val) {
      if (val.trim()) {
        this.usersLoading = true;
        this.debouncedSearch()
      }
      else {
        this.items = []
      }
    },
    dialog() {
      if(this.dialog) {
        this.usersDoneLoading = true;
        this.getOperatorUsersId()
        this.usersDoneLoading = false;
      } else {
        this.getUsersOperating();
      }
    }
  },
  computed: {
    prepareSearchQuery() {
      const search = this.search.trim();
      const isNumber = !isNaN(Number(search));

      return isNumber ? { ids: [search], isDeleted: false } : {
        birthdayMin: this.calculateDate(this.ageValue[1]).getTime(),
        birthdayMax: this.calculateDate(this.ageValue[0]).getTime(),
        isDeleted: false,
        search
        }
    }
  },
  created() {
    this.getOperatorUsersId();
    this.usersOperatingLoading = true;
    this.getUsersOperating();
    this.usersOperatingLoading = false;
  },
  methods: {
    debouncedSearch: debounce(function () {
      this.getUsers();
    }, 400),
    calculateDate(yearsToSubtract) {
      if (yearsToSubtract) {
        const currentDate = new Date();
        const newDate = subYears(currentDate, yearsToSubtract);
        return newDate
      }
      return null;

    },

    isActiveHeaderValue(value){
      if(value === 'id' || value === 'isAgency' || value === 'email' || value === 'createdAt' ) {
        return value
      }
      switch (value) {
        case 'profile.name':
          return 'profileName';
        case 'profile.surname':
          return 'profileSurname';
        case 'membership.membershipTariff.name':
          return 'membershipTariffName';
        case 'profile.isBoy':
          return 'profileIsBoy';
        case 'balance.amount':
          return 'balanceAmount';
        case 'profile.age':
          return 'profileAge';
        case 'profile.countryCode':
          return 'profileCountryCode';
      }
    },

    async getUsers() {
      try {
        const params = {
          limit: 100,
          page: 1,
          ...this.prepareSearchQuery
        }

        const response = await api
            .get('/admin/users', {
              params: params
            });
        const {items} = response;
        this.items = items
      } catch (e) {
        console.log('error', e);
      } finally {
        this.usersLoading = false;
      }
    },

    async getUsersOperating() {
      try {
        const response = await api
            .get(`/admin/operators/${this.id}/users`, {params: {
                limit: 100,
                page: 1
              }
            });
        const { items } = response;
        this.usersOperating = items.map(item => ({
          id: item.user?.id || '-',
          email: item.user?.email || '-',
          name: item.user?.profile?.name || '-',
          avatar: item.user?.profile?.avatar || undefined
        }))
      } catch (e) {
        console.log('error', e);
      } finally {
        this.usersLoading = false;
      }
    },


    async saveChanging() {
      try {
        this.isLoadingSaveChanging = true
        await api.post(`/admin/users/${this.$route.params.id}/operator-users`, {
          ids: this.operatorIds
        })
        this.dialog = false;
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoadingSaveChanging = false
      }
    },

    addUserId(val) {
      const findIndex = this.operatorIds.findIndex(e => Number(e) === val.id)
      if (findIndex !== -1) {
        this.removeUserId(findIndex)
      } else {
        this.operatorIds.push(Number(val.id));
        // await api.post(`/admin/users/${this.$route.params.id}/operator-users`, {
        //   ids: this.operatorIds
        // })
      }
    },
    async removeUserId(val) {
      this.operatorIds.splice(val, 1)
    },
    async getOperatorUsersId() {
      const { operators } = await getUserId(this.$route.params.id)
      this.operatorIds = operators.map(operator => operator.userId);

    },
  }

}
</script>

<style scoped lang="scss">
.selected-user{
  background: rgba(10, 10, 10, .1);;
}
.user-container {
  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255);
  padding: 0 6px;
  border-radius: 6px;
  text-decoration: none;
  color: inherit;

  @media (max-width: 769px) {
    width: 100%;
  }
}
.users-container {
  max-height: 264px;
  overflow-y: auto;
  column-gap: 6px;
  row-gap: 6px;
}
.search-users-item{
  padding: 10px;
  width: 50%;
  height: 500px;
  .search-users-item-box{
    height: 90%;
    margin-top: 10px;
    overflow-y: auto;
  }
}
</style>