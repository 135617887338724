<template>
  <div class="agency">

    <PreloaderPage v-if="preloader"/>
    <v-container v-else class="my-5">
      <v-breadcrumbs v-if="this.$auth.isAdmin" large :items="breadcrumbs" ></v-breadcrumbs>
      <v-row>
        <v-col cols="12" md="12" lg="12">
          <v-card>
            <v-card-title class="table-header">
              {{ translationsAgencyAdmin.table.title }}({{ items.length }})
              <v-spacer></v-spacer>
              <v-text-field
                  class="search-input"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  color="white"

              ></v-text-field>
              <v-btn
                  elevation="0"
                  color="primary"
                  dark
                  class="button-add mb-2"
                  @click="createAgency"
              >
                {{ translationsAgencyAdmin.table.btn_add_new_agency }}
              </v-btn>
            </v-card-title>
            <v-data-table
                :footer-props="{'items-per-page-options': [10, 50, 100]}"
                :items-per-page="10"
                :headers="headers"
                :items="items"
                :search="search"
                @click:row="agencyClick"
                :loading="allAgencyLoading"
                :server-items-length="totalAgencyItems"
                :options.sync="agencyOptions"
            >
              <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
              <template v-slot:[`item.balance`]="{ item }">
                <v-chip color="success" small>
                                  <span v-if="item.balance === null">
                  -
                </span>
                  <span v-else>
<!--                  {{ Math.floor(item.balance.amount) }}-->
                  {{ $getBalance(item.balance.amount) }}
                </span>
                </v-chip>

              </template>
              <template v-slot:[`item.owner.name`]="{ item }">
                <div class="user-img" v-if="item.owner">
                  <img :src="$getAvatarImage(item.owner)" alt="img">
                  <span class="user-title"  @click.stop="openUserNewPage(item.owner.id)">{{ item.owner.email }}</span>
                  <div class="online" v-if="getterUserOnline(item.owner.id)"></div>
                </div>
                <div v-else> - </div>
              </template>
              <template v-slot:[`item.agencyPercent`]="{ item }">
                {{ (+item.agencyPercent * 100) + '%' }}
              </template>

              <template v-slot:[`item.girlPercent`]="{ item }">
                {{ (+item.girlPercent * 100) + '%' }}
              </template>

              <template v-slot:top>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">{{ translations.GLOBAL_VALUE.delete_modal.title }}</v-card-title>
                    <v-card-actions>
                      <v-checkbox v-model="deleteUsers" color="success" label="Delete all users at once"></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">{{ translations.GLOBAL_VALUE.delete_modal.cancel_btn }}</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ translations.GLOBAL_VALUE.delete_modal.ok_btn }}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
<!--                <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>-->
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    @click.stop="deleteDialog(item)"
                >
                  mdi-delete
                </v-icon>
              </template>

            </v-data-table>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import router from '@/routes/router';
import {deleteAgency, deleteAgencyAdmin, getAgency, postAgency} from "@/api/agencyRequest";
import DeleteModal from "@/components/DeleteModal";
import {api} from "@/providers/api";
import PreloaderPage from "@/components/PreloaderPage.vue";
import {getLikes} from "@/api/likeRequest";
import {debounce} from "lodash";

export default {
  name: 'AgencyAdmin',
  components: {PreloaderPage},
  data: () => ({
    deleteItem: '',
    items: [],
    headers: [
      { text: 'ID', value: 'id', sortable: true },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Owner', value: 'owner.name', sortable: false },
      { text: 'Agency Percent', value: 'agencyPercent', sortable: false },
      { text: 'Girl Percent', value: 'girlPercent', sortable: false },
      { text: 'Balance', value: 'balance', sortable: true, },
      { text: 'Registered At', value: 'createdAt', sortable: true, },
      { text: 'Actions', value: 'actions', sortable: false },
    ],

    search: null,
    isCreateDialog: false,
    form: { name: null },
    dialogDelete: false,
    allAgencyLoading: false,
    deleteUsers: false,
    preloader: true,
    agencyOptions: {},
    totalAgencyItems: 0,
  }),
  async mounted() {
  },

  async created() {
    this.headers = [
      { text: 'ID', value: 'id', sortable: true },
      { text: this.translationsAgencyAdmin.table.headers.name, value: 'name', sortable: false },
      { text: this.translationsAgencyAdmin.table.headers.owner, value: 'owner.name', sortable: false },
      { text: this.translationsAgencyAdmin.table.headers.agency_percent, value: 'agencyPercent', sortable: false },
      { text: this.translationsAgencyAdmin.table.headers.girl_percent, value: 'girlPercent', sortable: false },
      { text: this.translationsAgencyAdmin.table.headers.balance, value: 'balance', sortable: true, },
      { text: this.translationsAgencyAdmin.table.headers.registered_at, value: 'createdAt', sortable: true, },
      { text: this.translationsAgencyAdmin.table.headers.actions, value: 'actions', sortable: false },
    ]
    this.fetchAgencies();
  },
  watch: {
    search() {
      this.debouncedSearch();
    },
    agencyOptions: {
      handler(change) {
        console.log('change payments', change)
        this.fetchAgencies()
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    ...mapGetters('User', ['me']),
    translations() {
      return this.$t('translation')
    },
    translationsAgencyAdmin() {
      return this.translations.AGENCY.AGENCIES
    },
    breadcrumbs() {
      return [
        { text: this.translations.NAVBAR.DASHBOARD.title, href: '/' },
        { text: this.translationsAgencyAdmin.title, disabled: true,}
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },

  },
  methods: {

    debouncedSearch: debounce(function() {
      this.fetchAgencies()
    }, 400),

    openUserNewPage(id) {
      const url = this.$router.resolve(`/users/${id}`).href;
      window.open(url, '_blank');
    },

    async createAgency() {
      const url = new URL(process.env.VUE_APP_BASE_SITE_URL)
      url.pathname = 'become-agency-create'
      const response = await api.get(`/admin/users/${this.me.id}/login`).then(r => r.access_token)
      url.searchParams.append('admin_create_agency_token', response)
      return window.open(url.toString());
    },

    async deleteItemConfirm() {
      await deleteAgencyAdmin(this.deleteItem.id, this.deleteUsers);
      await this.fetchAgencies();
      this.dialogDelete = false
      this.deleteUsers = false
    },

    deleteDialog(item) {
      this.dialogDelete = true
      this.deleteItem = item
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    close() {
      this.isCreateDialog = false;
      this.form.name = '';
    },
    async save() {
      this.isCreateDialog = false;
      await postAgency({ name: this.form.name });
      this.form.name = '';
      this.fetchAgencies();
    },


    async fetchAgencies() {
      this.allAgencyLoading = true

      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.agencyOptions
        const ascParam = sortDesc[0]
        const params = {
          ...sortBy.length ? {asc: ascParam} : {},
          limit: limit === -1 ? 100 : limit,
          page
        }
        if (this.search) {
          params.search = this.search
        }


        const response = await api.get('/admin/agencies', {
          params: params,
        })
        const {data: items, total} = response

        this.totalAgencyItems = total
          // const response = await getAgency();
        this.items = items
        if (this.items.length === 1 && !this.$auth.isAdmin) {
          this.agencyClick(this.items[0])
        }
      }
      catch (e) {
        console.log(e)
      }
      finally {
        this.allAgencyLoading = false
        this.preloader = false
      }

      console.log(this.items)
    },

    agencyClick(item) {
      router.push({ name: 'agency-log', params: { targetAgency: [item], id: item.id } });
    }
  },

};
</script>

<style scoped lang="scss">
.agency {
  .card, .card-man, .card-woman {
    max-width: 330px;
    background: #2f55b4;
    color: white;

    .v-card__title {
      text-transform: uppercase;
    }

    .v-icon {
      color: #f4f6f9;
    }
  }

  .card-man {
    background: #007bff;
  }

  .card-woman {
    background: hotpink;
  }

  .button-add{
    border: 1px solid #fff !important;
  }
  .table-header {
    background: #2f55b4;
    color: white;
    margin-bottom: 5px;

    .search-input {
      margin-right: 50px;
      color: white;

      ::v-deep label {
        color: white;
      }

      ::v-deep .v-icon.v-icon {
        color: white;
      }

      ::v-deep .v-input__slot::before {
        border-color: white;
      }

      ::v-deep input {
        color: white;
      }
    }


  }
}
.online {
  background: #4fa526;
  width: 13px;
  height: 13px;
  position: absolute;
  border-radius: 100px;
  margin-left: 20px;
  border: 2px solid #fff;
  margin-top: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}
.user-title{
  cursor: pointer;
  transition: 0.3s;

  &:hover{
    color: #2f55b4;
  }
}
img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}
.link{
  height: 50px;
}

::v-deep{
  .v-breadcrumbs{
    .v-breadcrumbs__item{
      color: #2f55b4;
    }
    .v-breadcrumbs__divider, .v-breadcrumbs__item--disabled{
      color: rgba(0,0,0,0.6) !important;
    }
  }
}


</style>
