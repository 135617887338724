<template>
  <div>
    <PreloaderPage v-if="withdrawalLoading"/>

    <v-container class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :headers="headers"
          :page="currentDataWithdrawal().page"
          :items="currentDataWithdrawal().items"
          item-key="id"
          show-expand
          class="elevation-1"
          :expanded.sync="expanded"
          :single-expand="singleExpand"
          :options.sync="optionsWithdrawal"
          :server-items-length="currentDataWithdrawal().total"
      >
        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">{{ translationsWithdrawal.title }}</v-toolbar-title>
          </v-toolbar>

        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="row">
              <v-col>
                <v-select outlined v-model="item.status" :items="items"></v-select>
                <v-btn color="success" @click="successStatus(item.id, item.status)">{{ translations.GLOBAL_VALUE.save }}</v-btn>
              </v-col>
            </div>

          </td>
        </template>

        <template v-slot:[`item.agency.name`]="{ item }">
            <span class="agency-name"  @click.stop="openAgencyPage(item.agency.id)">{{ item.agency.name }}</span>
        </template>


        <template v-slot:[`item.status`]="{ item }">
          <div>
            <v-chip
                :color="getColor(item.status)"
                dark
                small
            >
              {{ item.status }}
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.summaryAmount`]="{ item }">
          <div>
            <v-chip
                color="green"
                dark
                small
            >
              {{ item.summaryAmount }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>


        <!--        <template v-slot:[`item.membersAmount`]="{ item }">-->
        <!--          <div>-->
        <!--            <v-chip-->
        <!--                color="blue"-->
        <!--                dark-->
        <!--                small-->
        <!--            >-->
        <!--              {{ item.membersAmount }}-->
        <!--            </v-chip>-->
        <!--          </div>-->
        <!--        </template>-->
        <!--        <template v-slot:[`item.agencyAmount`]="{ item }">-->
        <!--          <div>-->
        <!--            <v-chip-->
        <!--                color="blue"-->
        <!--                dark-->
        <!--                small-->
        <!--            >-->
        <!--              {{ item.agencyAmount }}-->
        <!--            </v-chip>-->
        <!--          </div>-->
        <!--        </template>-->


      </v-data-table>
    </v-container>
  </div>
</template>

<script>


import {changeStatusWithdrawal, getWithdrawals} from '@/api/transactionsRequest';
import {api} from "@/providers/api";
import {mapGetters} from "vuex";
import PreloaderPage from "@/components/PreloaderPage.vue";

export default {
  name: 'WithdrawalOfFunds',
  components: {PreloaderPage},
  data() {
    return {
      showBtn: false,
      expanded: [],
      singleExpand: true,
      withdrawal: [],
      items: ['PENDING', 'PROCESSING', 'APPROVED', 'REJECTED', 'FAILED'],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Agency',
          sortable: false,
          value: 'agency.name'
        },

        {
          text: 'Agency amount',
          sortable: false,
          value: 'agencyAmount',
        },
        {
          text: 'Members amount',
          sortable: false,
          value: 'membersAmount',
        },
        {
          text: 'Summary amount',
          sortable: false,
          value: 'summaryAmount',
        },
        {
          text: 'Created',
          value: 'createdAt',
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status',
        },
      ],

      users: [],
      payments: [],

      withdrawals: [],

      editedIndex: -1,
      editedItem: {
        id: '',
        from: '',
        to: '',
        avatar: '',
        avatar2: '',
        gift: {
          file: ''
        }
      },
      totalWithdrawalItem: 0,
      loadingWithdrawal: false,
      optionsWithdrawal: {},

      failWithdraw: false,
      successWithdraw: null,
      withdrawalLoading: false,
      firstLoadPage: true,
      queryParams: {},
    };
  },
  computed: {
    ...mapGetters('Admin', [
      'getterData'
    ]),
    translations() {
      return this.$t('translation')
    },
    translationsWithdrawal() {
      return this.translations.WITHDRAWAL.table
    },
  },

  async created() {
    this.headers = [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: this.translationsWithdrawal.headers.agency,
          sortable: false,
          value: 'agency.name'
        },

        {
          text: this.translationsWithdrawal.headers.agency_amount,
          sortable: false,
          value: 'agencyAmount',
        },
        {
          text: this.translationsWithdrawal.headers.members_amount,
          sortable: false,
          value: 'membersAmount',
        },
        {
          text: this.translationsWithdrawal.headers.summary_amount,
          sortable: false,
          value: 'summaryAmount',
        },
        {
          text: this.translationsWithdrawal.headers.created_at,
          sortable: false,
          value: 'createdAt',
        },
        {
          text: this.translationsWithdrawal.headers.status,
          sortable: false,
          value: 'status',
        },
  ]
    console.log('CREATED')
    const currentDataWithdrawal = this.currentDataWithdrawal();
    this.queryParams = this.updateURLParams(
        currentDataWithdrawal.page || this.$route.query.page || 1,
        currentDataWithdrawal.limit || this.$route.query.limit || 10);
    this.optionsWithdrawal.itemsPerPage = +this.queryParams.limit;
  },
  mounted() {

  },
  watch: {
    optionsWithdrawal: {
      handler(change) {

        if (this.firstLoadPage) {
          this.syncWithdrawalFromApi(+this.queryParams.page, +this.queryParams.limit);
          this.firstLoadPage = false;
        } else {
          this.updateURLParams(change.page, change.itemsPerPage);
          this.syncWithdrawalFromApi(change.page, change.itemsPerPage);
        }
        console.log('CHANGE', change)
      },
      deep: true
    }
  },

  methods: {
    openAgencyPage(id) {
      this.$router.push({path: `/agencies/${id}/info`})
    },
    updateURLParams(page, limit) {
      // console.log('PAGE AND LIMIT', page, limit)
      this.$router.push({
        path: this.$route.path,
        query: {
          page: page.toString(),
          limit: limit.toString(),
        },
      });
      return {page: page, limit: limit}
    },
    async syncWithdrawalFromApi(page = null, itemsPerPage = null) {
      console.log('FETCH', page, itemsPerPage)
      try {
        this.withdrawalLoading = true;
        const {sortBy, sortDesc} = this.optionsWithdrawal;
        const response = await api.get('/admin/agencies-withdrawal', {
          params: {
            limit: itemsPerPage,
            page: page,
          },
        });


        const {data: items, total,} = response;

        this.withdrawal = items;
        this.totalWithdrawalItem = total;
        await this.$store.dispatch('Admin/addData', {
          key: 'withdrawal',
          value: {
            items: this.withdrawal,
            total: this.totalWithdrawalItem,
            page: page,
            limit: itemsPerPage
          },
        });

      } catch (e) {
        console.log(e);
      } finally {
        this.withdrawalLoading = false;
      }
    },
    currentDataWithdrawal() {
      return this.getterData('withdrawal')
    },
    getColor(status) {
      if (status === 'PENDING') {
        return "orange";
      } else if (status === 'PROCESSING') return 'blue';
      else if (status === 'APPROVED') return 'green';
      else if (status === 'REJECTED') return 'red';
      else return 'purple';
    },


    async successStatus(id, status) {
      try {
        await api.post(`/admin/agencies-withdrawal/${id}/status?status=${status}`)
      } finally {
        this.expanded = []
        await this.syncWithdrawalFromApi(+this.queryParams.page, +this.queryParams.limit);
      }


    },


  },
};
</script>

<style scoped lang="scss">
.processing {
  color: white;
}

.row {
  max-width: 350px;
  display: flex;
  flex-wrap: nowrap;
  margin: 5px;

  .user-img {
    margin-right: 30px;
  }

  .v-btn {
    margin-right: 5px;
    margin-bottom: 15px;
  }
}

.title {
  color: #f4f6f9;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}
.agency-name{
  cursor: pointer;
  transition: 0.3s;
  &:hover{
    color: #2f55b4;
  }
}
</style>
