import Vue from 'vue';
import VueRouter from 'vue-router';
import MyDashboard from '@/views/Dashboard/Dashboard';
import AgencyAdmin from '@/views/Agency/AgencyAdmin';
import AgencyTasks from '@/views/EmptyComponents/ManageUsers/AgencyTasks';
import CharReport from '@/views/EmptyComponents/ManageUsers/CharReport';
import ChatLog from '@/views/Logs/ChatLog';
import DeleteFinesAndBonus from '@/views/EmptyComponents/ManageUsers/DeleteFinesAndBonus';
import EmployeeLog from '@/views/Logs/EmployeeLog';
import FlirtLogs from '@/views/Logs/FlirtLogs';
import HighRollers from '@/views/EmptyComponents/ManageUsers/HighRollers';
import RoleManager from '@/views/RoleManager/RoleManager';
import SearchArchive from '@/views/EmptyComponents/ManageUsers/SearchArchive';
import CreditCardHistory from '@/views/Payments/CreditCardHistory';
import AgencyBonus from '@/views/Payments/AgencyBonus';
import MyModules from '@/views/EmptyComponents/ContentManager/MyModules';
import FAQ from '@/views/FAQ/FAQ';
import AdminModules from '@/views/EmptyComponents/ContentManager/AdminModules';
import AgencyModules from '@/views/EmptyComponents/ContentManager/AgencyModules';
import WireExport from '@/views/EmptyComponents/ContentManager/WireExport';
import GiftDelivery from '@/views/GiftSection/GiftDelivery';
import GiftShop from '@/views/GiftSection/GiftShop';
import FreeEmail from '@/views/EmptyComponents/EmailManager/FreeEmail';
import EmailManager from '@/views/Configuration/EmailManager.vue';
import AffilatesLeads from '@/views/EmptyComponents/AffilateManager/AffilatesLeads';
import MyCreatives from '@/views/EmptyComponents/AffilateManager/MyCreatives';
import ManageTagManager from '@/views/EmptyComponents/Seo/ManageTagManager';
import AgencyApplications from '@/views/EmptyComponents/Statistics/AgencyApplications';
import ChatMessagesAnalytics from '@/views/EmptyComponents/Statistics/ChatMessagesAnalytics';
import MyConversion from '@/views/EmptyComponents/Statistics/MyConversion';
import AgencyAdjustments from '@/views/EmptyComponents/Statistics/AgencyAdjustments';
import MostLikeFoto from '@/views/EmptyComponents/Statistics/MostLikeFoto';
import MyAuth from '@/views/Auth/MyAuth';
import faqPage from '@/views/FAQ/faq-page.vue';

import { middleware } from 'vue-router-middleware';
import LikeLog from '@/views/Logs/LikeLog';
import MyMemberships from '@/views/Memberships/MyMemberships';
import PaymentsLog from '@/views/Logs/PaymentsLog';
import UserLog from '@/views/Users/UserLog';
import EditUser from '@/views/EditUser/EditUser';
import UserMessageLog from '@/views/Users/UserMessageLog';
import ModerationUsersLog from '@/views/Users/ModerationUsersLog';
import AgencyLog from '@/views/Agency/AgencyLog';
import WithdrawalOfFunds from '@/views/Payments/WithdrawalOfFunds';
import UsersLog from '@/views/Users/UsersLog';
import ContactLog from '@/views/Logs/ContactLog';
import CommissionPrice from '@/views/Payments/CommissionPrice';
import BlogLog from "@/views/Blog/BlogLog";
import EditBlog from "@/views/Blog/EditBlog";
import NewBlog from "@/views/Blog/NewBlog";
import ChatDatesLog from "@/views/Logs/ChatDatesLog";
import BotPage from "@/views/Bot/BotPage";
import UsersAgency from "@/views/Agency/UsersAgency";
import AgencyAdminTableWithdrawel from "@/views/Agency/AgencyAdminTableWithdrawel";
import UsersContract from "@/views/Agency/UsersContract";
import AgencyAddContractFile from "@/views/Agency/AgencyAddContractFile.vue";
import AddWoman from "@/views/AddWoman/AddWoman.vue";
import Configuration from "@/views/Configuration/Configuration.vue";
import AgencyLogStaff from "@/views/Agency/AgencyLogStaff.vue";
import AgencyLogTransactions from "@/views/Agency/AgencyLogTransactions.vue";
import AgencyLogChats from "@/views/Agency/AgencyLogChats.vue";
import AgencyLogActivities from "@/views/Agency/AgencyLogActivities.vue";
import AgencyLogTableWithdrawer from "@/views/Agency/AgencyLogTableWithdrawer.vue";
import AgencyLogStats from "@/views/Agency/AgencyLogStats.vue";
import AgencyLogSettings from "@/views/Agency/AgencyLogSettings.vue";
import AgencyPayOut from "@/views/Agency/AgencyPayOut.vue";
import EmailManagerEdit from "@/views/Configuration/EmailManagerEdit.vue";
import EmailManagerUsersList from "@/views/Configuration/EmailManagerUsersList.vue";
import KeyEdit from "@/views/Configuration/KeyEdit.vue";
import AgencyLogInfo from "@/views/Agency/AgencyLogInfo.vue";
import AgencyLogMessages from "@/views/Agency/AgencyLogMessages.vue";
import Emoji from "@/views/Emoji/Emoji.vue";
import AgencyNotificationTable from "@/views/AgencyNotification/AgencyNotificationTable.vue";
import IntroductionsLog from "@/views/Logs/IntroductionsLog.vue";
import SubscriptionCancel from "@/views/Logs/SubscriptionCancel.vue";
import UsersTextTable from "@/views/Users/UsersTextTable.vue";
import UsersPhotoTable from "@/views/Users/UsersPhotoTable.vue";
import UsersPassportTable from "@/views/Users/UsersPassportTable.vue";
import UsersVideosTable from "@/views/Users/UsersVideosTable.vue";
import CampaignsPage from "@/views/Campaigns/CampaignsPage";
import UsersClosingAccountTable from "@/views/Users/UsersClosingAccountTable.vue";
import MainNotificationsCampaign from "@/views/Campaigns/NotificationsCampaign/MainNotificationsCampaign.vue";
import CreditsPage from "@/views/Payments/CreditsPage.vue";
import UserAttachmentsTable from "@/views/Users/UserAttachmentsTable.vue";
import OperatorInfo from "@/views/Operator/OperatorInfo.vue";

Vue.use(VueRouter);

const routes = middleware('authorized', [
  {
    path: '/',
    component: MyDashboard,
    name: 'dashboard',
    meta: { requiresAuth: true }
  },
  //Bot
  {
    path: '/bot',
    component: BotPage,
    name: 'bot',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/emoji',
    component: Emoji,
    name: 'emoji',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },

  //Campaigns
  {
    path: '/campaigns',
    component: CampaignsPage,
    name: 'campaigns',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
  },
  {
    path: '/campaigns/:id',
    component: MainNotificationsCampaign,
    name: 'campaign-id',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
  },
  //Blog
  {
    path: '/configuration',
    component: Configuration,
    name: 'configuration',
    meta: { requiresAuth: true }
  },
  {
    path: '/key-edit',
    component: KeyEdit,
    name: 'key-edit',
    meta: { requiresAuth: true }
  },

  {
    path: '/faq-page',
    component: faqPage,
    name: 'faq-page',
    meta: { requiresAuth: true }
  },
  {
    path: '/email-manager',
    component: EmailManager,
    name: 'email-manager',
    meta: { requiresAuth: true }
  },
  {
    path: '/email-manager/:id',
    component: EmailManagerEdit,
    name: 'email-manager-edit',
    meta: { requiresAuth: true }
  },
  {
    path: '/email-manager/:id/users',
    component: EmailManagerUsersList,
    name: 'email-manager-users',
    meta: { requiresAuth: true }
  },
  {
    path: '/blog-log',
    component: BlogLog,
    name: 'blog-log',
    meta: { requiresAuth: true }
  },
  {
    path: '/add-blog',
    component: NewBlog,
    name: 'add-blog',
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-blog/:id',
    component: EditBlog,
    name: 'edit-blog',
    props: true,
    meta: { requiresAuth: true }
  },

  {
    path: '/add-woman/:id',
    component: AddWoman,
    name: 'add-woman',
    meta: { requiresAuth: true },
    props: true,
  },



  //Manage Users
  {
    path: '/users',
    component: UsersLog,
    name: 'users',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/contact-log',
    component: ContactLog,
    name: 'contact-log',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/agencies',
    component: AgencyAdmin,
    name: 'agencies',
    meta: { requiresAuth: true }
  },

  // {
  //   path: '/agency-contract',
  //   component: UsersContract,
  //   name: 'agency-contract',
  //   meta: { requiresAuth: true }
  // },

  {
    path: '/agency-contract',
    component: AgencyAddContractFile,
    name: 'agency-contract',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/agency-created-by-users',
    component: UsersAgency,
    name: 'agency-created-by-users',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/agency-admin-withdrawal',
    component: AgencyAdminTableWithdrawel,
    name: 'agency-admin-withdrawal',
    meta: { requiresAuth: true }
  },
  {
    path: '/payments',
    component: PaymentsLog,
    name: 'payments',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/letter-log',
    component: IntroductionsLog,
    name: 'payments',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/users/:id',
    component: UserLog,
    name: 'user-log',
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/user-messages-chat/chat_id/:id',
    component: UserMessageLog,
    name: 'user-messages-chat',
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/agency-tasks',
    component: AgencyTasks,
    name: 'agency-tasks',
    meta: { requiresAuth: true }
  },
  {
    path: '/chart-report',
    component: CharReport,
    name: 'chart-report',
    meta: { requiresAuth: true }
  },
  {
    path: '/chat-log',
    component: ChatLog,
    name: 'chat-log',
    meta: { requiresAuth: true }
  },
  {
    path: '/chat-dates',
    component: ChatDatesLog,
    name: 'chat-dates',
    meta: { requiresAuth: true }
  },
  {
    path: '/like-log',
    component: LikeLog,
    name: 'like-log',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/subscription-cancel',
    component: SubscriptionCancel,
    name: 'subscription-cancel',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/memberships',
    component: MyMemberships,
    name: 'memberships',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/delete-fines-and-bonus',
    component: DeleteFinesAndBonus,
    name: 'delete-fines-and-bonus',
    meta: { requiresAuth: true }
  },
  {
    path: '/employee-log',
    component: EmployeeLog,
    name: 'employee-log',
    meta: { requiresAuth: true }
  },
  {
    path: '/flirt-logs',
    component: FlirtLogs,
    name: 'flirt-logs',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },

  {
    path: '/high-rollers',
    component: HighRollers,
    name: 'high-rollers',
    meta: { requiresAuth: true }
  },
  {
    path: '/role-manager',
    component: RoleManager,
    name: 'role-manager',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/search-archive',
    component: SearchArchive,
    name: 'search-archive',
    meta: { requiresAuth: true }
  },
  {
    path: '/moderation-users-log',
    component: ModerationUsersLog,
    name: 'moderation-users-log',
    redirect: {name: 'photo-log'},
    children: [
      {
        path: 'photo-log',
        component: UsersPhotoTable,
        name: 'photo-log',
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'text-log',
        component: UsersTextTable,
        name: 'text-log',
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'passport-log',
        component: UsersPassportTable,
        name: 'passport-log',
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'video-log',
        component: UsersVideosTable,
        name: 'video-log',
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'closing-account-log',
        component: UsersClosingAccountTable,
        name: 'closing-account-log',
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'attachments-log',
        component: UserAttachmentsTable,
        name: 'attachments-log',
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
    ]
  },

  {
    path: '/agency-notifications',
    component: AgencyNotificationTable,
    name: 'agency-notifications',
    meta: { requiresAuth: true }
  },


  {
    path: '/users/:id/edit',
    component: EditUser,
    name: 'edit-user',
    meta: { requiresAuth: true },
    // props: true
  },

  //PAYMENTS
  {
    path: '/commission-prices',
    component: CommissionPrice,
    name: 'commission-prices',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/credit-card',
    component: CreditCardHistory,
    name: 'credit-card',
    meta: { requiresAuth: true }
  },
  {
    path: '/high-rollers',
    component: HighRollers,
    name: 'high-rollers',
    meta: { requiresAuth: true }
  },
  {
    path: '/agency-bonus',
    component: AgencyBonus,
    name: 'agency-bonus',
    meta: { requiresAuth: true }
  },
  {
    path: '/withdrawal',
    component: WithdrawalOfFunds,
    name: 'withdrawal',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/credits-price',
    component: CreditsPage,
    name: 'credits-price',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },

  //Content manager
  {
    path: '/modules',
    component: MyModules,
    name: 'modules',
    meta: { requiresAuth: true }
  },
  {
    path: '/faq',
    component: FAQ,
    name: 'faq',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/admin-modules',
    component: AdminModules,
    name: 'admin-modules',
    meta: { requiresAuth: true }
  },
  {
    path: '/agency-modules',
    component: AgencyModules,
    name: 'agency-modules',
    meta: { requiresAuth: true }
  },
  {
    path: '/wire-export',
    component: WireExport,
    name: 'wire-export',
    meta: { requiresAuth: true }
  },

  //Gift section
  {
    path: '/girl-delivery',
    component: GiftDelivery,
    name: 'girl-delivery',
    meta: { requiresAuth: true }
  },
  {
    path: '/girl-shop',
    component: GiftShop,
    name: 'girl-shop',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },

  //Email manager
  {
    path: '/free-email',
    component: FreeEmail,
    name: 'free-email',
    meta: { requiresAuth: true }
  },
  {
    path: '/email-manager',
    component: EmailManager,
    name: 'email-manager',
    meta: { requiresAuth: true }
  },

  //Affilate manager
  {
    path: '/affilate',
    component: AffilatesLeads,
    name: 'affilate',
    meta: { requiresAuth: true }
  },
  {
    path: '/creatives',
    component: MyCreatives,
    name: 'creatives',
    meta: { requiresAuth: true }
  },

  //SEO
  {
    path: '/manage-tag-manager',
    component: ManageTagManager,
    name: 'manage-tag-manager',
    meta: { requiresAuth: true }
  },

  //Statistics
  {
    path: '/agency-app',
    component: AgencyApplications,
    name: 'agency-app',
    meta: { requiresAuth: true }
  },
  {
    path: '/chat-messages',
    component: ChatMessagesAnalytics,
    name: 'chat-messages',
    meta: { requiresAuth: true }
  },
  {
    path: '/conversion',
    component: MyConversion,
    name: 'conversion',
    meta: { requiresAuth: true }
  },
  {
    path: '/agency-adjustments',
    component: AgencyAdjustments,
    name: 'agency-adjustments',
    meta: { requiresAuth: true }
  },
  {
    path: '/most-like',
    component: MostLikeFoto,
    name: 'most-like',
    meta: { requiresAuth: true }
  },
  {
    path: '/agency-info',
    name: 'agency-info',
    component: AgencyLogInfo,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/operator-info',
    name: 'operator-info',
    component: OperatorInfo,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/agencies/:id',
    component: AgencyLog,
    name: 'agency-log',
    meta: { requiresAuth: true },
    props: true,
    redirect: {name: 'agency-staff'},
    children: [
      {
        path: 'staff',
        name: 'agency-staff',
        component: AgencyLogStaff,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: 'transactions',
        name: 'agency-transactions',
        component: AgencyLogTransactions,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: 'chats',
        name: 'agency-chats',
        component: AgencyLogChats,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: 'messages/:id',
        name: 'agency-messages',
        component: AgencyLogMessages,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: 'activities',
        name: 'agency-activities',
        component: AgencyLogActivities,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: 'withdrawal',
        name: 'agency-withdrawal',
        component: AgencyLogTableWithdrawer,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: 'stats',
        name: 'agency-stats',
        component: AgencyLogStats,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: 'settings',
        name: 'agency-settings',
        component: AgencyLogSettings,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: 'pay-out',
        name: 'agency-pay-out',
        component: AgencyPayOut,
        meta: { requiresAuth: true },
        props: true,
      },
    ],
  },

]);

const unAuthRoutes = middleware('unauthorized', [
  {
    path: '/login',
    component: MyAuth,
    name: 'login',
    meta: {
      hideNavbar: true,
    },
  },
]);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes, ...unAuthRoutes]
});

export default router;

