import { api } from "@/providers/api";
import store from "@/store";
import { getApiAuth } from "@/providers/auth";
import {getAuthMe} from "@/api/authRequest";

async function authorized(args, to, from, next) {
  if (!getApiAuth()) {
    return next("/login");
  }
  if (!store.getters["User/me"]) {
    const user = await getAuthMe()
    store.commit("User/SET_USER_ME", user);

    if (to.meta.requiresAdmin && !user.roles.map(r => r.name).includes('admin') && !user.roles.map(r => r.name).includes('operator')) {
      return next('/');
    }
  }


  return next();
}

async function unauthorized(args, to, from, next) {
  if (getApiAuth()) {
    return next({
      name: "dashboard",
    });
  }
  return next();
}

export const routerMiddlewares = {
  authorized,
  unauthorized,
};
