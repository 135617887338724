import {api} from "@/providers/api";

export async function getAgencyTransactionsId(id) {
  return api.get(`/admin/agencies/${id}/transactions`)
}

export async function getAgencyMessageId(id) {
  return api.get(`/admin/agencies/${id}/messages`)
}
export async function getAgencyBalanceId(id) {
  return api.get(`/admin/agencies/${id}/balance`)
}

export async function getAgencyById(id) {
  return api.get(`/v2/admin/agencies/${id}`)
}
export async function getTotalUsersInAgencyById(id) {
  const { total } = await api
    .get(`/admin/agencies/${id}/users`, {
      params: {
        limit: 1,
        page: 1,
      }
    });
  return total
}
export async function addAgencyUser(user_id, agency_id) {
  return api.post('/admin/agencies/user', user_id, agency_id)
}
export async function getActivityById(id) {
  return api.get(`/admin/agencies/${id}/activity`)
}
export async function getAgency() {
  return api.get('/admin/agencies')
}
export async function postAgency(data) {
  return api.post('/agencies', data)
}
export async function deleteAgency(id) {
  return api.delete('/agencies/' + id)
}
export async function deleteAgencyAdmin(id, deleteUsers) {
  return api.delete('/admin/agencies/' + id, {
    params: {
      deleteUsers: deleteUsers
    }
  })
}
export async function addUsersAgency(userId, agencyId, userRole) {
  return api.post(`/admin/agencies/${agencyId}/users`, {}, {
    params: {
      user_id: userId,
      ...userRole ? { user_role_id: userRole } : {},
    }
  })
}
export async function deleteUsersAgency(userId, agencyId) {
  return api.delete(`/admin/agencies/${agencyId}/users/${userId}`);
}

export async function getBecomeAgency(status) {
  return await api.get('/admin/agencies-requests', {
    params: {
      status: status
    }
  })
}

export async function getBecomeAgencyByStatus(data) {
  return await api.get('/admin/agencies-requests', data)
}

export async function postStatusAgency(id, status, reworkMessage, rejectMessage) {
  return await api.post(`/admin/agencies-requests/${id}/status`, {}, {
    params: {
      status: status,
      reworkMessage: reworkMessage,
      rejectMessage: rejectMessage,
    }
  });
}

export async function setGirlPercent(agencyId, girlPercent) {
  return await api.post(`/admin/agencies/${agencyId}/taxes`, {}, {
    params: {
      girlPercent
    }
  })
}

export async function setAgencyPercent(agencyId, agencyPercent) {
  return await api.post(`/admin/agencies/${agencyId}/taxes`, {}, {
    params: {
      agencyPercent
    }
  })
}
