var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 50, 100]
    },"items-per-page":_vm.itemsPerPage,"headers":_vm.header,"items":_vm.chatDates,"server-items-length":_vm.totalItemsChatDates,"options":_vm.chatDatesOptions,"loading":_vm.chatDatedLoading,"page":_vm.page},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:options":function($event){_vm.chatDatesOptions=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('DeleteModal',{attrs:{"dialog":_vm.dialogDelete},on:{"close-options":_vm.closeDelete,"save-options":_vm.deleteItemConfirm}}),_c('v-toolbar',{attrs:{"flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.translationsChatDatesLog.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":_vm.toggleSearchFields}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),(_vm.showSearchFields)?_c('TableSearchFields',{attrs:{"searchFields":_vm.searchFields},on:{"add-field":_vm.addSearchField,"remove-field":_vm.removeSearchField,"update-fields":_vm.updateSearchCriteria,"clear-fields":_vm.clearFields}}):_vm._e()]},proxy:true},{key:"item.to",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"user-img",on:{"click":function($event){return _vm.userOpen(item.to)}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.to)}}),_vm._v(" "+_vm._s(_vm.$getUserName(item.to))+" "),(item.to.id !== _vm.me.id && !_vm.$auth.isAdmin && !_vm.$auth.isOperator)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.to.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1)]}},{key:"item.from",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"user-img",on:{"click":function($event){return _vm.userOpen(item.from)}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.from)}}),_vm._v(" "+_vm._s(_vm.$getUserName(item.from))+" "),(item.from.id !== _vm.me.id && !_vm.$auth.isAdmin  && !_vm.$auth.isOperator)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.from.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1)]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.start",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.start),'Pp')))]}},{key:"item.end",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.end),'Pp')))]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"input-page"},[_c('v-row',{staticClass:"mx-2",attrs:{"align":"center","justify":"start","align-content":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"single-line":"","hide-details":"","label":"Go to page","type":"number","min":"1","max":_vm.pageCount},on:{"change":_vm.updatePage},model:{value:(_vm.pageInput),callback:function ($$v) {_vm.pageInput=$$v},expression:"pageInput"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('span',[_vm._v(" Pages: "+_vm._s(_vm.page)+" / "+_vm._s(_vm.pageCount)+" ")])])],1)],1)]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }