<template>
  <div>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
              :headers="headers"
              :items="this.roles"
              sort-by="calories"
              class="elevation-1"
              :loading="rolesLoading"
      >
        <template v-slot:top>
          <v-toolbar
                  flat
                  color="primary"
          >
            <v-toolbar-title class="title">{{ translation.ROLE_MANAGER.table_roles.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog
                    v-model="dialog"
                    max-width="500px"
            >
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-btn
                        color="white"
                        outlined
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                >
                  {{ translation.ROLE_MANAGER.table_roles.btn_new_role }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ translation.ROLE_MANAGER.table_roles.modal_title }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                              cols="12"
                              sm="6"
                              md="4"
                      >
                        <v-text-field
                                v-model="editedItem.name"
                                :label="translation.ROLE_MANAGER.table_roles.modal_label"
                        ></v-text-field>
                      </v-col>


                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions >
                  <v-spacer></v-spacer>
                  <v-btn
                          color="primary"
                          text
                          @click="close"
                  >
                    {{ translation.GLOBAL_VALUE.cancel }}
                  </v-btn>
                  <v-btn
                          color="primary"
                          text
                          @click="save"
                  >
                    {{ translation.GLOBAL_VALUE.save }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>
          </v-toolbar>
        </template>
        <template  v-slot:[`item.actions`]="{ item }">
          <v-icon
                  v-if="item.name !== 'admin'"
                  small
                  @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>

      <div class="select-container">
        <v-select
                filled
                v-model="selected"
                :items="this.roles"
                item-text="name"
                item-value="name"
                :label="translation.ROLE_MANAGER.select_role_label"
                persistent-hint
                return-object
                single-line
                style="max-width: 400px"
                @change="sortRoles"
        ></v-select>
      </div>


      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
              :headers="headersUsers"
              :items="this.sortList"
              item-key="name"
              class="table elevation-1"
              :loading="sortListLoading"


      >
        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">{{ translation.ROLE_MANAGER.table_users_roles.title }}</v-toolbar-title>
          </v-toolbar>

        </template>
        <template v-slot:[`item.profile.name`]="{ item }" >
          <div class="user-img">
            <img :src="$getAvatarImage(item)">
            {{ item.profile.name }}
          </div>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
        <template v-slot:[`item.balance`]="{ item }">{{ $getBalance(item.balance) }}</template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>

  import { getRoles } from "@/api/usersRequest";
  import { addRoles } from "@/api/usersRequest";
  import {editMember} from "@/api/membershipRequest";
  import { deleteRoles} from "@/api/usersRequest";
  import DeleteModal from "@/components/DeleteModal";
  import {api} from "@/providers/api";

  export default {
    name: "RoleManager",
    components: {DeleteModal},
    data: () => ({
      selected: '',
      roles: [],
      dialog: false,
      rolesLoading: false,
      sortListLoading: false,
      dialogDelete: false,
      headers: [
        {text: 'ID', align: 'start', value: 'id'},
        {
          text: 'Name',
          value: 'name',
        },

        { text: 'Actions', value: 'actions', sortable: false },
      ],

      headersUsers: [
        {
          text: 'NAME',
          align: 'start',
          sortable: false,
          value: 'profile.name',
        },
        { text: 'REGISTERED AT', value: 'createdAt' },
        { text: 'BALANCE', value: 'balance' },
        { text: 'email', value: 'email' },

      ],
      editedIndex: -1,
      membership: {
        membershipTariff: {
          name: ''
        }
      },
      editedItem: {
        name: '',

      },
      defaultItem: {
        name: '',

      },
      data: {},
      sortList: [],
      deleteId: null
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Role' : 'Edit Role'
      },
      translation() {
        return this.$t('translation')
      }
    },

    async mounted() {

    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.headers = [
        {text: 'ID', align: 'start', value: 'id'},
        {
          text: this.translation.ROLE_MANAGER.table_roles.headers.name,
          value: 'name',
        },

        { text: this.translation.ROLE_MANAGER.table_roles.headers.actions, value: 'actions', sortable: false },
      ]
      this.headersUsers = [
        {
          text: this.translation.ROLE_MANAGER.table_users_roles.headers.name,
          align: 'start',
          sortable: false,
          value: 'profile.name',
        },
        { text: this.translation.ROLE_MANAGER.table_users_roles.headers.registered_at, value: 'createdAt' },
        { text: this.translation.ROLE_MANAGER.table_users_roles.headers.balance, value: 'balance' },
        { text: this.translation.ROLE_MANAGER.table_users_roles.headers.email, value: 'email' },

      ],
      this.fetchRoles();
    },

    methods: {
      async fetchRoles() {
        try {
          this.rolesLoading = true
          this.roles =  await getRoles();
          return this.roles;
        }
        catch (e) {
          console.log(e)
        }
        finally {
          this.rolesLoading = false
        }
      },

      async sortRoles() {
        try {
          this.sortListLoading = true
          this.sortList = await api.get('/admin/users/roles/' + this.selected.id)
        }
        catch (e) {
          console.log(e)
        }
        finally {
          this.sortListLoading = false
        }


      },

      editItem (item) {
        console.log(item)
        this.editedIndex = this.roles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true

      },

      deleteItem (item) {
        this.editedIndex = this.roles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
        this.deleteId = item

      },

      async deleteItemConfirm () {

        await deleteRoles(this.deleteId.name)
        this.fetchRoles()
        this.closeDelete();
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        if (this.editedIndex > -1) {
          Object.assign(this.roles[this.editedIndex], this.editedItem)
          await editMember(this.editedItem.id, {
            name: this.editedItem.name,
          })
        } else {
          this.roles.push(this.editedItem)
          const response = await addRoles({
            name: this.editedItem.name,
          })
          this.editedItem.id = response.id
        }
        this.close()
      },
    },
  }
</script>

<style scoped lang="scss">
  .title {
    color: #f4f6f9;
  }

  .select-container{
    margin-top: 20px;

    display: flex;
    align-items: center;
    .v-select {
      margin-right: 20px;
    }
    .v-btn {
      background: #343a40;
      color: #f4f6f9;
    }
  }

  .table{
    .user-img {
      display: flex;
      align-items: center;
    }

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 20px;
    }
  }

</style>
