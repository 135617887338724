<template>
  <div>
    <div
        v-if="isUserLoading">

      <v-progress-linear
          indeterminate
          color="cyan"
      ></v-progress-linear>
    </div>
    <div
        v-else
    >
      <v-alert
          :value="copyPass"
          border="top"
          color="gray"
          dark
          transition="fade-transition"
          class="alert"
      >
        Password copied
      </v-alert>
      <v-alert
          v-model="alertShow"
          class="alert"
          border="top"
          transition="fade-transition"
          :type="alertStatus ? 'success' : 'error'"
      >
        {{ alertText }}
      </v-alert>
      <v-row class="my-5">


        <v-col md="8"
               lg="8"
               offset-md="2">
          <MyBreadcrumbs class="mb-3" :breadcrumbs="breadcrumbs"/>
          <v-card outlined color="#f4f6f9">


            <v-row>
              <v-avatar size="30" color="primary" class="title-count mr-2">1</v-avatar>
              <h2 class="text-overline">{{ translationsEditUser.main_info_block.title }}</h2>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="3"
              >
                <div class="upload-button">
                  <!--                  <p class="text-caption text-overline">User avatar</p>-->
                  <AppImageAvatar
                      class="edit-user-avatar"
                      v-model="newAvatar"
                      :url="profileForm.profile.avatar"
                  />
                  <v-btn color="primary" @click="downloadAvatar">{{ translationsEditUser.main_info_block.btn_download_avatar }}</v-btn>

                </div>

                <!--              <v-btn-->
                <!--                  color="success"-->
                <!--                  class="mr-4"-->
                <!--                  @click="saveAvatar"-->
                <!--              >-->
                <!--                Save-->
                <!--              </v-btn>-->
              </v-col>
              <v-col cols="12" md="1">
                <v-divider vertical/>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
                  v-if="this.$auth.isAdmin"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{on, attrs}">
                    <div v-on="on">
                      <v-select
                          v-bind="attrs"
                          v-model="currentMembershipTariff"
                          :hint="`${membershipTariffStartEnd(profileForm)}`"
                          :items="memberships"
                          class="mb-2"
                          item-text="name"
                          item-value="id"
                          :label="translationsEditUser.main_info_block.select_membership_label"
                          outlined
                          persistent-hint
                          return-object
                          append-icon="mdi-close"
                          @click:append="clearMembership"
                          @change="membershipTariffCheck"
                      >
                        <template v-slot:message="{ message }">
                          <v-menu
                              ref="menu"
                              v-model="menuDate"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                                <span :class="{ 'new-membership': newMembership}" v-html="message" v-bind="attrs"
                                      v-on="on"></span>

                            </template>
                            <v-date-picker
                                v-model="date"
                                :events="oldDateMembership"
                                event-color="green lighten-1"
                                no-title
                                scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="menuDate = false"
                              >
                                {{ translations.GLOBAL_VALUE.cancel }}
                              </v-btn>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="saveDateMembership(date)"
                              >
                                {{ translations.GLOBAL_VALUE.ok }}
                              </v-btn>
                            </v-date-picker>
                          </v-menu>

                        </template>

                      </v-select>
                    </div>
                  </template>
                  <span>{{ translationsEditUser.main_info_block.select_membership_description }}</span>
                </v-tooltip>

                <v-autocomplete
                    :value="profileForm.roles"
                    :items="roles"
                    class="mb-2"
                    outlined
                    :label="translationsEditUser.main_info_block.roles_label"
                    multiple
                    small-chips
                    chips
                    item-text="name"
                    item-value="id"
                    return-object
                    @change="(event) => updateUserRoles(event)"
                ></v-autocomplete>

                <!--              <v-btn-->
                <!--                  color="success"-->
                <!--                  @click="saveMembership"-->
                <!--                  class="save-member"-->
                <!--              >-->
                <!--                Save membership-->
                <!--              </v-btn>-->
              </v-col>
              <v-col
                  cols="12"
                  md="4"
                  class="text-right"
              >
                <v-select
                    v-if="this.$auth.isAdmin"
                    :items="statusItems"
                    v-model="statusSelect"
                    :label="translationsEditUser.main_info_block.select_status_label"
                    class="mb-2"
                    outlined
                    @change="changeStatusUser"
                ></v-select>
                <v-card-text v-else>
                  {{ translationsEditUser.main_info_block.select_status_label }}: <span>{{ statusSelect }}</span>
                </v-card-text>

                <v-text-field
                    v-if="this.$auth.isAdmin"
                    v-model="profileForm.balance"
                    :label="translationsEditUser.main_info_block.balance_label"
                    outlined

                ></v-text-field>
                <!--              <v-btn-->
                <!--                  outlined-->
                <!--                  color="success"-->
                <!--                  @click="saveBalance"-->
                <!--              >-->
                <!--                Save balance-->
                <!--              </v-btn>-->
              </v-col>
              <v-row v-if="this.$auth.isAdmin && userIsOperator"
                     class="pa-0 pl-4 pr-4 operator-container">
                <v-col class="pa-0 line" cols="4">
                </v-col>
                <v-col class="pa-0 pl-4 pr-4">
                 <SearchUsers :id="currentUserId"/>
                </v-col>
              </v-row>
              <v-row v-if="this.$auth.isAdmin"
                     :style="{
                       'margin-bottom': `16px`,
                       'width': `100%`,
                       'margin-top': userIsOperator ? '0' : '-64px'
                     }"
                     class="pa-0 pl-4 pr-4">
                <v-col class="pa-0" cols="4">
                </v-col>

                <v-col class="pa-0 pl-4 pr-4" style="display: flex; justify-content: flex-end;">
                  <v-btn
                      style="margin-left: auto"
                      v-if="this.$auth.isAdmin"
                      outlined
                      color="success"
                      @click="saveAvatarMembershipBalance"
                  >
                    {{ translations.GLOBAL_VALUE.save }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="this.$auth.isAdmin" class="pa-0 pl-4 pr-4" style="margin-bottom: 16px">
              <v-col
                  class="pa-0" cols="4">
              </v-col>
              <v-col class="pa-0 pl-4 pr-4">
                <div class="d-flex align-center">
                  <v-text-field class="mr-4"
                                hide-details
                                v-model="password"
                                :label="translationsEditUser.main_info_block.password_label"
                                outlined
                                type="password"
                                id="passwordInput"
                                :append-icon="showPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                append-outer-icon="mdi-dice-5-outline"
                                prepend-inner-icon="mdi-content-copy"
                                @click:append-outer="randomPassword"
                                @click:append="showPassword"
                                @click:prepend-inner="copyPassword"
                  ></v-text-field>
                  <v-btn outlined color="orange" @click="changePassword">{{ translationsEditUser.main_info_block.btn_change_password }}</v-btn>
                </div>
              </v-col>
              </v-row>

            </v-row>
          </v-card>
          <v-divider class="my-6"/>
          <v-card outlined color="#f4f6f9">
            <v-row class="mb-3">
              <v-avatar size="30" color="primary" class="title-count mr-2">2</v-avatar>
              <h2 class="text-overline">{{ translationsEditUser.user_info_block.title }}</h2>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="profileForm.name"
                    outlined
                    :label="translationsEditUser.user_info_block.name_label"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="profileForm.surname"
                    outlined
                    :label="translationsEditUser.user_info_block.surname_label"
                ></v-text-field>
              </v-col>


            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.patronymic"
                    outlined
                    :label="translationsEditUser.user_info_block.patronymic_label"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.email"
                    outlined
                    :label="translationsEditUser.user_info_block.email_label"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.phone"
                    outlined
                    :label="translationsEditUser.user_info_block.phone_label"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                    outlined
                    color="success"
                    class="mr-4"
                    @click="saveUser"
                >
                  {{ translations.GLOBAL_VALUE.save }}
                </v-btn>
              </v-col>
            </v-row>

          </v-card>
          <v-divider class="my-6"/>
          <v-card outlined color="#f4f6f9">
            <v-row class="mb-3">
              <v-avatar size="30" color="primary" class="title-count mr-2">3</v-avatar>
              <h2 class="text-overline">{{ translationsEditUser.profile_info_block.title }}</h2>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.profile.name"
                    :label="translationsEditUser.profile_info_block.name_label"
                    outlined
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.profile.surname"
                    :label="translationsEditUser.profile_info_block.surname_label"
                    outlined
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="profileForm.profile.birthday"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        placeholder="YYYY-MM-DD"
                        outlined
                        v-model="profileForm.profile.birthday"
                        :label="translationsEditUser.profile_info_block.birth_label"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="profileForm.profile.birthday"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      {{ translations.GLOBAL_VALUE.cancel }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(profileForm.profile.birthday)"
                    >
                      {{ translations.GLOBAL_VALUE.save }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-autocomplete
                    v-model="profileForm.profile.countryCode"
                    :items="$getCountries()"
                    item-text="name"
                    item-value="code"
                    :label="translationsEditUser.profile_info_block.country_code_label"
                    persistent-hint
                    single-line
                    clearable
                    outlined
                ></v-autocomplete>
                <!--            <v-text-field-->
                <!--                v-model="profileForm.profile.countryCode"-->
                <!--                label="Country code"-->
                <!--            ></v-text-field>-->
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.profile.city"
                    :label="translationsEditUser.profile_info_block.city_label"
                    outlined
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-autocomplete
                    v-model="profileForm.profile.state"
                    :items="$getCountryStates(profileForm.profile.countryCode)"
                    item-text="name"
                    item-value="name"
                    :label="translationsEditUser.profile_info_block.state_label"
                    persistent-hint
                    single-line
                    clearable
                    outlined

                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.profile.marital"
                    :label="translationsEditUser.profile_info_block.marital_label"
                    outlined
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model.number="profileForm.profile.children"
                    :label="translationsEditUser.profile_info_block.children_label"
                    outlined
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.profile.occupation"
                    :label="translationsEditUser.profile_info_block.occupation_label"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
<!--              <v-col-->
<!--                  cols="12"-->
<!--                  md="4"-->
<!--              >-->
<!--                <v-text-field-->
<!--                    v-model.number="profileForm.profile.age"-->
<!--                    :label="translationsEditUser.profile_info_block.age_label"-->
<!--                    outlined-->
<!--                ></v-text-field>-->
<!--              </v-col>-->

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.profile.education"
                    :label="translationsEditUser.profile_info_block.education_label"
                    outlined
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.profile.phone"
                    :label="translationsEditUser.profile_info_block.phone_label"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.profile.eyeColor"
                    :label="translationsEditUser.profile_info_block.eye_color_label"
                    outlined
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.profile.religion"
                    :label="translationsEditUser.profile_info_block.religion_label"
                    outlined
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model.number="profileForm.profile.height"
                    :label="translationsEditUser.profile_info_block.height_label"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model.number="profileForm.profile.weight"
                    :label="translationsEditUser.profile_info_block.weight_label"
                    outlined
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="profileForm.profile.hairColor"
                    :label="translationsEditUser.profile_info_block.hair_color_label"
                    outlined
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-select
                    item-text="text"
                    item-value="value"
                    v-model="profileForm.profile.smoking"
                    :label="translationsEditUser.profile_info_block.smoking_label"
                    outlined
                    :items="[{text: 'Yes', value: true}, {text: 'No', value: false}]"
                >

                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-select
                    v-model="profileForm.profile.isBoy"
                    :items="isBoy"
                    item-text="text"
                    item-value="value"
                    :label="translationsEditUser.profile_info_block.gender_label"
                    persistent-hint
                    single-line
                    outlined
                ></v-select>

              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-slider
                    v-model="profileForm.profile.seekingAgeMin"
                    :label="translationsEditUser.profile_info_block.seeking_age_min_label"
                    max="99"
                    min="18"
                    thumb-label="always"
                ></v-slider>

              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-slider
                    class="edit-user-slider"
                    v-model="profileForm.profile.seekingAgeMax"
                    :label="translationsEditUser.profile_info_block.seeking_age_max_label"
                    max="99"
                    min="18"
                    thumb-label="always"
                ></v-slider>

              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-textarea
                    v-model="profileForm.profile.bio"
                    :label="translationsEditUser.profile_info_block.bio_label"
                    outlined
                ></v-textarea>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-textarea
                    v-model="profileForm.profile.ideal"
                    :label="translationsEditUser.profile_info_block.ideal_label"
                    outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                    color="success"
                    outlined
                    class="mr-4"
                    @click="saveProfile"
                >
                  {{ translations.GLOBAL_VALUE.save }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-divider class="my-6"/>
          <v-card outlined color="#f4f6f9">
            <v-row class="mb-3">
              <v-avatar size="30" color="primary" class="title-count mr-2">4</v-avatar>
              <h2 class="text-overline">{{ translationsEditUser.photo_info_block.title }}</h2>
            </v-row>

            <v-row v-if="videos.length > 0" class="photo-content video">
              <h4 class="text--darken-3">Videos</h4>
              <v-progress-circular
                  v-if="progressVideos"
                  indeterminate
                  color="#a93ad5"
                  class="ml-3"
              ></v-progress-circular>
              <v-col
                  cols="12"
                  md="12"
              >
                <div class="photo-list">
                  <div class="container-photo" v-for="video in videos" :key="video.id">
                    <video class="mt-2 mr-2 mb-2" controls :src="video.converted.s3Url"/>
                    <v-icon class="icon-delete" color="red" @click="deleteVideoConfirm(video.id)">mdi-close-box</v-icon>

                  </div>
                </div>

              </v-col>

            </v-row>
            <v-row v-if="passportAccept.length > 0" class="photo-content passport">
              <h4 class="text--darken-3">{{ translationsEditUser.photo_info_block.passport_title }}</h4>
              <v-progress-circular
                  v-if="progressPassport"
                  indeterminate
                  color="#a93ad5"
                  class="ml-3"
              ></v-progress-circular>
              <v-col
                  cols="12"
                  md="12"
              >
                <div class="photo-list">
                  <div class="container-photo" v-for="photo in passportAccept" :key="photo.id">
                    <img :src="$getImage(photo.file)" alt=""/>
                    <v-icon class="icon-delete" color="red" @click="deletePassportPassport(photo.id)">mdi-close-box
                    </v-icon>

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="icon-change"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="selectPhoto(photo, photo.documentType)"
                        >
                          mdi-dots-horizontal-circle
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                            @click="swapSelectedPhoto(item.value)"
                            v-for="(item, index) in swapItems"
                            :key="index"
                            v-if="item.value !== photo.documentType"
                        >
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                  </div>
                </div>

              </v-col>
            </v-row>

            <v-row v-if="passportPending.length > 0" class="photo-content ">
              <h4 class="text--darken-3">{{ translationsEditUser.photo_info_block.passport_moderation_title }}</h4>
              <v-progress-circular
                  v-if="progressPassportPending"
                  indeterminate
                  color="#FF9800"
                  class="ml-3"
              ></v-progress-circular>
              <v-col
                  cols="12"
                  md="12"
              >
                <div class="photo-list">
                  <div class="container-photo" v-for="photo in passportPending" :key="photo.id">
                    <img :src="$getImage(photo.file)" alt=""/>
                    <v-icon class="icon-delete" color="red" @click="deletePassportPassport(photo.id)">mdi-close-box
                    </v-icon>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="icon-change"
                            color="#FF9800"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="selectPhoto(photo, photo.documentType)"
                        >
                          mdi-dots-horizontal-circle
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                            @click="swapPendingSelectedPhoto(item.value)"
                            v-for="(item, index) in swapItems"
                            :key="index"
                            v-if="item.value !== photo.documentType"
                        >
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>

              </v-col>
              <v-divider class="ma-5"></v-divider>
            </v-row>

            <v-row v-if="albumAccept.length > 0" class="photo-content accept">
              <h4 class="text--darken-3">{{ translationsEditUser.photo_info_block.album_title }}</h4>
              <v-progress-circular
                  v-if="progressAlbum"
                  indeterminate
                  color="#03A9F4"
                  class="ml-3"
              ></v-progress-circular>
              <v-col
                  cols="12"
                  md="12"
              >
                <div class="photo-list">
                  <div class="container-photo" v-for="photo in albumAccept" :key="photo.id">
                    <img :src="$getImage(photo.url)" alt=""/>
                    <v-icon class="icon-delete" color="red" @click="deleteAlbumPhoto(photo.id)">mdi-close-box</v-icon>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="icon-change"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="selectPhoto(photo, 'ALBUM')"
                        >
                          mdi-dots-horizontal-circle
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                            @click="swapSelectedPhoto(item.value)"
                            v-for="(item, index) in swapItems"
                            :key="index"
                            v-if="item.value !== 'ALBUM'"
                        >
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-checkbox v-if="albumAccept.length > 5" class="v-checkbox" hide-details v-model="photo.paid"  label="Locked"></v-checkbox>
                  </div>
                </div>

                <v-btn :disabled="btnDisabled" color="success" class="mt-3" v-if="hasChanges" @click="saveChangesLockedPhotos">Save</v-btn>

              </v-col>
            </v-row>

            <v-row v-if="albumPending.length > 0" class="photo-content">
              <h4 class="text--darken-3">Photo and video on moderation</h4>
              <v-progress-circular
                  v-if="progressAlbumPending"
                  indeterminate
                  color="#FF9800"
                  class="ml-3"
              ></v-progress-circular>
              <v-col
                  cols="12"
                  md="12"

              >
                <div class="photo-list">
                  <div class="container-photo"  v-for="photo in albumPending" :key="photo.image ? photo.image.id : photo.video.id">
                    <img v-if="photo.destination !== 3" :src="$getImage(photo.image.url)" alt=""/>
                    <video class="mr-2 mb-2 mt-2" controls v-if="photo.destination === 3 && !photo.image && photo.video.isReady" :src="photo.video.converted.s3Url"/>
                    <div v-if="photo.destination === 3 && !photo.image && !photo.video.isReady" class="video-pending mr-2 mb-2 mt-2" >
                      <v-icon
                          large
                          color="gray"
                      >
                        mdi-clock-outline
                      </v-icon>
                    </div>

                    <v-icon v-if="(photo.image && !photo.image.video) || (photo.video && photo.video.isReady)" class="icon-delete" color="red" @click="deletePhotoPending(photo.id)">mdi-close-box</v-icon>
                    <v-menu v-if="photo.destination !== 3"  offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="icon-change"
                            color="#FF9800"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="selectPhoto(photo, 'ALBUM')"
                        >
                          mdi-dots-horizontal-circle
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                            @click="swapPendingSelectedPhoto(item.value)"
                            v-for="(item, index) in swapItems"
                            :key="index"
                            v-if="item.value !== 'ALBUM'"
                        >
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>

              </v-col>
            </v-row>

            <v-row class="photo-content add">
              <h4 class="text--darken-3">{{ translationsEditUser.photo_info_block.add_album_title }}</h4>
              <v-col md="12" lg="12">
                <input type="file" hidden multiple ref="files" @change="listFiles">
                <v-select
                    v-model="photoList"
                    :items="photoList"
                    item-text="file.name"
                    chips
                    readonly
                    prepend-icon="mdi-paperclip"
                    multiple
                    @click.native="$refs.files.click()"
                    @click:prepend="$refs.files.click()"
                    @click:append="clearClick"
                    label="Photo input"
                    append-icon="mdi-close"
                >
                  <template v-slot:selection="{item}">
                    <v-chip
                        color="primary"
                        label
                        small
                        close
                        @click:close="deletePhoto(item.src)"
                    >
                      {{ item.file.name }}
                    </v-chip>

                  </template>
                </v-select>
              </v-col>
              <v-col lg="12" v-if="photoList.length !== 0">
                <div>
                  <!--                  <p @click="clearClick" class="delete-all">Delete All</p>-->
                  <v-btn outlined @click="clearClick" dark class="delete-all" color="red"> {{ translations.GLOBAL_VALUE.remove_all }}</v-btn>
                  <div class="photo-list">
                    <div class="container-photo" v-for="photo in photoList" :key="photo.src">
                      <img :src="photo.src" alt=""/>
                      <v-icon class="icon-delete" color="red" @click="deletePhoto(photo.src)">mdi-close-box</v-icon>

                    </div>

                  </div>
                </div>
              </v-col>


            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                    color="success"
                    outlined
                    class="mr-4"
                    @click="addPhoto"
                    v-if="!progressPhoto"
                >
                  {{ translations.GLOBAL_VALUE.save }}
                </v-btn>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                ></v-progress-circular>
              </v-col>
            </v-row>


            <v-row class="photo-content add">
              <h4 class="text--darken-3">{{ translationsEditUser.photo_info_block.add_passport_title }}</h4>


              <v-col md="12" lg="12">

                <v-row>

                  <v-col cols="4">
                    <p class="ml-8">{{ translationsEditUser.photo_info_block.passport_types.front }}</p>
                    <v-file-input
                        class="passport-input pass-front"
                        :class="{'active': passportPhotoOne.length > 0}"
                        accept="image/*"
                        outlined
                        v-model="passport1"
                        @change="passportOneChange"
                        :clearable="false"
                        ref="passportOneInput"
                    >
                      <template v-slot:selection>
                        <div class="passport-photo">
                          <div class="container-photo" v-for="photo in passportPhotoOne" :key="photo.src">
                            <img :src="photo.src" alt=""/>
                            <v-icon class="icon-delete" color="red" @click.stop="clearClickPassportOne()">mdi-close-box
                            </v-icon>
                          </div>

                        </div>

                      </template>

                    </v-file-input>

                  </v-col>
                  <v-col cols="4">
                    <p class="ml-8">{{ translationsEditUser.photo_info_block.passport_types.back }}</p>
                    <v-file-input
                        :class="{'active': passportPhotoTwo.length > 0}"
                        class="passport-input pass-back"
                        outlined
                        accept="image/*"
                        v-model="passport2"
                        @change="passportTwoChange"
                        :clearable="false"
                    >

                      <template v-slot:selection>
                        <div class="passport-photo">
                          <div class="container-photo" v-for="photo in passportPhotoTwo" :key="photo.src">
                            <img :src="photo.src" alt=""/>
                            <v-icon class="icon-delete" color="red" @click.stop="clearClickPassportTwo()">mdi-close-box
                            </v-icon>
                          </div>

                        </div>

                      </template>

                    </v-file-input>
                  </v-col>
                  <v-col cols="4">
                    <p class="ml-8">{{ translationsEditUser.photo_info_block.passport_types.selfie }}</p>
                    <v-file-input
                        :class="{'active': passportPhotoThree.length > 0}"
                        class="passport-input pass-self"
                        outlined
                        accept="image/*"
                        v-model="passport3"
                        @change="passportThreeChange"
                        :clearable="false"
                    >

                      <template v-slot:selection>
                        <div class="passport-photo">
                          <div class="container-photo" v-for="photo in passportPhotoThree" :key="photo.src">
                            <img :src="photo.src" alt=""/>
                            <v-icon class="icon-delete" color="red" @click.stop="clearClickPassportThree()">mdi-close-box
                            </v-icon>
                          </div>

                        </div>

                      </template>

                    </v-file-input>
                  </v-col>

                </v-row>
              </v-col>


            </v-row>

            <v-row>
              <v-col class="text-right">
                <v-btn
                    color="success"
                    outlined
                    class="mr-4"
                    @click="addPassport"
                    v-if="!progress"
                >
                  {{ translations.GLOBAL_VALUE.save }}
                </v-btn>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-row class="photo-content add">
              <h4 class="text--darken-3">Add video</h4>


              <v-col md="12" lg="12">
                <input type="file" accept="video/mp4,video/mkv, video/x-m4v,video/*" hidden multiple ref="filesVideo" @change="listFilesVideo">
                <v-select
                    v-model="videoList"
                    :items="videoList"
                    item-text="file.name"
                    chips
                    readonly
                    prepend-icon="mdi-paperclip"
                    multiple
                    @click.native="$refs.filesVideo.click()"
                    @click:prepend="$refs.filesVideo.click()"
                    @click:append="clearVideoClick"
                    label="Video input"
                    append-icon="mdi-close"
                >
                  <template v-slot:selection="{item}">
                    <v-chip
                        color="primary"
                        label
                        small
                        close
                        @click:close="deleteVideo(item.src)"
                    >
                      {{ item.file.name }}
                    </v-chip>

                  </template>
                </v-select>
              </v-col>
              <v-col lg="12" v-if="videoList.length !== 0">
                <div>
                  <v-btn outlined @click="clearVideoClick" dark class="delete-all" color="red"> {{ translations.GLOBAL_VALUE.remove_all }}</v-btn>
                  <div class="photo-list">
                    <div class="container-video" v-for="video in videoList" :key="video.src">
                      <video class="mr-2 mb-2" controls :src="video.src" alt=""/>
                      <v-icon class="icon-delete" color="red" @click="deleteVideo(video.src)">mdi-close-box</v-icon>

                      <v-text-field v-model="video.title"/>
                    </div>

                  </div>
                </div>
              </v-col>


            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                    color="success"
                    outlined
                    class="mr-4"
                    @click="addVideo"
                    v-if="!progressVideo"
                >
                  {{ translations.GLOBAL_VALUE.save }}
                </v-btn>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                ></v-progress-circular>
              </v-col>
            </v-row>

          </v-card>
        </v-col>

      </v-row>


    </div>
  </div>
</template>

<script>

import AppImageAvatar from '@/components/AppImageAvatar';
import _ from 'lodash';
import {
  addUserRole, approveChangeImageRequest, changeDocumentStatus,
  getRoles, getUserId,
  removeUserRole
} from '@/api/usersRequest';
import {
  deleteMembership,
  postSaveAvatar, postSaveBalance,
  postSaveMembership,
  postSaveProfile,
  postSaveUser, postSaveUserProfile,
} from '@/api/editUsers';
import {getMembers} from "@/api/membershipRequest";
import {api} from "@/providers/api";
import {format, parseISO} from "date-fns";
import {log10} from "chart.js/helpers";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import axios from "axios";
import {tr} from "date-fns/locale";
import SearchUsers from "@/views/EditUser/SearchUsers.vue";

export default {
  props: ['qwe'],
  name: 'EditUser',
  components: {SearchUsers, MyBreadcrumbs, AppImageAvatar},
  data() {
    return {
      isDeleteMembership: false,
      oldBalance: null,
      alertText: '',
      alertStatus: false,
      alertShow: false,
      btnDisabled: false,
      statusItems: ['REVIEW', 'ACTIVE', 'BLOCKED', 'IRRELEVANT'],
      roles: [],
      valuesRole: [],
      isUserLoading: false,
      newAvatar: undefined,
      memberships: [],
      profileForm: {
        name: null,
        surname: null,
        birthday: null,
        balance: null,
        status: null,
        // birthdayForm: {
        //   day: 1,
        //   month: 1,
        //   year: 1,
        // },
        countryCode: 'us',
        city: null,
        state: null,
        marital: null,
        children: 0,
        occupation: null,
        education: null,
        phone: null,
        eyeColor: null,
        hairColor: null,
        religion: null,
        height: null,
        weight: null,
        smoking: false,
        seekingAgeMin: 18,
        seekingAgeMax: 99,
        bio: null,
        ideal: null,
        age: null,
        rating: null,
        membership: {
          membershipTariff: {
            // name: 123,
            // id: 123
          }
        }
      },
      profileFormOptions: {
        MARITAL_STATUSES: [],
        EDUCATION_STATUSES: [],
        RELIGION_STATUSES: [],
      },
      isBoy: [{
        text: 'male',
        value: true
      },
        {
          text: 'female',
          value: false
        }],
      // formCountries: getCountries(),
      eyeColors: ['brown', 'blue', 'green', 'grey', 'hazel', 'grey-blue'],
      hairColors: ['black', 'brown', 'light-brown', 'blond', 'red', 'grey'],
      placeholders: {
        about: `Put at least a brief description now and come back to it later. Anything you want to share with the ladies. Are you into bikes, cars; what sports do you like; do you have pets; what movies and music do you like etc. The more details you share, the higher are your chances to find your perfect match and true love.`,
        ideal: `Put at least a brief description now and come back to it later. \nWhat qualities do you appreciate in a woman? \nWhat is important for you in a relationship?`
      },
      menu: false,
      testUser: [],
      videos: [],
      albumPending: [],
      albumAccept: [],
      albumAcceptDefault: [],
      photoList: [],
      videoList: [],
      operatorIds: [],
      operatorIdsSearch: [],
      search: '',
      files: [],
      accessToken: '',
      progress: false,
      progressVideo: false,
      progressPhoto: false,
      progressPassport: false,
      progressVideos: false,
      progressPassportPending: false,
      progressAlbum: false,
      progressAlbumPending: false,
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: '',
      oldDateMembership: '',
      menuDate: false,
      newMembership: false,
      passportPhotoOne: [],
      passportPhotoTwo: [],
      passportPhotoThree: [],
      passport1: [],
      passport2: [],
      passport3: [],
      membershipTariffApprove: false,
      passportAccept: [],
      passportPending: [],
      swapPhoto: [],
      swapItems: [
        {title: 'Move to Album', value: 'ALBUM'},
        {title: 'Move to passport front', value: 'PASSPORT1'},
        {title: 'Move to passport back', value: 'PASSPORT2'},
        {title: 'Move to selfie passport', value: 'PASSPORT_SELFIE'},
      ],
      deleteTypePhoto: '',
      selectedPhoto: {},
      showPass: true,
      copyPass: false,
      password: ''
    };
  },
  computed: {
    userIsOperator() {
      return this.profileForm.roles.find(e => e.name === 'operator')
    },
    translations() {
      return this.$t('translation')
    },
    translationsEditUser() {
      return this.translations.EDIT_USER
    },
    currentUserId() {
      return Number(this.$route.params.id);
    },
    breadcrumbs() {
      return this.$auth.isAdmin ? [
        { text: this.translations.NAVBAR.DASHBOARD.title, href: '/' },
        { text: this.translations.NAVBAR.MANAGE_USERS.items.users_page, href: '/users' },
        { text: this.currentUserId, href: `/users/${this.currentUserId}`},
        { text: this.translationsEditUser.title, disabled: true,}
      ] : [
        { text: this.translations.NAVBAR.MANAGE_AGENCY.items.agency_page, href: `/agencies`},
        { text: `${this.translations.USERS.USER_LOG.title} ${this.currentUserId}`, href: `/users/${this.currentUserId}`},
        { text: this.translationsEditUser.title, disabled: true,}
      ];
    },
    statusSelect() {
      if (this.profileForm.status === 0) return 'REVIEW'
      else if (this.profileForm.status === 1) return 'ACTIVE'
      else if (this.profileForm.status === 3) return 'IRRELEVANT'
      else return 'BLOCKED'
    },
    currentMembershipTariff: {
      get() {
        return this.profileForm?.membership?.membershipTariff
      },
      set(value) {
        this.profileForm.membership.membershipTariff = value
      }
    },
    hasChanges() {
      for (let i = 0; i < this.albumAccept.length; i++) {
        if (this.albumAccept[i].paid !== this.albumAcceptDefault[i].paid) {
          return true;
        }
      }
      return false;
    },
    openedPhotos() {
      return this.albumAccept.filter(photo => !photo.paid).length
    }
  },
  async created() {
    this.isUserLoading = true
    this.randomPassword()
    this.roles = await getRoles();
    this.memberships = await getMembers();

    this.profileForm = await getUserId(this.currentUserId);
    if(this.profileForm.profile.birthday !== null) {
      this.profileForm.profile.birthday = format(new Date(this.profileForm.profile.birthday), 'yyyy-MM-dd')
    }

    this.oldBalance = this.profileForm.balance

    if (this.profileForm.membership?.endDate) {
      this.fetchDatepicker()
      this.oldDateMembership = [format(new Date(this.profileForm.membership.endDate), 'yyyy-MM-dd')]
    }

    await this.initToken()



    await this.fetchVideos()
    await this.fetchPassportPhoto()
    await this.fetchPendingPhoto()
    await this.fetchAcceptedPhoto()

    this.isUserLoading = false


    if (!this.profileForm.membership) {
      this.profileForm.membership = {
        membershipTariff: {}
      };
    }
  },
  methods: {
    async fetchVideos() {
      this.videos = await api.get(`admin/account/${this.currentUserId}/videos`)
    },

    async saveChangesLockedPhotos() {
      if (this.openedPhotos !== 5) {
        this.alertShow = 3
        this.alertStatus = false
        this.alertText = 'You can save public 5 open photo in your profile'
        setTimeout(() => {
          this.alertShow = false
        }, 3000)
      }
      else {
        this.btnDisabled = true
        let photo
        for (let i = 0; i < this.albumAccept.length; i++) {
          if (!this.objectsAreEqual(this.albumAccept[i], this.albumAcceptDefault[i])) {
            photo = this.albumAccept[i]
            if (photo.paid) {
              await api.post(`/admin/account/${photo.id}/paid/${photo.paid}`)
            }
            else {
              await api.post(`/admin/account/${photo.id}/paid/${photo.paid}`)

            }
          }
        }
        await this.fetchAcceptedPhoto()
        this.btnDisabled = false
        this.alertShow = 3
        this.alertStatus = true
        this.alertText = 'Success'
        setTimeout(() => {
          this.alertShow = false
        }, 3000)
      }
    },
    objectsAreEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    async changeStatusPhoto(photo) {
      if (this.openedPhotos >= 6) {
        setTimeout(() => {
          photo.paid = !photo.paid
        })

      }
      else {
        await api.post(`/admin/account/${photo.id}/paid/${photo.paid}`)
      }
    },
    async changePassword() {
      try {
        await api.post(`/admin/users/user/${this.currentUserId}/password`, {
          password: this.password
        })
        this.alertStatus = true
        this.alertShow = true
        this.alertText = 'Change password - success'
        setTimeout(() => {
          this.alertShow = false
        }, 3000)
      }
      catch (e) {
        console.log(e)
      }
    },
    copyPassword() {

      if (this.password !== null) {
        this.copyPass = true
        let input = document.getElementById('passwordInput')
        navigator.clipboard.writeText(input.value);
        setTimeout(() => {
          this.copyPass = false
        }, 2000)
      }

    },
    showPassword() {
      this.showPass = !this.showPass
      let input = document.getElementById('passwordInput')
      this.showPass ? input.type = 'text' : input.type = 'password'
    },
    randomPassword() {
      let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let charLength = chars.length;
      let result = '';
      for (let i = 0; i < 8; i++) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
      }
      this.password = result
    },
    async downloadAvatar() {
      const  { originalS3Url } = await api.get(`/admin/users/user/${this.currentUserId}/original-avatar`)
      if (originalS3Url !== null) {

        axios({
          url: originalS3Url,
          method: 'GET',
          responseType: 'blob'
        }).then((response) => {
          const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileUrl
          const fileName = `image_${Date.now()}.jpg`;

          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)
          fileLink.click()
        })

      }
      else {
        this.alertStatus = false
        this.alertShow = true
        this.alertText = 'No original file'
        setTimeout(() => {
          this.alertShow = false
        }, 3000)
      }
    },
    async swapSelectedPhoto(item) {
      switch (item) {
        case 'ALBUM':

          const formData = new FormData();
          formData.append("avatar", this.swapPhoto);
          this.progressPassport = true

          try {
            const photo = await api.post(`/admin/account/${this.currentUserId}/album-photo`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            await this.fetchPendingPhoto()
            const findPhoto = this.albumPending.find(e => e.image.id === photo.image.id)
            await approveChangeImageRequest(findPhoto.id, true);
            await this.fetchAcceptedPhoto();
            await this.photoTypeDeleteMethod();
          } finally {
            this.progressPassport = false
          }

          break;
        case 'PASSPORT1':

          const formDataPass1 = new FormData();
          formDataPass1.append('file', this.swapPhoto);
          this.progressAlbum = true

          try {
            const passport1 = await api.post('/admin/users/passport', formDataPass1, {
              params: {
                userId: this.currentUserId,
                type: 'PASSPORT1'
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }

            });
            await this.fetchPassportPhoto();
            await changeDocumentStatus(this.passportPending.find(e => e.id === passport1.id).id, 'ACCEPTED')
            await this.fetchPassportPhoto();
            await this.photoTypeDeleteMethod();
            await this.fetchAcceptedPhoto()
          } finally {
            this.progressAlbum = false
          }

          break;
        case 'PASSPORT2':

          const formDataPass2 = new FormData();
          formDataPass2.append('file', this.swapPhoto);
          this.progressAlbum = true

          try {
            const passport2 = await api.post('/admin/users/passport', formDataPass2, {
              params: {
                userId: this.currentUserId,
                type: 'PASSPORT2'
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }

            });
            await this.fetchPassportPhoto();
            await changeDocumentStatus(this.passportPending.find(e => e.id === passport2.id).id, 'ACCEPTED')
            await this.fetchPassportPhoto();
            await this.photoTypeDeleteMethod();
            await this.fetchAcceptedPhoto()
          } finally {
            this.progressAlbum = false
          }

          break;
        case 'PASSPORT_SELFIE':

          const formDataPass3 = new FormData();
          formDataPass3.append('file', this.swapPhoto);
          this.progressAlbum = true

          try {
            const passport3 = await api.post('/admin/users/passport', formDataPass3, {
              params: {
                userId: this.currentUserId,
                type: 'PASSPORT_SELFIE'
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }

            });
            await this.fetchPassportPhoto();
            await changeDocumentStatus(this.passportPending.find(e => e.id === passport3.id).id, 'ACCEPTED')
            await this.fetchPassportPhoto();
            await this.photoTypeDeleteMethod();
            await this.fetchAcceptedPhoto()
          } finally {
            this.progressAlbum = false
          }

          break;
      }

    },
    async swapPendingSelectedPhoto(item) {
      switch (item) {
        case 'ALBUM':
          const formData = new FormData();
          formData.append("avatar", this.swapPhoto);
          this.progressPassportPending = true

          try {
            await api.post(`/admin/account/${this.currentUserId}/album-photo`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            await this.photoTypeDeleteMethod();
            await this.fetchPendingPhoto()
          }
          finally {
            this.progressPassportPending = false
          }

          break;
        case 'PASSPORT1':
          const formDataPass1 = new FormData();
          formDataPass1.append('file', this.swapPhoto);
          this.progressAlbumPending = true

          try {
            await api.post('/admin/users/passport', formDataPass1, {
              params: {
                userId: this.currentUserId,
                type: 'PASSPORT1'
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }

            });
            await this.deletePhotoPending(this.selectedPhoto.id)
            await this.fetchPassportPhoto()
            await this.fetchPendingPhoto()
          } finally {
            this.progressAlbumPending = false
          }

          break;
        case 'PASSPORT2':

          const formDataPass2 = new FormData();
          formDataPass2.append('file', this.swapPhoto);
          this.progressAlbumPending = true

          try {
            await api.post('/admin/users/passport', formDataPass2, {
              params: {
                userId: this.currentUserId,
                type: 'PASSPORT2'
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }

            });
            await this.deletePhotoPending(this.selectedPhoto.id)
            await this.fetchPassportPhoto()
            await this.fetchPendingPhoto()
          } finally {
            this.progressAlbumPending = false
          }

          break;
        case 'PASSPORT_SELFIE':

          const formDataPass3 = new FormData();
          formDataPass3.append('file', this.swapPhoto);
          this.progressAlbumPending = true

          try {
            await api.post('/admin/users/passport', formDataPass3, {
              params: {
                userId: this.currentUserId,
                type: 'PASSPORT_SELFIE'
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }

            });
            await this.deletePhotoPending(this.selectedPhoto.id)
            await this.fetchPassportPhoto()
            await this.fetchPendingPhoto()
          } finally {
            this.progressAlbumPending = false
          }

          break;
      }

    },

    async deleteAlbumPhoto(id) {
      await api.delete(`/admin/account/${this.currentUserId}/album-photo/${id}`)
      await this.fetchAcceptedPhoto()
    },

    async photoTypeDeleteMethod() {
      if (this.deleteTypePhoto === 'ALBUM') {
        console.log("YES")
        return await api.delete(`/admin/account/${this.currentUserId}/album-photo/${this.selectedPhoto.id}`)

      }
      if (this.deleteTypePhoto === 'PASSPORT1') {
        return await this.deletePassportPassport(this.selectedPhoto.id)
      }
      if (this.deleteTypePhoto === 'PASSPORT2') {
        return await this.deletePassportPassport(this.selectedPhoto.id)
      }
      if (this.deleteTypePhoto === 'PASSPORT_SELFIE') {
        return await this.deletePassportPassport(this.selectedPhoto.id)
      }
    },

    async selectPhoto(photo, type) {
      console.log('photo', photo)
      this.selectedPhoto = photo
      this.deleteTypePhoto = type
      console.log('deleteTypePhoto', this.deleteTypePhoto)
      const imgUrl = photo.file ? photo.file : photo.url ? photo.url : photo.image.url
      const response = await fetch(imgUrl, {
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin"
      });
      const blob = await response.blob();
      this.swapPhoto = blob
    },

    async deletePassportPassport(id) {
      await api.delete(`/admin/users/passport/${id}`)
      await this.fetchPassportPhoto()
    },
    async fetchPassportPhoto() {
      const request = await api.get(`/admin/users/passport/${this.currentUserId}`)
      this.passportPending = request.filter(r => r.documentStatus === 'PENDING')
      this.passportAccept = request.filter(r => r.documentStatus === 'ACCEPTED')
    },

    membershipTariffCheck() {
      this.membershipTariffApprove = true
    },
    fetchDatepicker(date = this.profileForm.membership.endDate) {
      this.date = format(new Date(date), 'yyyy-MM-dd')
    },
    passportTwoChange() {
      this.passportPhotoTwo.push({
        src: URL.createObjectURL(this.passport2),
        file: this.passport2
      })
    },
    passportOneChange() {
      this.passportPhotoOne.push({
        src: URL.createObjectURL(this.passport1),
        file: this.passport1
      })
    },
    passportThreeChange() {
      this.passportPhotoThree.push({
        src: URL.createObjectURL(this.passport3),
        file: this.passport3
      })
    },

    clearClickPassportOne() {
      this.passportPhotoOne = []
      this.passport1 = []
    },
    clearClickPassportTwo() {
      this.passportPhotoTwo = []
      this.passport2 = []
    },
    clearClickPassportThree() {
      this.passport3 = null;
      this.passportPhotoThree.forEach(photo => URL.revokeObjectURL(photo.src));
      this.passportPhotoThree = [];
    },
    saveDateMembership(date) {
      this.newMembership = true
      // console.log(this.profileForm.membership.endDate)
      this.profileForm.membership.endDate = new Date(date).toJSON()
      // console.log(new Date(date).toJSON())
      // console.log(this.profileForm.membership.endDate)
      this.fetchDatepicker(new Date(date).toJSON())
      this.menuDate = false
    },
    membershipTariffStartEnd(profile) {
      console.log(profile?.membership?.membershipTariff)
      if (profile?.membership?.membershipTariff?.id) {
        return `Validity period: ${profile?.membership?.membershipTariff.name}  ${format(parseISO(profile.membership.startDate), 'PP')}
        –
        ${format(parseISO(profile.membership.endDate), 'PP')}`
      } else {
        return ''
      }
    },

    async initToken() {
      this.accessToken = await api.get(`/admin/users/${this.currentUserId}/login`).then(r => r.access_token)
    },

    async fetchPendingPhoto() {
      this.albumPending = await api.get(`/admin/account/${this.currentUserId}/pending-photos`)
    },
    async fetchAcceptedPhoto() {
      this.albumAccept = await api.get(`/admin/account/${this.currentUserId}/album`)
      this.albumAcceptDefault = await api.get(`/admin/account/${this.currentUserId}/album`)
    },

    async deletePhotoPending(id) {
      console.log(id)
      await api.delete(`/admin/account/${this.currentUserId}/pending-photo/${id}`)
      await this.fetchPendingPhoto()
    },


    async addVideo() {
      try {
        this.progressVideo = true

        if (this.videoList.length > 0) {
          for (let i = 0; i < this.videoList.length; i++) {
            let formData = new FormData();
            formData.append('video', this.videoList[i].file);
            formData.append('userName', this.videoList[i].title);

            await api.post('/account/video', formData, {
              headers: {
                'Authorization': `Bearer ${this.accessToken}`,
                'Content-Type': 'multipart/form-data'
              }
            });
          }
        }


        this.alertStatus = true
        this.alertShow = true
        this.alertText = 'Add Video - sent for moderation'

      } catch (e) {
        this.alertStatus = false
        this.alertShow = true
        this.alertText = e.data.message
      } finally {
        this.progressVideo = false

        this.clearVideoClick()
        this.fetchPendingPhoto()

        setTimeout(() => {
          this.alertShow = false
        }, 2000)
      }
    },

    async addPassport() {
      try {
        this.progress = true

        if (this.passportPhotoOne.length > 0) {
          const formDataPass1 = new FormData();
          formDataPass1.append('file', this.passportPhotoOne[0].file, this.passportPhotoOne[0].file.name);
          await api.post('/admin/users/passport', formDataPass1, {
            params: {
              userId: this.currentUserId,
              type: 'PASSPORT1'
            },
            headers: {
              'Content-Type': 'multipart/form-data'
            }

          });
        }

        if (this.passportPhotoTwo.length > 0) {
          const formDataPass2 = new FormData();
          formDataPass2.append('file', this.passportPhotoTwo[0].file, this.passportPhotoTwo[0].file.name);
          await api.post('/admin/users/passport', formDataPass2, {
            params: {
              userId: this.currentUserId,
              type: 'PASSPORT2'
            },
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        }

        if (this.passportPhotoThree.length > 0) {
          const formDataPass3 = new FormData();
          formDataPass3.append('file', this.passportPhotoThree[0].file, this.passportPhotoThree[0].file.name);
          await api.post('/admin/users/passport', formDataPass3, {
            params: {
              userId: this.currentUserId,
              type: 'PASSPORT_SELFIE'
            },
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        }


        this.alertStatus = true
        this.alertShow = true
        this.alertText = 'Add Photo - sent for moderation'

      } catch (e) {
        this.alertStatus = false
        this.alertShow = true
        this.alertText = e.data.message
      } finally {
        this.progress = false
        await this.fetchPassportPhoto()
        this.clearClickPassportOne()
        this.clearClickPassportTwo()
        this.clearClickPassportThree()
        setTimeout(() => {
          this.alertShow = false
        }, 2000)
      }
    },
    async addPhoto() {
      try {
        this.progressPhoto = true
        for (let i = 0; i < this.photoList.length; i++) {
          const formData = new FormData();
          formData.append('avatar', this.photoList[i].file, this.photoList[i].file.name);
          const photo = await api.post(`/admin/account/${this.currentUserId}/album-photo`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          await this.fetchPendingPhoto()
          if (this.albumAccept.length >= 5 && !this.profileForm.profile.isBoy) {
            await api.post(`/admin/account/${photo.image.id}/paid/${true}`)
          }
        }

        this.alertStatus = true
        this.alertShow = true
        this.alertText = 'Add Photo - sent for moderation'

      } catch (e) {
        this.alertStatus = false
        this.alertShow = true
        this.alertText = e.data.message
      } finally {
        this.progressPhoto = false
        this.clearClick()
        await this.fetchPendingPhoto()
        setTimeout(() => {
          this.alertShow = false
        }, 2000)
      }
    },

    deletePhoto(src) {
      console.log(src)
      let index = this.photoList.findIndex(el => el.src === src)
      this.photoList.splice(index, 1);
    },

    deleteVideo(src) {
      let index = this.videoList.findIndex(el => el.src === src)
      this.videoList.splice(index, 1);
    },


    async deleteVideoConfirm(id) {
      await api.delete(`/account/video/${id}`, {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`,
        }
      })
      await this.fetchVideos()
    },


    listFiles() {
      for (let i = 0; i < this.$refs.files.files.length; i++)
        this.photoList.push({src: URL.createObjectURL(this.$refs.files.files[i]), file: this.$refs.files.files[i]})
      console.log('photoList', this.photoList)
    },

    listFilesVideo() {
      for (let i = 0; i < this.$refs.filesVideo.files.length; i++)
        this.videoList.push({src: URL.createObjectURL(this.$refs.filesVideo.files[i]), file: this.$refs.filesVideo.files[i], title: this.$refs.filesVideo.files[i].name})
      console.log('videoList', this.videoList)
    },

    clearClick() {
      this.photoList = []
      this.files = []
    },

    clearVideoClick() {
      this.videoList = []
    },

    onInput(value) {
      console.log('value', value)
      console.log('value.replace(/\\D/g, \'\')', value.replace(/\D/g, ''))
      this.operatorIds = value.replace(/\D/g, '');
    },

    async changeStatusUser(item) {
      let status;

      switch (item) {
        case 'REVIEW':
          status = 0;
          break;
        case 'ACTIVE':
          status = 1;
          break;
        case 'IRRELEVANT':
          status = 3;
          break;
        default:
          status = 2;
          break;
      }

      await api.post(`/admin/users/${this.currentUserId}/status?status=${status}`);

      this.alertShow = true
      this.alertStatus = true
      this.alertText = 'User status - success change'
      setTimeout(() => {
        this.alertShow = false
      }, 2000)
    },

    async updateUserRoles(newRolesList) {
      const oldRolesList = this.profileForm.roles;
      const diffAdded = _.differenceBy(newRolesList, oldRolesList, 'id');
      const diffRemoved = _.differenceBy(oldRolesList, newRolesList, 'id');
      try {
        for (const {id} of diffAdded) {
          await addUserRole(this.profileForm.id, id);
        }

        for (const {id} of diffRemoved) {
          await removeUserRole(this.profileForm.id, id);
        }

        this.profileForm.roles = newRolesList;
        this.alertShow = true
        this.alertStatus = true
        this.alertText = 'User role - success change'
        setTimeout(() => {
          this.alertShow = false
        }, 2000)
      } catch (e) {
        alert('error add roles: ' + e);
      }

    },

    async saveUser() {
      try {
        const user = {
          name: this.profileForm.name,
          surname: this.profileForm.surname,
          email: this.profileForm.email,
          phone: this.profileForm.phone,
          patronymic: '',
        }

        await postSaveUser(this.profileForm.id, user);
        this.alertStatus = true
        this.alertShow = true
        this.alertText = 'User info - success save'
      } catch (e) {
        this.alertStatus = false
        this.alertShow = true
        this.alertText = e.data.message
      } finally {
        setTimeout(() => {
          this.alertShow = false
        }, 2000)
      }

    },
    async saveProfile() {
      try {
        await postSaveUserProfile(this.profileForm.id, this.profileForm.profile);
        this.alertStatus = true
        this.alertShow = true
        this.alertText = 'Profile info - success save'
      } catch (e) {
        this.alertStatus = false
        this.alertShow = true
        this.alertText = e.data.message
      } finally {
        setTimeout(() => {
          this.alertShow = false
        }, 2000)
      }
    },


    clearMembership() {
      this.profileForm.membership.membershipTariff = {}
      this.isDeleteMembership = true
    },

    async saveAvatarMembershipBalance() {
      try {

        if (this.oldBalance !== this.profileForm.balance) {
          await postSaveBalance(this.profileForm.id, this.profileForm.balance === '' ? 0 : this.profileForm.balance)
          this.oldBalance = this.profileForm.balance
        }


        if (this.profileForm.membership.membershipTariff.id && this.membershipTariffApprove) {
          await postSaveMembership(this.profileForm.id, this.profileForm.membership.membershipTariff.id)
          this.profileForm = await getUserId(this.currentUserId);
          this.membershipTariffStartEnd()
          this.membershipTariffApprove = false
          this.fetchDatepicker()
          this.oldDateMembership = [format(new Date(this.profileForm.membership.endDate), 'yyyy-MM-dd')]
        }

        if (this.newMembership) {
          await api.post(`/admin/users/${this.profileForm.id}/membership/edit`, {
            userMembershipId: this.profileForm.membership.id,
            endDate: new Date(this.date).toJSON()
          })
          this.profileForm = await getUserId(this.currentUserId);
          this.fetchDatepicker()
          this.oldDateMembership = [format(new Date(this.profileForm.membership.endDate), 'yyyy-MM-dd')]
          this.newMembership = false

        }

        if (this.isDeleteMembership) {
          await deleteMembership(this.profileForm.id)
          this.isDeleteMembership = false
        }


        if (this.newAvatar) {
          const formData = new FormData();
          formData.append('avatar', this.newAvatar.blob, this.newAvatar.file.name);
          await postSaveAvatar(this.profileForm.id, formData)
        }

        // this.saveMembership()
        // this.saveAvatar()
        this.alertStatus = true
        this.alertShow = true
        this.alertText = 'Main info - success save'
      } catch (e) {
        this.alertStatus = false
        this.alertShow = true
        this.alertText = e.data.message
      } finally {
        setTimeout(() => {
          this.alertShow = false
        }, 3000)
      }

    }
  },
  mounted() {
    this.showPassword()
  },

};
</script>

<style scoped lang="scss">

.operator-container {
  margin-top: -96px;
  margin-bottom: 16px;
  width: 100%;
  .line {
    @media(max-width: 769px) {
      display: none;
    }
  }

  @media(max-width: 769px) {
    margin-top: -16px;
  }
}

.operator-ids{
  overflow-y: auto;
  max-height: 100px;
}
.container-video{
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;

  .icon-delete{
    top: 0;
    margin: 5px -30px;
    position: absolute;
    z-index: 100;
  }
}

.photo-list, .passport-photo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .container-photo {
    display: flex;
    align-items: flex-start;

  }
  video{
    width: 250px;
    height: 200px;
  }
  img {
    width: 175px;
    height: 200px;
    object-fit: cover;
    margin: 10px;
  }
}

.passport-photo{
  @media (max-width: 1440px) {
    img{
      margin: 0px;
      height: 170px;
      width: 130px;
    }

    .icon-delete{
      margin-top: 0px;
      margin-left: -23px;
    }
  }
}

::v-deep {

  .passport-input {

    &.active{
      .v-file-input__text {
        color: #2f55b4;
        justify-content: center;
        &:after {
          display: none;

        }
      }
    }


    .v-input__slot {
      height: 230px;
      position: relative;


      .v-file-input__text {
        &:after {
          width: 100%;
          background-position: center;
          height: 100%;
          content: "";
          left: 0;
          right: 0;
          background-size: 130px 80px;
          margin: 0 auto;
          background-image: url('@/assets/images/icons/passport-front.svg');

        }
      }
    }

    &.pass-back{
      .v-file-input__text {
        &:after {
          background-image: url('@/assets/images/icons/passport-back.svg');
        }
      }
    }
    &.pass-self{
      .v-file-input__text {
        &:after {
          background-image: url('@/assets/images/icons/passport-self.svg');
        }
      }
    }
  }

}

.new-membership {
  color: #2f55b4;
}

.alert {
  position: fixed;
  z-index: 100;
  right: 15px;
  width: max-content;
}

.upload-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-count {
  color: #f4f6f9;
}

.save-member {
  margin-top: 50px;
}

.apply-btn {
  font-family: "Comfortaa", sans-serif;
  cursor: pointer;
  padding: 10px 44px;
  background-color: #ffffff;
  border: 1px solid #151e2f;
  border-radius: 44px;
  font-size: 14px;
  line-height: 21px;
  color: #151e2f;
  font-weight: 400;
}

.edit-user-avatar {
  margin: 20px;
}

.container {
  height: unset !important;
}

.img-pending {
  width: 175px;
  height: 200px;
  object-fit: cover;
  margin: 10px 10px 10px 0px;
}

.photo-content {
  margin: 10px;
  //border-left: 3px solid #FF9800;
  padding: 10px;

  h4 {
    color: #FF9800;
    border: 2px solid #FF9800;
    border-radius: 20px;
    padding: 5px 16px;

  }
  &.video{
    h4 {
      color: #ef329c;
      border: 2px solid #ef329c;
      border-radius: 20px;
      padding: 5px 16px;
    }
  }
  &.passport {
    h4 {
      color: #a93ad5;
      border: 2px solid #a93ad5;
      border-radius: 20px;
      padding: 5px 16px;
    }
  }

  &.add {
    //border-left: 3px solid #4caf50;

    h4 {
      color: #4caf50;
      border: 2px solid #4caf50;
      border-radius: 20px;
      padding: 5px 16px;
    }
  }

  &.accept {
    //border-left: 3px solid #03A9F4;

    h4 {
      color: #03A9F4;
      border: 2px solid #03A9F4;
      border-radius: 20px;
      padding: 5px 16px;
    }
  }
}

.photo-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .container-photo {
    display: flex;
    align-items: flex-start;
    margin-right: 5px;
    margin-bottom: 15px;
    position: relative;
  }

  img {
    width: 175px;
    height: 200px;
    object-fit: cover;
    margin: 10px 10px 10px 0px;
  }
}

.icon-delete {
  cursor: pointer;
  margin-top: 7px;
  margin-left: -30px;
  background: #f4f6f9;
}

.icon-change {
  margin-top: 192px;
  margin-left: -24px;
  background: #f4f6f9;
  border-radius: 40px;
}
.v-checkbox{
  position: absolute;
  bottom: -20px;
  margin-top: 10px;
}

.video-pending{
  background: #d1d1d1;
  width: 250px;
  height: 170px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

</style>
