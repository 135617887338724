var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 50, 100]
    },"items-per-page":_vm.itemsPerPage,"headers":_vm.headers,"items":_vm.users,"item-key":"name","page":_vm.page,"server-items-length":_vm.totalItemsMessages,"options":_vm.messagesOptions,"loading":_vm.messagesLoading},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.messagesOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.translationsChatLog.table.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":_vm.toggleSearchFields}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),(_vm.showSearchFields)?_c('TableSearchFields',{attrs:{"searchFields":_vm.searchFields},on:{"add-field":_vm.addSearchField,"remove-field":_vm.removeSearchField,"update-fields":_vm.updateSearchCriteria,"clear-fields":_vm.clearFields}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.translations.GLOBAL_VALUE.delete_modal.title))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.delete_modal.cancel_btn)+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.delete_modal.ok_btn)+" ")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.from",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"user-img cursor-pointer",on:{"click":function($event){return _vm.userPush(item.from)}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.avatar)}}),_vm._v(" "+_vm._s(item.from.name)+" ")])]}},{key:"item.to",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"user-img cursor-pointer",on:{"click":function($event){return _vm.userPush(item.all)}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.avatar2)}}),_vm._v(" "+_vm._s(item.to)+" ")])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.chatId",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.chat))]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":_vm.$auth.isOperator,"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"input-page"},[_c('v-row',{staticClass:"mx-2",attrs:{"align":"center","justify":"start","align-content":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"single-line":"","hide-details":"","label":"Go to page","type":"number","min":"1","max":_vm.pageCount},on:{"change":_vm.updatePage},model:{value:(_vm.pageInput),callback:function ($$v) {_vm.pageInput=$$v},expression:"pageInput"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('span',[_vm._v(" Pages: "+_vm._s(_vm.page)+" / "+_vm._s(_vm.pageCount)+" ")])])],1)],1)]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }