<template>
  <div>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headers"
          :items="users"
          :server-items-length="totalItems"
          :options.sync="options"
          :loading="loading"
          item-key="name"
          class="elevation-1"

      >
        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">{{ translations.title }}</v-toolbar-title>
          </v-toolbar>

        </template>
        <template v-slot:[`item.from`]="{ item }">
          <div class="user-img">
            <img :src="$getImage(item.avatar)">
            <div class="online" v-if="getterUserOnline(item)"></div>
            {{ item.from }}
          </div>
        </template>

        <template v-slot:[`item.to`]="{ item }">
          <div class="user-img">
            <img :src="$getImage(item.avatar2)">
            <div class="online" v-if="getterUserOnline(item)"></div>
            {{ item.to }}
          </div>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>


      </v-data-table>
    </v-container>
  </div>
</template>

<script>
// import {getUserMessage} from "@/api/getRequest";

import {api} from "@/providers/api";

export default {
  name: "UserMessageLog",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'CHAT-ID',
        align: 'start',
        value: 'chat',
      },
      {
        text: 'FROM',
        value: 'from',
      },
      {
        text: 'TO',
        value: 'to',
      },
      {
        text: 'MESSAGE',
        value: 'message',
      },
      {
        text: 'CREATED',
        value: 'createdAt',
      },

    ],
    users: [],
    options: {},
    totalItems: 0,
  }),
  watch: {
    options: {
      handler() {
        this.syncDataFromApi()
      },
      deep: true
    }
  },
  computed: {
    translations() {
      return this.$t('translation.USERS.USER_LOG.tabs_items.message_table')
    }
  },
  async created() {
    this.headers = [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'CHAT-ID',
        align: 'start',
        value: 'chat',
      },
      {
        text: 'FROM',
        value: 'from',
      },
      {
        text: 'TO',
        value: 'to',
      },
      {
        text: 'MESSAGE',
        value: 'message',
      },
      {
        text: 'CREATED',
        value: 'createdAt',
      },

    ]
  },
  methods: {
    async syncDataFromApi() {
      console.log('this.$route.params',this.$route.params)
      this.loading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options
        const response = await api.get(`/admin/messages/`, {
          params: {
            chat_id: this.$route.params.id,
            ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
            limit: limit === -1 ? 100 : limit,
            page,

          },

        })

        const {items, meta} = response
        this.users = items.map(e => {
          const to = e.sender.id !== e.chat.members[0].id ?
              e.chat.members[0].profile :
              e.chat.members[1].profile;
          return {
            id: e.id,
            chat: e.chat.id,
            from: e.sender.profile.name,
            to: to.name,
            message: e.text,
            avatar: e.sender.profile.avatar,
            avatar2: to.avatar,
            createdAt: e.createdAt
          }
        });
        this.totalItems = meta.totalItems
      } catch (e) {
        console.log('Error', e)
      } finally {
        this.loading = false
      }
    },
    fetchData() {
      this.loading = true

      setTimeout(() => {
        const request = this.data.items
        this.users = request.map(e => {
          const to = e.sender.id !== e.chat.members[0].id ?
              e.chat.members[0].profile :
              e.chat.members[1].profile;
          return {
            id: e.id,
            chat: e.chat.id,
            from: e.sender.profile.name,
            to: to.name,
            message: e.text,
            avatar: e.sender.profile.avatar,
            avatar2: to.avatar,
            createdAt: e.createdAt
          }
        })
        this.loading = false
      }, 300)

    }
  }
}
</script>

<style scoped lang="scss">
.online {
  background: #4fa526;
  width: 13px;
  height: 13px;
  position: absolute;
  border-radius: 100px;
  margin-left: 20px;
  border: 2px solid #fff;
  margin-top: 20px;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.title {
  color: #f4f6f9;
}
</style>