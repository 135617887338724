var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.items,"item-key":"id","show-expand":"","expanded":_vm.expanded,"single-expand":_vm.singleExpand,"loading":_vm.giftLoading},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.translationsGiftShop.title))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"white","outlined":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.translationsGiftShop.btn_new_gift)+" ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"modal-add"},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-file-input',{attrs:{"show-size":"","label":_vm.translationsGiftShop.modal_file_label},model:{value:(_vm.formFile),callback:function ($$v) {_vm.formFile=$$v},expression:"formFile"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":_vm.translationsGiftShop.modal_price_label},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.addGift}},[_vm._v(_vm._s(_vm.translationsGiftShop.modal_btn_add)+" ")])],1)],1)],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"user-img"},[_c('img',{attrs:{"src":_vm.$getImage(item.file)}})]),_c('v-col',[_c('v-text-field',{model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}}),_c('v-btn',{attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.editGift(item)}}},[_vm._v(_vm._s(_vm.translations.GLOBAL_VALUE.save))]),_c('v-btn',{staticClass:"red",attrs:{"dark":""},on:{"click":function($event){return _vm.deleteGift(item.id)}}},[_vm._v(_vm._s(_vm.translations.GLOBAL_VALUE.remove))])],1)],1)])]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-img"},[_c('img',{attrs:{"src":_vm.$getImage(item.file)}})])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }