var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-list"},[_c('v-container',{staticClass:"my-5"},[_c('MyBreadcrumbs',{staticClass:"mb-3",attrs:{"s":"","breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-title',{staticClass:"table-header"},[_vm._v(" "+_vm._s(_vm.translationsEmailManagerUsersList.title)+" "),_c('v-spacer'),_c('v-spacer')],1),_c('v-data-table',{attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.users,"options":_vm.usersTableOptions,"server-items-length":_vm.totalResponseUsers,"loading":_vm.usersLoading},on:{"click:row":_vm.userOpen,"update:options":function($event){_vm.usersTableOptions=$event}},scopedSlots:_vm._u([{key:"item.profile.isBoy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile.isBoy ? 'man' : 'woman')+" ")]}},{key:"item.isAgency",fn:function(ref){
var item = ref.item;
return [(item.isAgency)?_c('v-chip',{staticClass:"status",attrs:{"color":_vm.getColorAgency(item.isAgency),"dark":""}},[_vm._v(" "+_vm._s('yes')+" ")]):_vm._e()]}},{key:"item.isDeleted",fn:function(ref){
var item = ref.item;
return [(item.isDeleted)?_c('v-chip',{staticClass:"status",attrs:{"color":"red","dark":""}},[_vm._v(" "+_vm._s('yes')+" ")]):_vm._e()]}},{key:"item.profile.countryCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getCountyByCode(item.profile.countryCode).name)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.profile.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-img"},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item),"alt":"img"}}),_c('span',{staticClass:"user-title",on:{"click":function($event){$event.stopPropagation();return _vm.openUserNewPage(item.id)}}},[_vm._v(_vm._s(item.profile.name))]),(_vm.getterUserOnline(item.id))?_c('div',{staticClass:"online"}):_vm._e(),(item.isAgency)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"}},[_vm._v("mdi-face-agent")]):_vm._e(),(item.id !== _vm.me.id && _vm.$auth.isAdmin)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }