<template>
  <div>
    <PreloaderPage v-if="activityLoading" class="preloader-line"/>
    <v-container :class="{'disabled' : activityLoading}">
      <MyBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-tabs v-model="currentTab" class="mb-3" background-color="#f4f6f9" color="primary">
        <v-tab to="staff">
          Staff
        </v-tab>
        <v-tab to="transactions">
          {{ translationsAgencyLog.breadcrumbs.transactions }}
        </v-tab>
        <v-tab to="chats">
          Chats
        </v-tab>
        <v-tab to="activities">
          {{ translationsAgencyLog.breadcrumbs.activities }}

        </v-tab>
        <v-tab v-if="!this.$auth.isAdmin" to="withdrawal">
          {{ translationsAgencyLog.breadcrumbs.withdrawal }}
        </v-tab>
        <v-tab to="stats">
          {{ translationsAgencyLog.breadcrumbs.stats }}
        </v-tab>
        <v-tab to="settings">
          {{ translationsAgencyLog.breadcrumbs.settings }}
        </v-tab>
        <v-tab to="pay-out">
          {{ translationsAgencyLog.breadcrumbs.pay_out }}
        </v-tab>
      </v-tabs>
    </v-container>

    <v-container class="my-5">
<!--      {{ groupedList }}-->
<!--      {{ groupMessagesTimeList }}-->
      <v-row>
        <v-col lg="6" md="6">
          <v-data-table
              :footer-props="{'items-per-page-options': [10, 50, 100]}"
              :items-per-page="10"
              :headers="headersMessage"
              :items="itemsChats"
              :server-items-length="totalItemsChats"
              :options.sync="optionsChats"
              :loading="loadingChats"
              class="elevation-1"
              :class="{ 'disabled-table': disabledTable }"
              :item-class="itemRowSelected"
              @click:row="selectChat"

          >
            <template v-slot:[`item.chat.members`]="{ item }">

              <v-list-item
                  class="pa-0"
                  dense
              >
                <v-icon
                    v-if="($auth.isAdmin || me.isAgency)"
                    class="mr-2"
                    color="primary"
                    @click.stop="$switchProfileUser(getUser(item).id)"
                >
                  mdi-account-arrow-right
                </v-icon>

                <v-list-item-avatar size="35" class="mr-3">
                  <v-list-item-avatar class="mr-0">
                    <v-img :src="$getAvatarImage(getUser(item))"></v-img>
                  </v-list-item-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ getUser(item).name }}</v-list-item-title>

                  <v-list-item-subtitle> {{ getUser(item).email }}</v-list-item-subtitle>
                </v-list-item-content>


              </v-list-item>

            </template>

            <template v-slot:[`item.chat.membersSecond`]="{ item }">

              <v-list-item
                  class="pa-0"
                  dense
              >
                <v-list-item-avatar size="35" class="mr-3">
                  <v-list-item-avatar class="mr-0">
                    <v-img :src="$getAvatarImage(getUser(item, false))"></v-img>
                  </v-list-item-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ getUser(item, false).name }}</v-list-item-title>

                  <v-list-item-subtitle v-if="$auth.isAdmin"> {{ getUser(item, false).email }}</v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>

            </template>

            <template v-slot:[`item.unread`]="{ item }">
              <v-chip dark small color="green" v-if="item.unread !== 0">
                {{ item.unread }}
              </v-chip>
            </template>
            <!--        <template v-slot:[`item.chat.createdAt`]="{ item }">{{ new Date(item.chat.createdAt) | date('Pp') }}</template>-->

          </v-data-table>
        </v-col>


        <v-col lg="6" md="6" v-if="selectedChat">
          <v-row>
            <v-col cols="12">
              <div class="card-header">
                <v-list-item
                    class="pa-0"
                    dense
                    dark
                >
                  <v-list-item-avatar size="50" class="mr-3">
                    <v-list-item-avatar class="mr-0">
                      <v-img :src="$getAvatarImage(getUser(selectedChat, false))"></v-img>
                    </v-list-item-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ getUser(selectedChat, false).name }}</v-list-item-title>
                  </v-list-item-content>

                </v-list-item>
              </div>
              <v-card tile :loading="messageLoading" class="chat-container " height="480">
<!--                <div ref="observer" class="observer"></div>-->
                <v-card-text>
                  <v-sheet v-if="!messageLoading">
                    <div v-for="({lastMessageTime, group }) in groupMessagesTimeList"
                         :key="lastMessageTime"
                    >
                      <div class="date">
                        <span>{{ dateFormat(lastMessageTime) }}</span>
                      </div>
                      <v-row v-for="({messages, sender}, index) in group" :key="index">
                        <v-col class="pa-1" :class="getMessageAlignmentClass(sender.id)">

                          <div v-for="message in messages"
                               :key="message.id">
                            <v-avatar v-if="getUser(selectedChat).id !== sender.id" size="30" class="mr-2">
                              <v-img :src="$getAvatarImage(getUser(selectedChat, false))"></v-img>
                            </v-avatar>
                            <div class="message-container"
                            >
                              <div v-if="message.emoji">
                                <img class="emoji-img-container" :src="message.emoji.file.s3Url" alt="">
                              </div>
                              <div class="photos-img-container" v-else-if="message.photos.length !== 0">
                                <img :src="message.photos[0].file.s3Url" alt="">
                              </div>
                              <span>
                                {{ message.text }}
                              </span>

                              <span class="time">
                              {{ format(new Date(message.createdAt), "HH:mm") }}
                            </span>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                  </v-sheet>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- Форма для ввода сообщения -->
          <v-row>

            <v-col cols="12" style="position: relative">

              <v-text-field outlined hide-details v-model="newMessage" @keydown.enter="sendMessage"
                            label="Input message">
                <template v-slot:append>
                  <emoji-picker
                      @emoji="append" :search="search"
                  >
                    <button

                        slot="emoji-invoker"
                        slot-scope="{ events: { click: clickEvent } }"
                        @click.stop="clickEvent"
                    >
                      <v-icon>mdi-emoticon</v-icon>

                    </button>
                    <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                      <div class="emoji-picker">
                        <div class="emoji-picker__search">
                          <input type="text" v-model="search" v-focus>
                        </div>
                        <div>
                          <div v-for="(emojiGroup, category) in emojis" :key="category">
                            <h5>{{ category }}</h5>
                            <div class="emojis">
                <span
                    v-for="(emoji, emojiName) in emojiGroup"
                    :key="emojiName"
                    @click="insert(emoji)"
                    :title="emojiName"
                >{{ emoji }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </emoji-picker>

                </template>
              </v-text-field>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>


</template>

<script>
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import {getAgencyById, getAgencyMessageId} from "@/api/agencyRequest";
import {mapGetters} from "vuex";
import {api} from "@/providers/api";
import {differenceInMinutes, format, parseISO,} from "date-fns";
import axios from "axios";
import PreloaderPage from "@/components/PreloaderPage.vue";

export default {
  name: "AgencyLogChats",
  components: {PreloaderPage, MyBreadcrumbs},
  data: () => ({
    currentTab: 2,
    totalItemsChats: 0,
    loadingChats: true,
    itemsChats: [],
    optionsChats: {},
    activityLoading: false,

    search: '',
    disabledTable: false,
    messageLoading: false,
    otherMessageLoading: false,
    agencyName: '',
    headersMessage: [],
    pagination: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 0,
      currentPage: 1
    },
    staticReformatDate: [
      {
        key: 'year',
        item: 'u'
      },
      {
        key: 'month',
        item: 'M'
      },
      {
        key: 'day',
        item: 'd'
      },
      {
        key: 'hour',
        item: 'h'
      },
      {
        key: 'minute',
        item: 'm'
      },
      {
        key: 'second',
        item: 's'
      }
    ],
    members: [],
    selectedRows: [],
    selectedChat: null,
    syncIntervalId: null,
    syncIntervalChatId: null,
    messages: [],
    newMessage: "",
    accessToken: "",
    cancelTokenSource: null,
  }),
  watch: {
    optionsChats: {
      handler() {
        this.syncChatsFromApi()
      },
      deep: true
    },

    messageLoading(newVal) {
      if (!newVal) {
        this.scrollToBottom();
      }
    },
    messages() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
  },

  beforeDestroy() {
    if (this.syncIntervalId) {
      clearInterval(this.syncIntervalId);
    }
    if (this.syncIntervalChatId) {
      clearInterval(this.syncIntervalChatId);
    }
  },
  async created() {

    // this.headersMessage =  [
    //   { text: 'ID', align: 'start', value: 'id', sortable: true },
    //   { text: this.translationsAgencyLogMessage.headers.sender, value: 'sender.name', sortable: false },
    //   { text: this.translationsAgencyLogMessage.headers.text, value: 'text', sortable: false },
    //   { text: this.translationsAgencyLogMessage.headers.created_at, value: 'createdAt', sortable: false },
    //   { text: this.translationsAgencyLogMessage.headers.chat_id, value: 'chat.id', sortable: false },
    // ]

    this.headersMessage = [
      {text: 'ChatId', align: 'start', value: 'chat.id', sortable: false},
      {text: 'Member 1', width: 150, value: 'chat.members', sortable: false},
      {text: 'Member 2', width: 150, value: 'chat.membersSecond', sortable: false},
      {text: 'Unread count ', value: 'unread', sortable: true},

    ]

    if (this.currentAgency?.name === undefined) {
      this.activityLoading = true;
      await this.$store.dispatch("Agencies/fetchAgencyById", {id: this.$route.params.id})
      this.activityLoading = false;
    }
    // await this.fetchTransactions()
  },
  computed: {
    ...mapGetters('User', ['me']),
    groupMessagesTimeList() {
      return this.groupedList
          .reduce((r, e) => {
                const last = r[r.length - 1];
                if (!last) {
                  r.push({
                    group: [e],
                    lastMessageTime: e.messages[e.messages.length - 1].createdAt
                  });
                } else {
                  const lastDate = [];
                  this.staticReformatDate.forEach(item => {
                    lastDate[item.key] = Number(format(parseISO(last.lastMessageTime), item.item))
                  })
                  const eDate = [];
                  this.staticReformatDate.forEach(item => {
                    eDate[item.key] = Number(format(parseISO(e.messages[e.messages.length - 1].createdAt), item.item))
                  })
                  let result = differenceInMinutes(
                      new Date(eDate.year, eDate.month, eDate.day, eDate.hour, eDate.minute, eDate.second),
                      new Date(lastDate.year, lastDate.month, lastDate.day, lastDate.hour, lastDate.minute, lastDate.second))
                  if (result <= 1440) {
                    last.group.push(e)
                  } else {
                    r.push({
                      group: [e],
                      lastMessageTime: e.messages[e.messages.length - 1].createdAt
                    })
                  }
                }
                return r;
              },
              [])
    },

    chatMessages() {
      return this.messages.map(item => ({
        ...item,
        sender: this.selectedChat?.chat?.members?.find(m => m.id === item?.sender?.id)
      }))
    },

    groupedList() {
      return this.chatMessages !== 0 ? this.chatMessages
          .reduce((r, e) => {
            const last = r[r.length - 1];
            const sender = {
              ...e.sender,
              name: e.sender?.profile ? e.sender.profile.name : e.sender.name
            }
            if (!last) {
              r.push({
                messages: [e],
                sender: sender,
              });
            } else {
              if (last?.sender?.id === e?.sender?.id) {
                last.messages.push(e);
              } else {
                r.push({
                  messages: [e],
                  sender: sender,
                });
              }
            }
            return r;
          }, [])
          .map(e => {
            return {
              ...e,
              self: e?.sender?.id !== this.$auth.user.id,
              startChatTime: [{
                time: e.messages.map(e => (e.createdAt)),
              }
              ]
            };
          }) : [];
    },

    ...mapGetters('Agencies', [
      'getterDataById', 'getterAgencyById'
    ]),
    translations() {
      return this.$t('translation')
    },
    translationsAgencyLog() {
      return this.translations.AGENCY.AGENCY_LOG
    },
    translationsAgencyLogMessage() {
      return this.translationsAgencyLog.table_message
    },
    currentAgency() {
      return this.getterAgencyById(+this.$route.params.id)
    },

    breadcrumbs() {
      return this.$auth.isAdmin ? [
        {text: this.translations.NAVBAR.DASHBOARD.title, href: '/'},
        {text: this.translations.AGENCY.AGENCIES.title, href: '/agencies'},
        {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id || '-'}`, href: 'staff'},
        {text: 'Chats', disabled: true,}
      ] : [
        {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id || '-'} `, href: 'staff'},
        {text: 'Chats', disabled: true,}
      ];
    },
  },

  methods: {

    append(emoji) {

      this.newMessage += emoji
    },

    itemRowSelected(item) {
      return item.chat.id === this.selectedChat?.chat.id ? 'selected-row' : ''
    },

    async sendMessage() {
      if (this.newMessage !== '') {
        const chatMessage = await api.post(`/chats/${this.selectedChat.chat.id}`, {
          text: this.newMessage
        }, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
          }
        })
        this.messages.push(chatMessage)

      }
      this.newMessage = '';
    },

    async initToken(id) {
      this.accessToken = await api.get(`/admin/users/${id}/login`).then(r => r.access_token)
    },

    dateFormat(data) {
      return format(parseISO(data), 'dd LLLL uuuu');
    },

    format,
    getMessageAlignmentClass(senderId) {
      const user = this.getUser(this.selectedChat)
      return user.id === senderId ? "message-right" : "message-left"
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = document.querySelector(".chat-container");
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },

    async syncChatMessageFromApi() {
      this.messageLoading = true
      let currentPage = 1;
      const limit = 100;
      let meta;
      do {
        const response = await api.get(`/admin/agencies-chats/${this.selectedChat.chat.id}?page=${currentPage}&limit=${limit}`);

        const messages = response.items;
        meta = response.meta;
        this.messages.push(...messages)
        this.messages.sort((a, b) => a.id - b.id);
        this.members = response.members
        currentPage++;

      } while (currentPage <= meta.totalPages);

      this.messageLoading = false
    },

    async selectChat(item) {
      // this.checkIntersectionObserver();
      this.pagination.currentPage = 1;
      this.disabledTable = true
      this.selectedChat = item
      this.messages = []
      if (this.syncIntervalId) {
        clearInterval(this.syncIntervalId);
      }
      await this.initToken(this.getUser(this.selectedChat).id)
      await api.post(`/chats/${item.chat.id}/reset-unread-counter`, {}, {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`,
        }
      })
      await this.updateChatMessage();
      this.disabledTable = false
    },

    updateChatMessage() {
      this.syncChatMessageFromApi();

      this.syncIntervalId = setInterval(() => {
        this.messages = []
        this.syncChatMessageFromApi();
      }, 10000);
    },


    getUser(item, getWoman = true) {
      if (getWoman) {
        return item.chat.members.find(e => !e.profile.isBoy)
      } else {
        return item.chat.members.find(e => e.profile.isBoy)
      }

    },




    async syncChatsFromApi() {
      this.loadingChats = true
      try {
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel('New request is being made');
        }

        this.cancelTokenSource = axios.CancelToken.source();
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsChats
        const response = await api.get(`/admin/agencies/${this.$route.params.id}/chats`, {
          params: {
            ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
            limit: limit === -1 ? 100 : limit,
            page,
          },
          cancelToken: this.cancelTokenSource.token,

        })
        if (!response) {
          return;
        }

        this.itemsChats = response.items;
        this.totalItemsChats = response.meta.totalItems
        this.loadingChats = false
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Previous request canceled', error.message);
        } else {
          console.error('Error', error);
        }
      }
    },
    // async fetchTransactions() {
    //   this.messageLoading = true
    //   try {
    //     const response1 = await api.get(`/admin/agencies/${this.$route.params.id}/chats`)
    //     // const response2 = await api.get(`/admin/agencies/chat/130`)
    //     console.log('res1', response1)
    //     // console.log('res2', response2)
    //     this.messages = await getAgencyMessageId(this.$route.params.id).then(r => r.data);
    //     await this.$store.dispatch("Agencies/addData", { key: 'messages', id: this.$route.params.id, value: this.messages  })
    //   }
    //   finally {
    //     this.messageLoading = false
    //   }
    //
    // },
    checkIntersectionObserver() {
      if(this.$refs.observer) {
        let options = {
          rootMargin: "0px",
          threshold: 1.0,
        };
        const callback = ((entries, observer) => {
          if(entries[0].isIntersecting) {
            if(this.pagination.currentPage !== this.pagination.totalPages) {
              this.pagination.currentPage++;
              this.syncChatMessageFromApi();
            }
          }
        })
        let observer = new IntersectionObserver(callback, options);
        observer.observe(this.$refs.observer)
      }
    }
  },
  mounted() {
    this.syncIntervalChatId = setInterval(this.syncChatsFromApi, 10000);
    // this.checkIntersectionObserver();
  }
}
</script>

<style scoped lang="scss">
.message-left {
  text-align: left;
}

.message-right {
  text-align: right;
}

.message-container {
  margin: 5px 0;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px 40px 10px 10px;
  display: inline-block;
  position: relative;

  .emoji-img-container {
    width: 56px;
    height: 56px;
  }
  .photos-img-container {
    width: 56px;
    height: 72px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.message-right .message-container {
  background-color: #d2e9ff;
}

.chat-container {
  overflow-y: auto;
  max-height: 480px;
}

.card-header {
  background-color: #2f55b4;
  padding-left: 10px;
  color: white;
}

.time {
  position: absolute;
  font-size: 11px;
  right: 5px;
  bottom: 1px;
}

.date {
  margin: 15px;
  text-align: center;

  span {
    background: #eaf3f8;
    padding: 10px 15px;
    border-radius: 30px;
    border: 1px solid #d2e9ff;
    color: #151e2f;
    font-weight: 500;
    font-size: 12px;
  }
}
.disabled-table {
  pointer-events: none;
  opacity: 0.6;
}

::v-deep{
  .selected-row{
    background: #eaf3f8 !important;
  }
}
.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}

.emoji-invoker:hover {
  transform: scale(1.1);
}

.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  right: 30px !important;
  top: -240px !important;
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 20rem;
  height: 200px;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}

.emoji-picker__search {
  display: flex;
}

.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}

.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
  margin-top: 10px;
}

.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}

.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}

.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.observer {
  position: absolute;
  top: 0;
  height: 56px;
  width: 100%;
  background: #2f55b4;
}


.preloader-line {
  position: absolute;
  top: 0;
}

.disabled {
  opacity: .6;
  pointer-events: none;
}
</style>