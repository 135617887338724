<template>
  <div class="users-log">
    <v-container class="my-5">
      <MyBreadcrumbs v-if="this.$auth.isAdmin" :breadcrumbs="breadcrumbs"/>
      <v-row>
        <v-col cols="12" md="12" lg="12">
          <v-card>
            <v-card-title class="table-header">
              {{ translationsUsersLog.table_title }}
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="translations.GLOBAL_VALUE.search"
                  single-line
                  hide-details
                  dark
                  class="mr-4"
                  :disabled="searchIDDisabled"

              ></v-text-field>
              <v-text-field
                  v-model="searchID"
                  append-icon="mdi-magnify"
                  label="ID Search"
                  single-line
                  hide-details
                  dark
                  type="number"
                  class="search-id"

              ></v-text-field>
              <v-spacer/>
              <v-dialog
                  v-model="dialogFilter"
                  width="650"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      color="white"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      :disabled="searchIDDisabled"
                  >
                    {{ translationsUsersLog.table_btn }}
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 primary white--text">
                    {{ translationsUsersLog.filter.title }}
                  </v-card-title>
                  <v-card-text>
                  </v-card-text>
                  <v-row class="ma-0">
                    <v-col cols="4">
                      <v-select
                          outlined
                          hide-details
                          v-model="selectedIsAgency"
                          :items="optionsIsAgency"
                          :label="translationsUsersLog.filter.is_agency_label"
                          @change="updateFilter('isAgency', selectedIsAgency)"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                          outlined
                          hide-details
                          v-model="selectedIsBoy"
                          :items="optionsIsBoy"
                          :label="translationsUsersLog.filter.gender_label"
                          @change="updateFilter('isBoy', selectedIsBoy)"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                          outlined
                          hide-details
                          v-model="selectedTariff"
                          :items="optionsTariff"
                          :label="translationsUsersLog.filter.tariff_label"
                          @change="updateFilter('membershipTariffName', selectedTariff)"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col cols="4">
                      <v-select
                          outlined
                          hide-details
                          v-model="selectedStatus"
                          :items="optionsStatus"
                          :label="translationsUsersLog.filter.status_label"
                          @change="updateFilter('status', selectedStatus)"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                          outlined
                          hide-details
                          v-model="selectedCountry"
                          :items="$getCountries()"
                          item-text="name"
                          item-value="code"
                          :label="translationsUsersLog.filter.country_label"
                          @change="updateFilter('countryCode', selectedCountry)"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                          hide-details
                          outlined
                          :label="translationsUsersLog.filter.agency_label"
                          :items="allAgencies"
                          item-text="name"
                          item-value="id"
                          v-model="selectedAgency"
                          @change="updateFilter('userAgencyId', selectedAgency)"

                      />
                    </v-col>
                    <v-checkbox
                        v-model="isDeleted"
                        class="pl-3"
                        color="red"
                        :label="translationsUsersLog.filter.is_deleted_label"
                    >
                    </v-checkbox>
                    <v-checkbox
                        v-model="isUtm"
                        class="pl-3"
                        color="blue"
                        label="UTM"
                    >
                    </v-checkbox>
                    <v-checkbox
                        v-if="$auth.isOperator"
                        v-model="operatingProfiles"
                        class="pl-3"
                        color="purple"
                        label="Operating Profiles"
                    >
                    </v-checkbox>
                  </v-row>
                  <v-divider class="ma-2"/>
                  <v-card-title class="text-h6  pa-0 pl-3">
                    {{ translationsUsersLog.filter.balance_range_label }}
                  </v-card-title>
                  <!--                  <v-row class="ma-0">-->
                  <!--                        <v-col cols="5">-->
                  <!--                          <v-text-field hide-details @change="rangeFilter('balance.amount', balanceValue)" v-model="balanceValue[0]" outlined label="Balance first value" type="number"/>-->
                  <!--                        </v-col>-->
                  <!--                        <v-col cols="2" class="d-flex justify-center">-->
                  <!--                          <v-icon color="primary" large>mdi-minus</v-icon>-->
                  <!--                        </v-col>-->
                  <!--                        <v-col cols="5">-->
                  <!--                          <v-text-field hide-details @change="rangeFilter('balance.amount', balanceValue)" v-model="balanceValue[1]" outlined label="Balance second value" type="number"/>-->

                  <!--                        </v-col>-->
                  <!--                  </v-row>-->
                  <v-row class="ma-0">
                    <v-col>
                      <v-range-slider
                          v-model="balanceValue"
                          max="10000"
                          min="0"
                          hide-details
                          class="align-center"
                      >
                        <template v-slot:prepend>
                          <v-text-field
                              :value="balanceValue[0]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              type="number"
                              style="width: 60px"
                              @change="$set(balanceValue, 0, $event)"
                          ></v-text-field>
                        </template>
                        <template v-slot:append>
                          <v-text-field
                              :value="balanceValue[1]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              type="number"
                              style="width: 60px"
                              @change="$set(balanceValue, 1, $event)"
                          ></v-text-field>
                        </template>
                      </v-range-slider>

                    </v-col>
                  </v-row>
                  <v-divider class="ma-2"/>
                  <v-card-title class="text-h6 pa-0 pl-3">
                    {{ translationsUsersLog.filter.age_range_label }}
                  </v-card-title>
                  <v-row class="ma-0">
                    <v-col>
                      <v-range-slider
                          v-model="ageValue"
                          max="99"
                          min="18"
                          hide-details
                          class="align-center"
                      >
                        <template v-slot:prepend>
                          <v-text-field
                              :value="ageValue[0]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              type="number"
                              style="width: 60px"
                              @change="$set(ageValue, 0, $event)"
                          ></v-text-field>
                        </template>
                        <template v-slot:append>
                          <v-text-field
                              :value="ageValue[1]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              type="number"
                              style="width: 60px"
                              @change="$set(ageValue, 1, $event)"
                          ></v-text-field>
                        </template>
                      </v-range-slider>

                    </v-col>
                    <!--                    <v-col cols="5">-->
                    <!--                      <v-text-field hide-details @change="rangeFilter('profile.age', ageValue)" v-model="ageValue[0]" outlined label="Age first value" type="number"/>-->
                    <!--                    </v-col>-->
                    <!--                    <v-col cols="2" class="d-flex justify-center">-->
                    <!--                      <v-icon color="primary" large>mdi-minus</v-icon>-->
                    <!--                    </v-col>-->
                    <!--                    <v-col  cols="5">-->
                    <!--                      <v-text-field hide-details @change="rangeFilter('profile.age', ageValue)" v-model="ageValue[1]" outlined label="Age second value" type="number"/>-->

                    <!--                    </v-col>-->
                  </v-row>
                  <v-divider class="ma-2"/>
                  <v-card-title class="text-h6 pa-0 pl-3">
                    {{ translationsUsersLog.filter.date_range_label }}
                  </v-card-title>
                  <v-row class="ma-0">
                    <v-col cols="5">
                      <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              hide-details
                              outlined
                              readonly
                              v-model="date[0]"
                              :label="translationsUsersLog.filter.datepicker_first_label"
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              @click:clear="rangeFilter('createdAt', date[0] = '')"

                          ></v-text-field>
                        </template>
                        <v-date-picker
                            @change="rangeFilter('createdAt', date)"
                            v-model="date[0]"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-center">
                      <v-icon color="primary" large>mdi-minus</v-icon>
                    </v-col>
                    <v-col cols="5">
                      <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              outlined
                              hide-details
                              readonly
                              v-model="date[1]"
                              :label="translationsUsersLog.filter.datepicker_second_label"
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              @click:clear="rangeFilter('createdAt', date[1] = '')"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            @change="rangeFilter('createdAt', date)"
                            v-model="date[1]"
                            no-title
                            @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>

                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-alert
                        class="alert ma-0"
                        v-model="alertShow"
                        border="left"
                        dense
                        type="warning"
                    >
                      {{ translationsUsersLog.errors.range_fields_filled }}
                    </v-alert>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        outlined
                        @click="clearFilters"
                    >
                      {{ translations.GLOBAL_VALUE.clear }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        outlined
                        @click="acceptFilters"
                    >
                      {{ translations.GLOBAL_VALUE.search }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn
                  color="white"
                  class="ml-2"
                  fab
                  small
                  :loading="downloadLoading"
                  :disabled="downloadLoading"
                  @click="downloadUsers"
              >
                <v-icon color="primary">
                  mdi-download
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
                :footer-props="{'items-per-page-options': [10, 50, 100]}"
                :items-per-page="10"
                :headers="headers"
                :items="users"
                :search="search"
                :options.sync="usersTableOptions"
                :server-items-length="totalResponseUsers"
                :loading="usersLoading"
            >
              <template v-slot:top>
                <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>
              </template>
              <template v-slot:[`item.profile.isBoy`]="{ item }">
                {{ item.profile.isBoy ? 'man' : 'woman' }}
              </template>

              <template v-slot:[`item.profile.age`]="{ item }">
                {{ item.profile.birthday ? $calculateAge(item.profile.birthday) : '' }}
              </template>

              <template v-slot:[`item.ip`]="{ item }">
                <v-chip
                    v-if="item.ip"
                    small
                >
                  {{ item.ip }}
                  <span>{{ getCfIpCountry(item) }}</span>
                </v-chip>
              </template>

              <template v-slot:[`item.utmTracking`]="{ item }">
                <v-chip
                    small
                    dark
                    :color="item.utmTracking ? 'green' : 'grey'"
                    class="status"
                >
                  <span>
                    {{ item.utmTracking ? 'yes' : 'no' }}
                  </span>
                </v-chip>
              </template>

              <template v-slot:[`item.email`]="{ item }">
                <div v-if="item.isDeleted">
                  <span v-if="item.email === null"> {{ item.deletedEmail }} </span>
                  <span v-else> {{ item.email }}</span>
                </div>
                <span v-else>{{ item.email }}</span>
              </template>

              <template v-slot:[`item.balance.amount`]="{ item }">
                <div style="width: 100px" @click.stop>
                  <v-text-field :disabled="$auth.isOperator" type="number"
                                :color="activeUserId === item.id ? 'success' : ''"
                                @input="debouncedChangeBalance(item, $event)" dense hide-details outlined
                                :value="$getBalance(item.balance)"></v-text-field>
                </div>
              </template>
              <template v-slot:[`item.isAgency`]="{ item }">
                <v-chip
                    v-if="item.isAgency"
                    :color="getColorAgency(item.isAgency)"
                    dark
                    class="status"
                >
                  {{ 'yes' }}
                </v-chip>
              </template>

              <template v-slot:[`item.isDeleted`]="{ item }">
                <v-chip
                    v-if="item.isDeleted"
                    color="red"
                    dark
                    class="status"
                >
                  {{ 'yes' }}
                </v-chip>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                    :color="getColor(item.status)"
                    dark
                    class="status"
                >
                  {{
                    item.status === 0 ? 'REVIEW' : item.status === 1 ? 'ACTIVE' : item.status === 3 ? 'IRRELEVANT' : 'BLOCKED'
                  }}
                </v-chip>
              </template>

              <template v-slot:[`item.profile.countryCode`]="{ item }">
                <span v-if="item.profile.countryCode">
                  {{ $getCountyByCode(item.profile.countryCode).name }}
                </span>

              </template>
              <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
              <template v-slot:[`item.profile.name`]="{ item }">
                <div class="user-img">
                  <img @click.stop="openUserNewPage(item.id)" :src="$getAvatarImage(item)" alt="img">
                  <span class="user-title" @click.stop="openUserNewPage(item.id)">{{ item.profile.name }}</span>
                  <div class="online" v-if="getterUserOnline(item.id)"></div>
                  <v-icon v-if="item.isAgency" class="ml-3" color="primary">mdi-face-agent</v-icon>
                  <v-icon v-if="userIsOperator(item)" class="ml-3" color="primary">mdi-monitor-account</v-icon>
                  <v-icon
                      v-if="item.id !== me.id && ($auth.isAdmin || ($auth.isOperator && me.operators.find(e => e.userId === item.id))) "
                      class="ml-3"
                      color="primary"
                      @click.stop="$switchProfileUser(item.id)"
                  >
                    mdi-account-arrow-left
                  </v-icon>
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    :disabled="$auth.isOperator"
                    class="mr-2"
                    @click.stop="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                    small
                    :disabled="$auth.isOperator"
                    @click.stop="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {api} from '@/providers/api';
import {mapGetters} from 'vuex';
import router from '@/routes/router';
import {getUserFlirt, getUserLike, getUsers, removeUser} from '@/api/usersRequest';
import {postSaveBalance, postSaveMembership} from "@/api/editUsers";
import {getMembers} from "@/api/membershipRequest";
import DeleteModal from "@/components/DeleteModal";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import {format, subYears} from "date-fns";
import {debounce} from 'lodash';
import Vue from "vue";

export default {
  name: 'UsersLog',
  components: {MyBreadcrumbs, DeleteModal},
  data: () => ({
    debouncedChangeBalance: null,
    activeUserId: null,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    users: [],
    search: '',
    searchID: '',
    headers: [
      {text: 'ID', value: 'id', sortable: true},
      {text: 'Name', value: 'profile.name', sortable: true},
      {text: 'Surname', value: 'profile.surname', sortable: true},
      {text: 'ip', value: 'ip', sortable: false},
      {text: 'Is Agency', value: 'isAgency', sortable: true},
      {text: 'Is Deleted', value: 'isDeleted', sortable: false},
      {text: 'Tariff', value: 'membership.membershipTariff.name', sortable: true},
      {text: 'Gender', value: 'profile.isBoy', sortable: true},
      {text: 'Status', value: 'status', sortable: false},
      {text: 'Email', value: 'email', sortable: true},
      {text: 'Agency', value: 'agency.agency.name', sortable: false, width: 120},
      {text: 'Balance', value: 'balance.amount', sortable: true, width: 220},
      {text: 'Age', value: 'profile.age', sortable: false},
      {text: 'Country', value: 'profile.countryCode', sortable: true, width: 200},
      {text: 'Registered At', value: 'createdAt', sortable: true},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    editedItem: {
      name: '',
      balance: '',
      membership: {
        membershipTariff: {
          name: ''
        }
      },
      profile: {
        isBoy: ''
      }
    },
    defaultItem: {
      name: '',
      isBoy: '',
    },
    memberships: [],
    usersLoading: false,
    dialogFilter: false,
    totalResponseUsers: 0,
    usersTableOptions: {},
    balanceValue: [0, 10000],
    ageValue: [18, 99],
    optionsIsAgency: [{text: '', value: ''}, {text: 'Yes', value: true}, {text: 'No', value: false}],
    optionsIsBoy: [{text: '', value: ''}, {text: 'Man', value: true}, {text: 'Woman', value: false}],
    optionsStatus: [{text: '', value: ''}, {text: 'Active', value: 1}, {text: 'Review', value: 0}, {
      text: 'Blocked',
      value: 2
    }, {text: "Irrelevant", value: 3}],
    optionsTariff: ['', 'Silver', 'Gold', 'Platinum', 'Diamond'],
    selectedIsAgency: '',
    selectedIsBoy: '',
    selectedTariff: '',
    selectedStatus: '',
    selectedCountry: '',
    selectedAgency: '',
    filter: {},
    alertShow: false,
    menu1: false,
    menu2: false,
    isDeleted: false,
    operatingProfiles: false,
    isUtm: false,
    date: ['', '',],
    downloadLoading: false
  }),
  async created() {
    this.debouncedChangeBalance = debounce(this.changeBalance, 400);
    this.translationsHeaders()
    await this.$store.dispatch('Agencies/fetchAllAgencies');
  },

  async mounted() {
    this.memberships = await getMembers();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    usersTableOptions: {
      handler(changed) {
        console.log('usersTableOptions:change', changed);
        this.syncUsersFromApi();
      },
      deep: true
    },
    search() {
      this.debouncedSearch();
    },
    searchID() {
      this.debouncedSearch();
    },

  },
  computed: {
    userIsOperator() {
      return user => user.roles.find(e => e.name === 'operator');
    },
    prepareSearchQuery() {
      return this.searchID
          ?
          {
            ...this.operatingProfiles ? { ids: [...this.me?.operators?.map(e => e.userId), this.searchID] } : {ids: [this.searchID]}
          }
          :
          {
            ...this.filter,
            ...this.isUtm ? { hasUtmTracking: this.isUtm } : {},
            ...this.operatingProfiles ? { ids: [...this.me.operators.map(e => e.userId)]} : {},
            balanceMax: this.balanceValue[1],
            balanceMin: this.balanceValue[0],
            birthdayMin: this.calculateDate(this.ageValue[1]).getTime(),
            birthdayMax: this.calculateDate(this.ageValue[0]).getTime(),
            isDeleted: this.isDeleted,
            ...this.search ? {search: this.search.trim()} : {}
          }
    },
    searchIDDisabled() {
      return this.searchID.length > 0
    },
    translations() {
      return this.$t('translation')
    },
    translationsUsersLog() {
      return this.translations.USERS.USERS_LOG
    },
    allAgencies() {
      return this.$store.state.Agencies.allAgencies
    },
    filterBalanceCheck() {
      if (this.balanceValue[0] === '' && this.balanceValue[1] !== '') {
        return true
      } else if (this.balanceValue[1] === '' && this.balanceValue[0] !== '') {
        return true
      } else {
        return false
      }
    },
    filterAgeCheck() {
      if (this.ageValue[0] === '' && this.ageValue[1] !== '') {
        return true
      } else if (this.ageValue[1] === '' && this.ageValue[0] !== '') {
        return true
      } else {
        return false
      }
    },
    filterDateCheck() {
      if (this.date[0] === null && this.date[1] !== null) {
        return true
      } else if (this.date[1] === null && this.date[0] !== null) {
        return true
      } else {
        return false
      }
    },
    breadcrumbs() {
      return [
        {text: this.translations.NAVBAR.DASHBOARD.title, href: '/'},
        {text: this.translations.NAVBAR.MANAGE_USERS.items.users_page, disabled: true,}
      ];
    },
    ...mapGetters('User', ['me']),

    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },

  },
  methods: {

    async changeBalance(item, newBalance) {
      await postSaveBalance(item.id, Number(newBalance))
      this.activeUserId = item.id
      setTimeout(() => {
        this.activeUserId = null
      }, 700)
    },
    getCfIpCountry(item) {
      if (item.cfIpCountry) {
        return this.$getCountyByCode(item.cfIpCountry.toLowerCase())?.name ? ` — ${this.$getCountyByCode(item.cfIpCountry.toLowerCase()).name}` : ''
      }
    },
    async downloadUsers() {
      this.downloadLoading = true
      try {
        const response = await api.get('/v2/admin/users/csv', {
          params: {
            ...this.prepareSearchQuery
          }
        })
        const parsedData = this.parseCSV(response);
        this.downloadCSV(parsedData);
      } catch (e) {
        console.log(e)
      } finally {
        this.downloadLoading = false
      }
    },
    debouncedSearch: debounce(function () {
      this.syncUsersFromApi();
    }, 400),

    parseCSV(csvString) {
      const [headerLine, ...lines] = csvString.trim().split("\n");
      const headers = headerLine.split(",");

      return lines.map(line => {
        const values = line.split(",");
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = values[index];
        });
        if (obj.registeredAt) {
          obj.registeredAt = JSON.stringify(new Date(obj.registeredAt));
        }
        return obj;
      });
    },

    downloadCSV(data, filename = "data.csv") {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(data[0]).join(";"),
        ...data.map(item => Object.values(item).join(";"))
      ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

      const readyData = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", readyData);
      link.setAttribute("download", filename);
      link.click();
    },
    translationsHeaders() {
      this.headers = [
        {text: 'ID', value: 'id', sortable: true},
        {text: this.translationsUsersLog.table_headers.name, value: 'profile.name', sortable: true},
        {text: this.translationsUsersLog.table_headers.surname, value: 'profile.surname', sortable: true},
        {text: 'IP', value: 'ip', sortable: false},
        {text: 'UTM', value: 'utmTracking', sortable: false},
        {text: this.translationsUsersLog.table_headers.is_agency, value: 'isAgency', sortable: true},
        {text: this.translationsUsersLog.table_headers.is_delete, value: 'isDeleted', sortable: false},
        {
          text: this.translationsUsersLog.table_headers.tariff,
          value: 'membership.membershipTariff.name',
          sortable: true
        },
        {text: this.translationsUsersLog.table_headers.gender, value: 'profile.isBoy', sortable: true},
        {text: this.translationsUsersLog.table_headers.status, value: 'status', sortable: false},
        {text: this.translationsUsersLog.table_headers.email, value: 'email', sortable: true},
        {
          text: this.translationsUsersLog.table_headers.agency,
          value: 'agency.agency.name',
          sortable: false,
          width: 120
        },
        {text: this.translationsUsersLog.table_headers.balance, value: 'balance.amount', sortable: true},
        {text: this.translationsUsersLog.table_headers.age, value: 'profile.age', sortable: true},
        {
          text: this.translationsUsersLog.table_headers.country,
          value: 'profile.countryCode',
          sortable: true,
          width: 200
        },
        {text: this.translationsUsersLog.table_headers.created, value: 'createdAt', sortable: true},
        {text: this.translationsUsersLog.table_headers.actions, value: 'actions', sortable: false},
      ]
    },
    rangeFilter(key, values) {
      const [value1, value2] = values;
      if (this.filter.hasOwnProperty('createdAtMax')) {
        Vue.delete(this.filter, 'createdAtMax');
      }
      if (this.filter.hasOwnProperty('createdAtMin')) {
        Vue.delete(this.filter, 'createdAtMin');
      }
      if (value1 !== '' && value2 !== '') {
        // if (key === 'createdAt') {
        //   obj = {
        //     [key]: {"between": [new Date(value1).toISOString(), new Date(value2).toISOString()]}
        //   };
        // } else {
        //   obj = {
        //     [key]: {"between": [value1, value2]}
        //   };
        // }
        this.filter = {
          ...this.filter,
          createdAtMax: new Date(value2).getTime(),
          createdAtMin: new Date(value1).getTime()
        }
      }
    },
    clearFilters() {
      this.isDeleted = false
      this.filter = {}
      this.selectedIsAgency = ''
      this.selectedTariff = ''
      this.selectedStatus = ''
      this.selectedIsBoy = ''
      this.selectedCountry = ''
      this.ageValue = [18, 99]
      this.balanceValue = [0, 10000]
      this.date = ['', '']
      this.selectedIsAgency = ''
    },
    async acceptFilters() {
      if (!this.filterBalanceCheck && !this.filterAgeCheck && !this.filterDateCheck) {
        this.dialogFilter = false
        await this.syncUsersFromApi()
      } else {
        this.alertShow = true
        setTimeout(() => {
          this.alertShow = false
        }, 3000)
      }

    },
    updateFilter(key, value) {
      if (value !== '' && value !== null) {
        this.addFilter(key, value);
      } else {
        this.removeFilter(key);
      }
    },
    addFilter(key, value) {
      this.filter = {
        ...this.filter,
        [key]: value
      }
      // const hasObjectWithKey = this.filter.hasOwnProperty(key);
      //
      // if (!hasObjectWithKey) {
      //   this.filter = {
      //     ...this.filter,
      //     [key]: value
      //   }
      // } else {
      //   this.filter.forEach(obj => {
      //     if (obj.hasOwnProperty(key)) {
      //       obj[key] = value;
      //     }
      //   });
      // }
    },

    removeFilter(key) {
      if(this.filter.hasOwnProperty(key)) {
        Vue.delete(this.filter, key)
      }
      // const index = this.filter.findIndex(filter => filter[key] !== undefined);
      // if (index !== -1) {
      //   this.filter.splice(index, 1);
      // }
    },
    openUserNewPage(id) {
      const url = this.$router.resolve(`/users/${id}`).href;
      window.open(url, '_blank');
    },

    calculateDate(yearsToSubtract) {
      if (yearsToSubtract) {
        const currentDate = new Date();
        const newDate = subYears(currentDate, yearsToSubtract);
        console.log(newDate)
        return newDate
      }
      return null;

    },

    isActiveHeaderValue(value){
      if(value === 'id' || value === 'isAgency' || value === 'email' || value === 'createdAt' ) {
        return value
      }
      switch (value) {
        case 'profile.name':
          return 'profileName';
        case 'profile.surname':
          return 'profileSurname';
        case 'membership.membershipTariff.name':
          return 'membershipTariffName';
        case 'profile.isBoy':
          return 'profileIsBoy';
        case 'balance.amount':
          return 'balanceAmount';
        case 'profile.age':
          return 'profileAge';
        case 'profile.countryCode':
          return 'profileCountryCode';
      }
    },

    async syncUsersFromApi() {
      this.usersLoading = true;
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.usersTableOptions;
        const params = {
          ...sortBy.length ? {
            sortMethod: sortDesc[0] ? 'DESC' : 'ASC',
            orderBy: this.isActiveHeaderValue(sortBy[0])
          } : {sortMethod: 'ASC', orderBy: 'createdAt'},
          limit: limit === -1 ? 100 : limit,
          page,
        }



        const response = await api
            .get('/admin/users', {
              params: {
                ...this.prepareSearchQuery,
                ...params
              }
            });


        const {items, meta} = response;

        console.log('items - ', items)

        this.users = items
        this.totalResponseUsers = meta.totalItems;


      } catch (e) {
        console.log('error', e);

      } finally {
        this.usersLoading = false;
      }

    },

    getColorAgency(status) {
      if (status) return 'green'
    },
    getColor(status) {
      if (status === 0) return '#9E9E9E'
      else if (status === 1) return 'green'
      else if (status === 3) return 'dark'
      else return 'orange'
    },
    usersCheck(users) {
      Object.filter = (obj, predicate) =>
          Object.keys(obj)
              .filter(key => predicate(obj[key]))
              .reduce((res, key) => (
                  res[key] = obj[key], res), {});


      const newUsers = []

      Object.filter(users, score => {
        if (score.profile !== null) {
          newUsers.push(score)
        }
      })
      return newUsers
    },


    editItem(item) {
      const url = this.$router.resolve(`users/${item.id}/edit`).href;
      window.open(url, '_blank');
      // this.editedIndex = this.users.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // this.dialog = true;
    },

    deleteItem(item) {
      this.deleteId = item.id
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await api.delete(`/admin/users/${this.deleteId}`)
      await this.syncUsersFromApi()
      // this.users = this.users.filter(e => e.id !== this.deleteId)
      this.dialogDelete = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    async userOpen(user) {
      const dataLike = await getUserLike(user.id);
      const dataFlirt = await getUserFlirt(user.id);
      const obj = {
        like: {
          dataLike
        },
        flirt: {
          dataFlirt
        }
      };
      router.push({name: 'user-log', params: {id: user.id, targetUser: user, obj: obj}});
    },
    async save() {
      await postSaveBalance(this.editedItem.id, this.editedItem.balance);
      await postSaveMembership(this.editedItem.id, this.editedItem.membership.membershipTariff.id);
      this.users = await getUsers().then(r => r.data)
      this.close();
    },

  }
};
</script>

<style scoped lang="scss">
.search-id {
  max-width: 120px;

  ::v-deep {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.agent-icon {
  position: absolute;
  margin-left: -35px
}

.users-log {
  .card, .card-man, .card-woman {
    max-width: 330px;
    background: #3da03d;
    color: white;

    .v-card__title {
      text-transform: uppercase;
    }

    .v-icon {
      color: #f4f6f9;
    }
  }

  .card-man {
    background: #5080f2;
  }

  .card-woman {
    background: #f37080;
  }

  .table-header {
    background: #2f55b4;
    color: white;

    .search-input {
      color: white;

      ::v-deep label {
        color: white;
      }

      ::v-deep .v-icon.v-icon {
        color: white;
      }

      ::v-deep .v-input__slot::before {
        border-color: white;
      }

      ::v-deep input {
        color: white;
      }
    }


  }
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.status {
  font-size: 9px;
}

.v-chip {
  padding: 0 6px !important;
  height: 21px;
}

.online {
  background: #4fa526;
  width: 13px;
  height: 13px;
  position: absolute;
  border-radius: 100px;
  margin-left: 20px;
  border: 2px solid #fff;
  margin-top: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.user-title {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #2f55b4;
  }
}

.alert {
  position: absolute;
}
</style>
