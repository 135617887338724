var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.contact,"item-key":"id","show-expand":"","expanded":_vm.expanded,"single-expand":_vm.singleExpand,"loading":_vm.contactLoading,"options":_vm.contactOptions,"server-items-length":_vm.contactTotalItems},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.userOpen,"update:options":function($event){_vm.contactOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('DeleteModal',{attrs:{"dialog":_vm.dialogDelete},on:{"close-options":_vm.closeDelete,"save-options":_vm.deleteItemConfirm}}),_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.translationsContactLog.title))])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"row"},[_c('v-col',[_c('p',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.translationsContactLog.expanded_item.message))]),_c('p',[_vm._v(" "+_vm._s(item.message)+" ")])])],1),(item.answered)?_c('div',{staticClass:"row"},[_c('v-col',[_c('p',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.translationsContactLog.expanded_item.answer))]),_c('p',[_vm._v(" "+_vm._s(item.answer)+" ")])])],1):_c('div',{staticClass:"row"},[_c('v-col',[_c('p',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.translationsContactLog.expanded_item.answer))]),_c('v-textarea',{attrs:{"filled":"","placeholder":"Answer message"},model:{value:(item.answer),callback:function ($$v) {_vm.$set(item, "answer", $$v)},expression:"item.answer"}}),_c('v-btn',{attrs:{"dark":"","color":"green"},on:{"click":function($event){return _vm.sendAnswer(item)}}},[_vm._v(" Send ")])],1)],1)])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-img "},[_vm._v(" "+_vm._s(item.owner ? item.owner.name : '')+" ")])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.priority !== '')?_c('v-chip',{attrs:{"color":_vm.getColor(item.priority),"dark":""}},[_vm._v(" "+_vm._s(item.priority)+" ")]):_c('div',[_vm._v(" - ")])],1)]}},{key:"item.answered",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"color":item.answered ? 'green' : 'orange',"dark":""}},[_vm._v(" "+_vm._s(item.answered ? 'yes' : 'no')+" ")])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","disabled":_vm.$auth.isOperator},on:{"click":function($event){$event.stopPropagation();return _vm.deleteDialog(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }