<template>
  <v-data-table
      :footer-props="{'items-per-page-options': [10, 50, 100]}"
      :items-per-page="10"
          :headers="headers"
          :items="usersText"
          item-key="id"
          show-expand
          class="elevation-1"
          :expanded.sync="expanded"
          :single-expand="singleExpand"
          :options.sync="optionsText"
          :server-items-length="textTotalItems"
          :loading="textLoading"


  >
    <template v-slot:top>
      <v-toolbar flat color="primary">
        <v-toolbar-title class="title">{{ translations.title  }}</v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="row-text">
          <div class="text-container">
            <h3>Hobby</h3>
            <p>
              text
            </p>
            <h3>About</h3>
            <p>text</p>
            <h3>Ideal</h3>
            <p>text</p>
          </div>

          <v-col>
            <v-btn v-if="item.status === 0" color="success" @click="accept(item)">Accept</v-btn>
            <v-btn v-if="item.status === 0" color="error" @click="reject(item)">Reject</v-btn>
          </v-col>
        </div>

      </td>
    </template>

    <template v-slot:[`item.user.profile.name`]="{ item }">
      <router-link class="user-img" :to="{name: 'user-log', params: {id: item.user.id}}">
        <img :src="$getAvatarImage(item.user)">
        {{ item.user.profile.name }}

        <v-icon
                v-if="item.user.id !== me.id && !$auth.isAdmin"
                class="ml-3"
                color="primary"
                @click.stop="$switchProfileUser(item.user.id)"
        >
          mdi-account-arrow-left
        </v-icon>
      </router-link>
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
              :color="getColor(item.status)"
              dark
      >
        <span v-if="item.status === 0">Waiting</span>
        <span v-if="item.status === 1">Accepted</span>
        <span v-if="item.status === 2">Rejected</span>
      </v-chip>
    </template>

  </v-data-table>
</template>

<script>
  import {approveChangeImageRequest, getChangeImageRequests, getUserFlirt, getUserLike} from "@/api/usersRequest";
  import router from "@/routes/router";
  import {mapGetters} from "vuex";

  export default {
    name: "UsersTextTable",
    data: () => ({
      headers: [],
      usersText: [],
      textLoading: [],
      textTotalItems: 0,
      optionsText: {},
      expanded: [],
      singleExpand: true,
    }),
    computed: {
      ...mapGetters('User', ['me']),
      translations() {
        return this.$t('translation.USERS.MODERATION_USERS_LOG.tabs_items.text_table')
      }
    },
    created() {
      this.headers =  [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          sortable: false
        },

        {
          text: this.translations.headers.name,
          value: 'user.profile.name',
          sortable: false

        },
        {
          text: this.translations.headers.created_at,
          value: 'createdAt',
          sortable: false

        },
        {
          text: this.translations.headers.status,
          value: 'status',
          sortable: true

        },
        {
          text: this.translations.headers.destination,
          value: 'destination',
          sortable: false
        },

      ]
    },
    methods: {
      async syncTextFromApi() {
        this.textLoading = true;
        try {
          const {sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsText;
          //change request
          const response = await getChangeImageRequests({
            params: {
              ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
              limit: limit === -1 ? 100 : limit,
              page
            }
          });
          const {data: items, total} = response;

          this.usersText = items;
          this.textTotalItems = total;
        } catch (e) {
          console.log('error contact', e);
        } finally {
          this.textLoading = false;
        }
      },

      async fetchText() {
        // const {
        //   data
        // } = await getChangeTextRequests();
        this.usersText = data;
      },

      async accept(item) {
        const response = await approveChangeImageRequest(item.id, true);
        console.log(response);
        item.status = 'Accepted';
        this.fetchAvatar();
      },
      async reject(item) {
        const response = await approveChangeImageRequest(item.id, false);
        console.log(response);
        item.status = 'Rejected';
        this.fetchAvatar();
      },


      async userOpen(user) {
        const dataLike = await getUserLike(user.user.id);
        const dataFlirt = await getUserFlirt(user.user.id);
        const obj = {
          like: {
            dataLike
          },
          flirt: {
            dataFlirt
          }
        };
        console.log(user);
        router.push({name: 'user-log', params: {id: user.user.id, obj: obj}});
      },

      getColor(status) {
        if (status === 1)
          return 'green';
        else if (status === 2)
          return 'red';
        else return 'orange';
      },
    }
  }
</script>

<style scoped lang="scss">


  .row, .row-text {
    width: 200px;
    display: flex;
    flex-wrap: nowrap;
    margin: 5px;

    .user-img {
      margin-right: 30px;
    }

    .v-btn {
      margin-right: 5px;
      margin-bottom: 15px;
    }
  }

  .row-text {
    width: unset;
  }

  .title {
    color: #f4f6f9;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 20px;
  }

  .text-container {
  }

  .user-img {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
  }
</style>