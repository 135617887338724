import DefaultAvatarImage from "@/mixins/img/userAvatar.svg"
export const ImageResolverMixin = {
  methods: {
    $getAvatarImage(user) {
      console.log(user)
      return user.profile?.avatarUrl || user.profile?.avatar?.url || user.profile?.avatar || user?.avatar?.url || user?.avatar || DefaultAvatarImage;
    },
    $getImage(url) {
      return url || DefaultAvatarImage;
    },
  },
};
