var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{key:"id",staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headersLike,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-img",on:{"click":function($event){return _vm.userPush(item.from)}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.from)}}),_vm._v(" "+_vm._s(item.from.name)+" "),(item.from.id !== _vm.me.id && !_vm.$auth.isAdmin && !_vm.$auth.isOperator)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.from.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1)]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-img",on:{"click":function($event){return _vm.userPush(item.to)}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.to)}}),_vm._v(" "+_vm._s(item.to.name)+" "),(item.to.id !== _vm.me.id && !_vm.$auth.isAdmin && !_vm.$auth.isOperator)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.to.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1)]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.file.url,"max-height":"100","max-width":"100"}})]}},{key:"item.file.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.file.createdAt),'Pp'))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }