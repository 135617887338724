<template>
  <div>
    <v-alert class="alert"
             border="top"
             type="error"
             v-model="alertErr">{{ translations.alert_text }}</v-alert>
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
        :headers="headers"
        :items="usersPhoto"
        item-key="id"
        show-expand
        class="elevation-1"
        :expanded.sync="expanded"
        :single-expand="singleExpand"
        :options.sync="optionsAvatar"
        :server-items-length="avatarTotalItems"
        :loading="avatarLoading"
        ref="myTable"


    >
      <template v-slot:top>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="title">{{ translations.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-select
                :label="translations.status_label"
                hide-details
                dark
                dense
                outlined
                :items="photoStatus"
                class="mt-3"
                clearable
                v-model="selectedStatus"
                @change="changeStatusPhoto"/>
          </v-toolbar-items>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="ma-0">
            <v-col cols="4">

              <div class="pending-photo">
                <div class="photo">
                  <img :src="$getImage(item.image.url)" alt="">
                  <v-icon v-if="item.image.paid" color="red" class="icon-lock">
                    mdi-lock
                  </v-icon>
                </div>
              </div>

            </v-col>

            <v-col>
              <v-btn width="100" class="mb-5 d-block" v-if="item.status === 0 || item.status === 2" color="success" @click="accept(item)">Accept</v-btn>
              <v-btn width="100" class="mb-10 d-block" v-if="item.status === 0" color="error" @click="reject(item)">Reject</v-btn>
              <v-btn color="primary"
                     :loading="loadingBtnDownload"
                     :disabled="loadingBtnDownload"
                     @click="download(item)">{{ translations.download_btn }}</v-btn>
            </v-col>
          </v-row>

        </td>
      </template>

      <template v-slot:[`item.user.profile.name`]="{ item }">
        <router-link class="user-img" :to="{name: 'user-log', params: {id: item.user.id}}">
          <img :src="$getAvatarImage(item.user)">
          {{ item.user.profile.name }}

          <v-icon
              v-if="item.user.id !== me.id && !$auth.isAdmin"
              class="ml-3"
              color="primary"
              @click.stop="$switchProfileUser(item.user.id)"
          >
            mdi-account-arrow-left
          </v-icon>
        </router-link>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
            :color="getColor(item.status)"
            dark
        >
          <span v-if="item.status === 0">Waiting</span>
          <span v-if="item.status === 1">Accepted</span>
          <span v-if="item.status === 2">Rejected</span>
        </v-chip>
      </template>
      <template v-slot:[`item.destination`]="{ item }">

        <span v-if="item.destination === 0">Avatar</span>
        <span v-if="item.destination === 1">Album</span>
        <span v-if="item.destination === 2">Background</span>

      </template>
    </v-data-table>
  </div>
</template>

<script>
import {approveChangeImageRequest, getChangeImageRequests, getUserFlirt, getUserLike} from "@/api/usersRequest";
import router from "@/routes/router";
import {mapGetters} from "vuex";
import axios from "axios";
import {api} from "@/providers/api";

export default {
  name: "UsersPhotoTable",

  data: () => ({
    alertErr: false,
    loaderBtnDownload: null,
    loadingBtnDownload: false,
    avatarTotalItems: 0,
    optionsAvatar: {},
    avatarLoading: true,
    expanded: [],
    singleExpand: true,
    photoStatus: ['Waiting', 'Accepted', 'Rejected'],
    selectedStatus: null,
    headers: [],
    usersPhoto: [],
    page: 1
  }),
  watch: {
    optionsAvatar: {
      handler(change) {
        console.log('change avatar', change);
        this.syncAvatarFromApi();
      },
      deep: true
    },
    selectedStatus() {
      this.optionsAvatar.page = 1;

      this.syncAvatarFromApi().then(() => {
        if (this.changeStatusPhoto() === 0) {
          setTimeout(() => {
            this.openFirstItem()
          }, 200)
        }
      });

    },

  },
  computed: {
    ...mapGetters('User', ['me']),
    translations() {
      return this.$t('translation.USERS.MODERATION_USERS_LOG.tabs_items.photo_table')
    }
  },
  created() {
    this.fetchAvatar()
    this.headers =  [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
        sortable: false
      },

      {
        text: this.translations.headers.name,
        value: 'user.profile.name',
        sortable: false

      },
      {
        text: this.translations.headers.created_at,
        value: 'createdAt',
        sortable: false

      },
      {
        text: this.translations.headers.status,
        value: 'status',
        sortable: true

      },
      {
        text: this.translations.headers.destination,
        value: 'destination',
        sortable: false
      },

    ]
  },
  methods: {
    async download(item)  {
      const url = item.image.originalS3Url
      if (url !== null) {

          axios({
            url: url,
            method: 'GET',
            responseType: 'blob'
          }).then((response) => {
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileUrl
            const fileName = `image_${Date.now()}.jpg`;

            fileLink.setAttribute('download', fileName)
            document.body.appendChild(fileLink)
            fileLink.click()
          })

      }
      else {
        this.alertErr = true
        setTimeout(() => {
          this.alertErr = false
        }, 3000)
      }
    },
    openFirstItem() {
      if (this.$refs.myTable && this.$refs.myTable.items && this.$refs.myTable.items.length > 0) {
        this.expanded.push(this.$refs.myTable.items[0])
      }
    },
    changeStatusPhoto(val) {
      const status = [
          'Waiting',
          'Accepted',
          'Rejected'
      ]
      return status.indexOf(this.selectedStatus) === -1 ? null : status.indexOf(this.selectedStatus)
    },
    async syncAvatarFromApi() {
      this.avatarLoading = true;
      const status = this.changeStatusPhoto()
      if (status !== null) {
        try {
          const {sortBy, sortDesc, page, itemsPerPage: limit } = this.optionsAvatar;
          const response = await getChangeImageRequests({
            params: {
              status: status,
              ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
              limit: limit === -1 ? 100 : limit,
              page
            }
          });
          // console.log("RESPONSE", response)



          this.usersPhoto = response.data;
          this.avatarTotalItems = response.total;
          // this.optionsAvatar.itemsPerPage = 7
        } catch (e) {
          console.log('error contact', e);
        } finally {
          this.avatarLoading = false;
        }
      }

      else {
        try {
          const {sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsAvatar;
          const response = await getChangeImageRequests({
            params: {
              ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
              limit: limit === -1 ? 100 : limit,
              page
            }
          });
          // console.log("RESPONSE2", response)
          const {data: items, total} = response;

          this.usersPhoto = items;
          this.avatarTotalItems = total;
        } catch (e) {
          console.log('error contact', e);
        } finally {
          this.avatarLoading = false;
        }
      }

    },

    async fetchAvatar() {
      const {
        data
      } = await getChangeImageRequests();
      this.usersPhoto = data;
    },

    async accept(item) {
      const response = await approveChangeImageRequest(item.id, true);
      console.log(response);
      item.status = 'Accepted';
      this.syncAvatarFromApi().then(() => {
        if (this.changeStatusPhoto() === 0) {
          setTimeout(() => {
            this.openFirstItem()
          }, 200)
        }
      });
    },

    async reject(item) {
      const response = await approveChangeImageRequest(item.id, false);
      console.log(response);
      item.status = 'Rejected';
      this.syncAvatarFromApi().then(() => {
        if (this.changeStatusPhoto() === 0) {
          setTimeout(() => {
            this.openFirstItem()
          }, 200)
        }
      });
    },

    getColor(status) {
      if (status === 1)
        return 'green';
      else if (status === 2)
        return 'red';
      else return 'orange';
    },

    async userOpen(user) {
      const dataLike = await getUserLike(user.user.id);
      const dataFlirt = await getUserFlirt(user.user.id);
      const obj = {
        like: {
          dataLike
        },
        flirt: {
          dataFlirt
        }
      };
      console.log(user);
      router.push({name: 'user-log', params: {id: user.user.id, obj: obj}});
    },

  },

}
</script>

<style scoped lang="scss">
.pending-photo{
  display: flex;
  justify-content: center;
  .photo{
    position: relative;
  }
  img{
    max-width:800px;
    max-height:200px;
    width: unset;
    height: unset;
    border: unset;
    margin: unset;
    border-radius: unset;
  }
}
.icon-lock{
  position: absolute;
  bottom: 8px;
  right: 0;
}
.alert {
  position: fixed;
  z-index: 100;
  right: 15px;
  width: max-content;
  top: 75px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  color: #f4f6f9;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.text-container {
}

.user-img {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}
</style>