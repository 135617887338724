<template>
  <v-data-table
      :footer-props="{'items-per-page-options': [10, 50, 100]}"
      :items-per-page="10"
      :headers="headersModeration"
      :items="items"
      :server-items-length="totalItems"
      :options.sync="options"
      :loading="loading"
      item-key="name"
      class="elevation-0"
  >
    <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
    <template v-slot:[`item.type`]="{ item }">{{ typeModeration(item.type) }}</template>
    <template v-slot:[`item.editor`]="{ item }">
      <div v-if="!item.editor">

      </div>
      <div v-else class="user-img">
        <img :src="$getAvatarImage(item.editor)" alt="img">
        <span class="user-title" @click.stop="openUserNewPage(item.editor.id)">{{ item.editor.profile.name }}</span>
        <div class="online" v-if="getterUserOnline(item.editor.id)"></div>
        <v-icon v-if="item.editor.isAgency" class="ml-3" color="primary">mdi-face-agent</v-icon>
        <v-icon
            v-if="item.editor.id !== me.id && ($auth.isAdmin || ($auth.isOperator && me.operators.find(e => e.userId === item.editor.id)))"
            class="ml-3"
            color="primary"
            @click.stop="$switchProfileUser(item.editor.id)"
        >
          mdi-account-arrow-left
        </v-icon>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters} from "vuex";
import {api} from "@/providers/api";
import {getBotMessagesActivity} from "@/api/chat-bot.api";

export default {
  name: "UserLogModerationTable",
  data: () => ({
    headersModeration: [],
    options: {},
    totalItems: 0,
    loading: true,
    items: [],
  }),
  props: {
    refresh: {
      type: Boolean,
    }
  },
  computed: {
    ...mapGetters('User', ['me']),

  },
  watch: {
    refresh(status) {
      if (status) {
        this.syncDataFromApi()
      }
    },
    options: {
      handler() {
        this.syncDataFromApi()
      },
      deep: true
    }
  },
  created() {
    this.headersModeration = [
      {text: 'ID', sortable: false, value: 'id'},
      {text: 'User', sortable: false, value: 'editor'},
      {text: 'Type', sortable: false, value: 'type'},
      {text: 'Moderation Date', sortable: false, value: 'createdAt'},
    ]
  },
  methods: {
    async syncDataFromApi() {
      this.loading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options
        const response = await api.get(`/admin/users/${this.$route.params.id}/moderation-logs`, {page, limit})

        const {items, meta} = response
        this.items = items;
        this.totalItems = meta.totalItems
      } catch (e) {
        console.log('Error', e)
      } finally {
        this.loading = false
        this.$emit('refreshStop', 'moderation')
      }
    },


    typeModeration(index) {
      const types = [
        'PASSWORD',
        'USER_INFO',
        'PROFILE_INFO',
        'PHOTO',
        'PASSPORT',
        'AVATAR',
        'MEMBERSHIP',
        'STATUS',
        'ROLE',
        'BALANCE'
      ]
      return types[index]
    },
    openUserNewPage(id) {
      const url = this.$router.resolve(`/users/${id}`).href;
      window.open(url, '_blank');
    },
  }
}
</script>

<style scoped lang="scss">
.user-img {
  display: flex;
  align-items: center;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.title {
  color: #f4f6f9;
}

.photo-img {
  margin-top: 3px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
  }

}

.user-title {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #2f55b4;
  }
}
</style>