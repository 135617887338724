<template>
  <div>
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
        :headers="headersTrans"
        :items="items"
        :server-items-length="totalItems"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        key="id"

    >

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ new Date(item.createdAt) | date('Pp') }}
      </template>
      <template v-slot:[`item.category`]="{ item }">
        {{ item.category }}
      </template>

    </v-data-table>
  </div>
</template>

<script>

import {api} from "@/providers/api";

export default {
  name: "UserManLogTransactionTable",
  props: {
    refresh: {
      type: Boolean,
      default: false
    },

  },
  data: () => ({
    headersTrans: [],
    options: {},
    totalItems: 0,
    loading: true,
    items: []
  }),
  computed: {
    translations() {
      return this.$t('translation.USERS.USER_LOG.tabs_items.transaction_table.headers')
    }
  },
  created() {
    this.headersTrans = [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',

      },

      {
        text: this.translations.created_at,
        sortable: false,
        value: 'createdAt',
      },

      {
        text: this.translations.amount,
        sortable: false,
        value: 'amount',
      },
      {
        text: this.translations.category,
        sortable: false,
        value: 'category',
      },
    ]

  },
  watch: {
    refresh(status) {
      this.loading = status
      if (status) {
        this.options.page = 1
        this.syncDataFromApi()
      }
    },
    options: {
      handler() {
        this.syncDataFromApi()
      },
      deep: true
    }
  },

  methods: {


    async syncDataFromApi() {
      this.loading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options
        const response = await api.get('/admin/transactions/user', {
          params: {
            ...sortBy.length ? {
              sortMethod: sortDesc[0] ? 'DESC' : 'ASC',
              orderBy: sortBy[0]
            } : {},
            limit: limit === -1 ? 100 : limit,
            page,
            user_id: this.$route.params.id

          },

        })

        this.items = response.items;
        this.totalItems = response.meta.totalItems
      } catch (e) {
        console.log('Error', e)
      } finally {
        this.loading = false
        this.$emit('refreshStop', 'transaction')
      }
    },
  }
}
</script>

<style scoped>

</style>