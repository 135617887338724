<template>
  <div class="pay-out">
    <PreloaderPage class="preloader"  v-if="loadingActivity"/>
    <v-alert class="alertSuccess" dark v-model="alertPayOut" type="success">
      {{ translationsAgencyLogPayOut.alert_message }}
    </v-alert>
    <v-container :class="{'disabled': loadingActivity}">
      <MyBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-tabs v-model="currentTab" class="mb-3" background-color="#f4f6f9" color="primary">
        <v-tab to="staff">
          Staff
        </v-tab>
        <v-tab to="transactions">
          {{ translationsAgencyLog.breadcrumbs.transactions }}
        </v-tab>
        <v-tab to="chats">
          {{ 'Chats' }}
        </v-tab>
        <v-tab to="activities">
          {{ translationsAgencyLog.breadcrumbs.activities }}

        </v-tab>
        <v-tab v-if="!this.$auth.isAdmin" to="withdrawal">
          {{ translationsAgencyLog.breadcrumbs.withdrawal }}
        </v-tab>
        <v-tab to="stats">
          {{ translationsAgencyLog.breadcrumbs.stats }}
        </v-tab>
        <v-tab to="settings">
          {{ translationsAgencyLog.breadcrumbs.settings }}
        </v-tab>
        <v-tab to="pay-out">
          {{ translationsAgencyLog.breadcrumbs.pay_out }}
        </v-tab>
      </v-tabs>
    </v-container>
    <PreloaderPage class="preloader"  v-if="loading"/>
    <v-container v-else class="my-5">
      <v-row>
        <v-col cols="8">
          <v-form  @submit.prevent ref="form">
            <v-card outlined height="600" class="overflow-y-auto pa-2">
              <v-row class="ma-0">
                <v-col class="mt-3 pa-0" cols="12">
                  <v-card outlined>
                    <v-row class="ma-0 align-center ">

                      <v-col cols="2">
                        <v-row class="ma-0 pa-0 justify-center">
                          <v-icon
                              color="primary"
                              size="100"
                          >
                            mdi-face-agent
                          </v-icon>
                        </v-row>

                      </v-col>
                      <v-col cols="8">

                        <v-row class="ma-3">
                          <v-col class="pa-0 pr-2"><span class="text-body-1 font-weight-medium ">{{ translationsAgencyLogPayOut.agency_label }} </span> <span
                              class="font-weight-medium">{{ agency.name }}</span></v-col>
                        </v-row>
                        <v-row class="ma-3">
                          <v-col class="pa-0 pr-2">
                            <span class="text-body-1 font-weight-medium">Balance: </span>
                            <span class="font-weight-medium ">{{ balance.balance }} $</span>
                          </v-col>
                        </v-row>
                        <v-row v-if="hiddenElement" class="ma-3">
                          <v-col class="pa-0 pr-2">
                            <span class="text-body-1 font-weight-medium">Credit Card Balance: </span>
                            <span class="font-weight-medium ">{{ $getBalance(balance.creditCardBalance) }} $</span>
                          </v-col>
                        </v-row>
                        <v-row v-if="hiddenElement" class="ma-3">
                          <v-col class="pa-0 pr-2">
                            <span class="text-body-1 font-weight-medium">PayPal Available Balance: </span>
                            <span class="font-weight-medium ">{{ $getBalance(balance.paypalBalanceAvailable) }} $</span>
                          </v-col>
                        </v-row>

                        <v-row class="ma-3 align-center">
                          <div class="mr-5"><span class="text-body-1 font-weight-medium">{{ translationsAgencyLogPayOut.withdrawal_amount }} </span></div>
                          <div>
                            <v-text-field
                                type="number"
                                v-model="agencyBalance"
                                outlined
                                hide-details
                                dense
                                :rules="[withdrawalAmountAgencyRule]"
                            ></v-text-field>
                          </div>
                        </v-row>
                        <v-row v-if="hiddenElement" class="ma-3 align-center">
                          <v-checkbox label="PayPal" class="ma-0" hide-details v-model="agencyPayPal"></v-checkbox>
                        </v-row>
                      </v-col>
                      <v-col cols="1"/>
                      <v-divider vertical></v-divider>
                      <v-col cols="1">
                        <v-row class="justify-center">
                          <v-checkbox
                              v-model="selected"
                              color="success"
                              hide-details
                          ></v-checkbox>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

              </v-row>
              <v-row v-if="value.length > 0" class="ma-0">
                <v-col class="mt-2 pa-1" cols="4" v-for="user in value" :key="user.id">

                  <v-card outlined>
                    <v-row class="ma-0 align-center justify-center d-flex flex-column ">

                      <v-avatar size="100">
                        <v-img :src="$getAvatarImage(user)"></v-img>

                      </v-avatar>
                      <v-card-title> {{ user.name }} </v-card-title>
                      <v-card-subtitle class="pa-0 grey--text">Balance: {{ $getBalance(user.balance) }}</v-card-subtitle>
                      <v-card-subtitle class="pa-0 grey--text" v-if="hiddenElement">Credit Card Balance: {{ $getBalance(user.paypalBalance.creditCardBalance) }}</v-card-subtitle>
                      <v-card-subtitle class="pa-0 grey--text" v-if="hiddenElement">PayPal Available Balance: {{ $getBalance(user.paypalBalance.paypalBalanceAvailable) }}</v-card-subtitle>
                      <v-text-field
                          class="mt-2"
                          :rules="[withdrawalAmountRule(user, user.withdrawalAmount)]"
                          type="number"
                          v-model="user.withdrawalAmount"
                          outlined
                          hide-details
                          label="Withdrawal amount"
                          dense
                      />
                      <v-checkbox v-if="hiddenElement" dense label="PayPal" class="ma-0" hide-details v-model="user.isPayPal"></v-checkbox>
                      <v-card-actions>
                        <v-btn
                            small
                            color="red"
                            text
                            @click="remove(user.id)"
                        >
                          Remove
                        </v-btn>
                      </v-card-actions>
                    </v-row>
                  </v-card>
                </v-col>

              </v-row>
            </v-card>
          </v-form>
        </v-col>
        <v-col cols="4">
          <v-row class="ma-0">
            <v-text-field
                class="mt-3"
                color="primary"
                background-color="white"
                :error-messages="errorUser ? 'Must be filled' : ''"
                v-model="searchUser"
                :items="items"
                label="Search Users"
                outlined
                hide-details
            >
            </v-text-field>
            <v-card height="300" width="100%" class="overflow-y-auto mt-3 d-flex justify-center">
              <v-progress-circular
                  v-if="loadingUsers"
                  indeterminate
                  color="primary"
                  class="mt-3"
              ></v-progress-circular>
              <v-card width="100%" v-else>
                <v-list>
                  <v-list-item @click="addToList(item)" :class="[{'selected-list': value.find(e => e.id === item.id)}, 'pa-0']"  v-for="item in items" :key="item.id">
                    <v-list-item-avatar class="ml-3">
                      <v-avatar left>
                        <v-img :src="$getAvatarImage(item)"></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.name"></v-list-item-title>
                      <v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-card>
          </v-row>
          <!--      <v-row class="mt-3 ma-0">-->
          <!--        <v-btn width="200" @click="allSelected" color="primary">{{ translationsAgencyLogPayOut.btn_select_all_users }}</v-btn>-->
          <!--      </v-row>-->
          <v-row class="mt-3 ma-0">
            <v-btn width="200" @click="removeSelected" dark color="red">{{ translationsAgencyLogPayOut.btn_remove_all_users }}</v-btn>
          </v-row>

          <v-card-text class="pl-0">{{ translationsAgencyLogPayOut.note }}</v-card-text>

          <v-row class="ma-0 mt-3 ">
            <v-col cols="12" class="mb-4 pa-0">
              <div class="d-flex align-center">
                <v-btn class="mr-3" color="success" @click="sendWithdrawal" :disabled="isSendDisabled">{{ translationsAgencyLogPayOut.btn_withdrawal }}</v-btn>
                <span> {{ translationsAgencyLogPayOut.total_label }}: {{ totalAmount }} $</span>
              </div>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-alert v-model="alertErr" type="error">{{ alertText }}</v-alert>
            </v-col>

          </v-row>
        </v-col>
      </v-row>



    </v-container>
  </div>
</template>

<script>
import {api} from "@/providers/api";
import {getAgencyById} from "@/api/agencyRequest";
import PreloaderPage from "@/components/PreloaderPage.vue";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import {mapGetters} from "vuex";
import {debounce} from "lodash";

export default {
  name: "AgencyPayOut",
  components: {MyBreadcrumbs, PreloaderPage},
  // props: {
  //   items: {
  //     type: Array,
  //     default: () => [],
  //   },
  //   agency: {
  //     type: Object,
  //     default: () => {
  //     },
  //   },
  //   balance: {
  //     type: String,
  //     required: true
  //   }
  // },
  data() {
    return {
      hiddenElement: false,
      loadingActivity: false,
      currentTab: 7,
      value: [],
      items: [],
      agency: [],
      loading: false,
      balance: '',
      agencyBalance: null,
      errorUser: false,
      alertPayOut: false,
      selected: false,
      alertErr: false,
      alertText: '',
      agencyName: '',
      agencyPayPal: false,
      loadingUsers: false,
      searchUser: ''
    }
  },
  computed: {
    ...mapGetters('Agencies', [
      'getterDataById', 'getterAgencyById'
    ]),
    isSendDisabled() {
      return this.value.some(user => {
        return !this.withdrawalAmountRule(user, user.withdrawalAmount);
      }) || (!this.withdrawalAmountAgencyRule && this.selected) || this.loadingActivity;
    },
    withdrawalAmountRule() {
      return (user, value) => {
        return !((user.isPayPal && value > user.paypalBalance.paypalBalanceAvailable) || (!user.isPayPal && value > user.paypalBalance.creditCardBalance));
      }
    },
    withdrawalAmountAgencyRule() {
      return !((this.agencyPayPal && this.agencyBalance > this.balance.paypalBalanceAvailable) || (!this.agencyPayPal && this.agencyBalance > this.balance.creditCardBalance));
    },
    translations() {
      return this.$t('translation')
    },
    translationsAgencyLog() {
      return this.translations.AGENCY.AGENCY_LOG
    },
    translationsAgencyLogPayOut() {
      return this.translationsAgencyLog.pay_out_page
    },
    currentAgency() {
      return this.getterAgencyById(+this.$route.params.id)
    },

    breadcrumbs() {
      return this.$auth.isAdmin ?  [
        { text: this.translations.NAVBAR.DASHBOARD.title, href: '/' },
        { text: this.translations.AGENCY.AGENCIES.title, href: '/agencies' },
        { text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff' },
        { text: this.translationsAgencyLogPayOut.title, disabled: true,}
      ] : [
        {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff'},
        {text: this.translationsAgencyLogPayOut.title, disabled: true,}
      ];
    },
    totalAmount() {
      const totalAmount = this.value.reduce((total, item) => {
        const amount = parseFloat(item.withdrawalAmount || 0);
        return total + amount;
      }, 0);

      console.log('totalAmount', totalAmount)
      if (this.selected) {
        let allAmount = totalAmount + (this.agencyBalance === null ? 0 : parseFloat(this.agencyBalance))
        console.log('allAmount', this.agencyBalance)

        return Number(parseFloat(allAmount.toFixed(2)))
      }

      return totalAmount
    }
  },
  async created() {
    if (this.currentAgency?.name === undefined) {
      this.loadingActivity = true;
    }
    this.loading = true
    try {
      this.agency = await this.$store.dispatch("Agencies/fetchAgencyById", {id: this.$route.params.id})
      console.log('agency', this.agency)
      this.items = await api
          .get(`/admin/agencies/${this.$route.params.id}/users`, {
            params: {
              limit: 100,
              page: 1,
            }
          })
          .then(r => r.data);
      this.balance = await this.getFullBalance(this.$route.params.id)
      console.log('this.balance', this.balance)
    } catch (e) {

    } finally {
      this.loading = false
      this.loadingActivity = false
    }
  },
  mounted() {
    this.value.forEach(user => {
      this.$set(user, 'withdrawalAmount', 0);
    });
  },
  watch: {
    async searchUser(val) {
      this.loadingUsers = true
      if (val === '') {
        this.items = await api
            .get(`/admin/agencies/${this.$route.params.id}/users`, {
              params: {
                limit: 100,
                page: 1,
              }
            })
            .then(r => r.data);
        this.loadingUsers = false
      } else {
        await this.debouncedSearch()
      }

    }
    // value(val) {
    //    if(Object.keys(val).length > 0) {
    //      this.errorUser = false
    //    }
    // }
  },
  methods: {
    debouncedSearch: debounce(async function () {
      this.items = await api
          .get(`/admin/agencies/${this.$route.params.id}/users`, {
            params: {
              limit: 100,
              page: 1,
              search: this.searchUser
            }
          })
          .then(r => r.data);
      this.loadingUsers = false
    }, 400),
    async getFullBalance(id, isAgency = true) {
      const data = isAgency ? {agencyId: id} : {userId: id};
      return await api.get('/admin/agencies/paypal-balance', {
        params: {
          ...data
        }
      })
    },
    // withdrawalAmountRule(user) {
    //   console.log('user', user)
    //   return (value) => {
    //     console.log('value', value)
    //     return !((user.isPayPal && value > user.paypalBalance.paypalBalanceAvailable) || (!user.isPayPal && value > user.balance));
    //
    //   };
    // },
    // withdrawalAmountAgencyRule() {
    //   return (user) => {
    //     if (+user > +this.$getBalance(this.balance)) {
    //       console.log('user', user, this.$getBalance(this.balance))
    //       return `Withdrawal amount can't be greater than balance (${this.$getBalance(this.balance)}$)`;
    //     }
    //     return true;
    //   };
    // },
    allSelected() {
      this.value = this.items
    },
    removeSelected() {
      this.value = []
    },

    addToList(user) {
      const find = this.value.findIndex(e => e.id === user.id)
      if (find === -1) {
        this.value.push(user)
      } else {
        this.remove(user.id)
      }
    },
    remove(id) {
      console.log(id, this.value)
      const index = this.value.findIndex(item => item.id === id);
      if (index !== -1) {
        this.value.splice(index, 1);
      }
    },


    async sendWithdrawal() {
      if (this.$refs.form.validate()) {
        const users = this.value
            .map(r => {
              if (r.withdrawalAmount !== undefined && +r.withdrawalAmount !== 0 && r.withdrawalAmount !== '') {
                console.log('user: ', r)
                return {
                  userId: r.id,
                  amount: parseFloat(r.withdrawalAmount),
                  isPaypal: !!r?.isPayPal
                }
              }
            })
            .filter(r => r !== undefined && !isNaN(r.amount) && r.amount > 0)
        // console.log(this.agencyBalance === null ? 0 : parseFloat(this.agencyBalance), users)
        console.log('users: ', users)
        if (this.totalAmount < 200) {
          this.alertErr = true
          this.alertText = 'Minimum withdrawal amount - 200$'
          setTimeout(() => {
            this.alertErr = false
          }, 3000)
        } else {
          try {
            this.loadingActivity = true
            await api.post(`/admin/agencies/${this.$route.params.id}/withdrawal`, {
              agencyAmount: this.selected ? this.agencyBalance === null ? 0 : parseFloat(this.agencyBalance) : 0,
              isPaypal: this.agencyPayPal,
              payoutPairs: users
            })
            this.value.map(r => r.withdrawalAmount = 0)
            this.agency = await getAgencyById(parseInt(this.$route.params.id))
            this.removeSelected()
            this.balance = this.$getBalance(this.agency.balance.amount)
            this.agencyBalance = null

          } catch (e) {
            this.alertErr = true
            this.alertText = 'Withdrawal error'
            setTimeout(() => {
              this.alertErr = false
            }, 3000)
          } finally {
            this.loadingActivity = false
            this.alertPayOut = true
            setTimeout(() => {
              this.alertPayOut = false
            }, 3000)
          }

        }
      } else {
        this.alertErr = true
        this.alertText = 'Balance exceeded'
        setTimeout(() => {
          this.alertErr = false
        }, 3000)
      }

    },
    userFilter(item, queryText) {
      // console.log('item', item)
      const hasName = item.name.toLowerCase().includes(queryText.toLowerCase())
      const hasEmail = item.email.toLowerCase().includes(queryText.toLowerCase())
      return hasName || hasEmail
    }
  }
}
</script>

<style scoped lang="scss">
.selected-list{
  background: lightgrey;
}
.amount-slider {
  width: 100%;
  max-width: 400px;

  @media (max-width: 1440px) {
    max-width: 200px;
  }
}

//.pay-out {
//  background: #f3f3f3;
//}
.alertSuccess {
  position: fixed;
  z-index: 10;
  right: 15px;
}
.preloader {
  position: absolute;
  top: 0;
}

.disabled {
  opacity: .6;
  pointer-events: none;
}
</style>