<template>
  <div>
    <v-container class="my-5">

      <v-data-table
          :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 50, 100]
      }"
          :items-per-page.sync="itemsPerPage"
          :headers="headers"
          :items="flirts"
          item-key="name"
          class="elevation-1"
          :loading="flirtLoading"
          :server-items-length="flirtTotalItems"
          :options.sync="optionsFlirt"
          :page.sync="page"

      >
        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">{{ translationsFlirtLog.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn dark icon @click="toggleSearchFields">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-toolbar>

          <TableSearchFields
              v-if="showSearchFields"
              :searchFields="searchFields"
              @add-field="addSearchField"
              @remove-field="removeSearchField"
              @update-fields="updateSearchCriteria"
              @clear-fields="clearFields"
          />



          <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>

        </template>
        <template v-slot:[`item.from`]="{ item }">
          <div class="user-img cursor-pointer" @click="userOpen(item.creator)">
            <img :src="$getAvatarImage(item.creator)">
            {{ item.creator.name }}

            <v-icon
                v-if="item.creator.id !== me.id && !$auth.isAdmin  && !$auth.isOperator"
                class="ml-3"
                color="primary"
                @click.stop="$switchProfileUser(item.creator.id)"
            >
              mdi-account-arrow-left
            </v-icon>

          </div>
        </template>

        <template v-slot:[`item.to`]="{ item }">
          <div class="user-img cursor-pointer" @click="userOpen(item.receiver)">
            <img :src="$getAvatarImage(item.receiver)">
            {{ item.receiver.name }}
            <v-icon
                v-if="item.receiver.id !== me.id && !$auth.isAdmin  && !$auth.isOperator"
                class="ml-3"
                color="primary"
                @click.stop="$switchProfileUser(item.receiver.id)"
            >
              mdi-account-arrow-left
            </v-icon>
          </div>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>

        <template v-slot:[`item.gift.file`]="{ item }">
          <v-img :src="$getImage(item.gift.file)" max-height="50"
                 max-width="50"></v-img>

        </template>
        `
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              small
              :disabled="$auth.isOperator"
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:footer>
          <div class="input-page">
            <v-row align="center" justify="start" align-content="center" class="mx-2">
              <v-col cols="12" md="3">
                <v-text-field
                    single-line
                    hide-details
                    label="Go to page"
                    v-model="pageInput"
                    @change="updatePage"
                    type="number"
                    min="1"
                    :max="pageCount"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  Pages: {{ page }} / {{ pageCount }}
                </span>
              </v-col>
            </v-row>
          </div>

        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>


import {deleteFlirt, getFlirt} from "@/api/flirtReqiest";
import {getUserFlirt, getUserLike} from "@/api/usersRequest";
import router from "@/routes/router";
import {mapGetters} from "vuex";
import DeleteModal from "@/components/DeleteModal";
import TableSearchFields from "@/components/TableSearchFields.vue";


export default {
  name: 'FlirtLogs',
  components: {TableSearchFields, DeleteModal},
  data() {
    return {
      dialogDelete: false,
      deleteId: '',
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',

        },

        {
          text: 'FROM',
          value: 'from',
          sortable: false,

        },
        {
          text: 'TO',
          value: 'to',
          sortable: false,

        },
        {
          text: 'GIFT',
          value: 'gift.file',
          sortable: false,

        },
        {
          text: 'Registered At',
          value: 'createdAt',
          sortable: false,

        },
        {
          text: 'ACTION',
          value: 'actions',
          sortable: false,
        },

      ],

      flirts: [],

      editedIndex: -1,
      editedItem: {
        id: '',
        from: '',
        to: '',
        avatar: '',
        avatar2: '',
        gift: {
          file: ''
        }
      },
      flirtTotalItems: 0,
      flirtLoading: true,
      optionsFlirt: {},
      page: 1,
      pageInput: 1,
      itemsPerPage: 10,

      showSearchFields: false,
      searchFields: {
        id: [{value: '', multiple: true, name: 'id'}],
        from: [{value: '', multiple: true, name: 'from'}],
        to: [{value: '', multiple: true, name: 'to'}],
        sent: [{value: '', multiple: false, name: 'sent'}]
      },
      searchCriteria: []
    };
  },
  async created() {
    this.headers = [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id',

      },

      {
        text: this.translationsFlirtLog.headers.from.toUpperCase(),
        value: 'from',
        sortable: true,

      },
      {
        text: this.translationsFlirtLog.headers.to.toUpperCase(),
        value: 'to',
        sortable: true,

      },
      {
        text: this.translationsFlirtLog.headers.gift.toUpperCase(),
        value: 'gift.file',
        sortable: false,

      },
      {
        text: "SENT",
        value: 'createdAt',
        sortable: true,

      },
      {
        text: this.translationsFlirtLog.headers.actions.toUpperCase(),
        value: 'actions',
        sortable: false,
      },

    ]
    this.fetchFlirt()
  },
  watch: {
    optionsFlirt: {
      handler(change) {
        console.log('change flirt', change)
        this.syncFlirtsFromApi()
      },
      deep: true
    },
    page(newVal) {
      this.pageInput = newVal;
    },
  },
  computed: {
    ...mapGetters('User', ['me']),
    translations() {
      return this.$t('translation')
    },
    translationsFlirtLog() {
      return this.translations.FLIRT_LOG.table
    },
    pageCount() {
      return Math.ceil(this.flirtTotalItems / this.itemsPerPage);
    },
  },
  methods: {
    async clearFields() {
      this.searchFields = {
        id: [{value: '', multiple: true, name: 'id'}],
        from: [{value: '', multiple: true, name: 'from'}],
        to: [{value: '', multiple: true, name: 'to'}],
        created: [{value: '', multiple: false, name: 'sent'}]
      }
      this.searchCriteria = []
    },
    toggleSearchFields() {
      this.showSearchFields = !this.showSearchFields;
    },
    addSearchField(data) {
      const {searchFields, category} = data
      this.searchFields = searchFields
      this.searchFields[category].push({value: ''});
    },
    removeSearchField(data) {
      const {category, index} = data
      this.searchFields[category].splice(index, 1);
    },
    async updateSearchCriteria(data) {
      const {searchFields, searchCriteria} = data
      this.searchFields = searchFields
      this.searchCriteria = searchCriteria
      if (this.page !== 1) {
        this.page = 1
      } else {
        await this.syncFlirtsFromApi();
      }
    },
    updatePage() {
      let page = parseInt(this.pageInput, 10);
      if (page > 0 && page <= this.flirtTotalItems) {
        this.page = page;
      } else {
        this.pageInput = this.page;
      }
    },
    async fetchFlirt() {
      this.flirts = await getFlirt().then(r => r.data);
      return this.flirts
    },

    async syncFlirtsFromApi() {
      this.flirtLoading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsFlirt
        const response = await getFlirt({
          params: {
            ...sortBy.length ? {
              createdAtSort: sortBy.map((s, i) => (sortDesc[i] ? 'DESC' : 'ASC'))[0],
              sortField: sortBy[0]
            } : '',
            limit: limit === -1 ? 100 : limit,
            page,
            ...this.searchCriteria
          }
        });

        const {data: items, total} = response

        this.flirts = items;
        this.flirtTotalItems = total
      } catch (e) {
        console.log('error' + JSON.stringify(e))
      } finally {
        this.flirtLoading = false
      }
    },
    async userOpen(user) {
      const dataLike = await getUserLike(user.id);
      const dataFlirt = await getUserFlirt(user.id);
      const obj = {
        like: {
          dataLike
        },
        flirt: {
          dataFlirt
        }
      };
      console.log(user)
      router.push({name: 'user-log', params: {id: user.id, targetUser: user, obj: obj}})
    },
    deleteItem(item) {
      this.editedIndex = this.flirts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.deleteId = item.id
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.flirts = this.flirts.filter(e => e.id !== this.deleteId)

      await deleteFlirt(this.deleteId)
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
  async mounted() {

  }
};
</script>

<style scoped lang="scss">
img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.title {
  color: #f4f6f9;
}


</style>
