<template>
  <div>
    <v-container :class="{'disabled' : activityLoadingStatic}">
      <MyBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-tabs v-model="currentTab" class="mb-3" background-color="#f4f6f9" color="primary">
        <v-tab to="staff">
          STAFF
        </v-tab>
        <v-tab to="transactions">
          {{ translationsAgencyLog.breadcrumbs.transactions }}
        </v-tab>
        <v-tab to="chats">
          Chats
        </v-tab>
        <v-tab to="activities">
          {{ translationsAgencyLog.breadcrumbs.activities }}

        </v-tab>
        <v-tab v-if="!this.$auth.isAdmin" to="withdrawal">
          {{ translationsAgencyLog.breadcrumbs.withdrawal }}
        </v-tab>
        <v-tab to="stats">
          {{ translationsAgencyLog.breadcrumbs.stats }}
        </v-tab>
        <v-tab to="settings">
          {{ translationsAgencyLog.breadcrumbs.settings }}
        </v-tab>
        <v-tab to="pay-out">
          {{ translationsAgencyLog.breadcrumbs.pay_out }}
        </v-tab>
      </v-tabs>
    </v-container>
    <PreloaderPage class="preloader"  v-if="activityLoading || activityLoadingStatic"/>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headersActivity"
          :items="getterDataById('activity', this.$route.params.id) ? getterDataById('activity', this.$route.params.id) : []"
          item-key="name"
          class="elevation-1"
      >

        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
        <template v-slot:[`item.sender`]="{ item }">
          <div class="user-img cursor-pointer" @click="userPush(item.sender)">
            <img :src="$getAvatarImage(item.sender)">
            {{ item.sender.profile.name }}
          </div>
        </template>
        <template v-slot:[`item.receiver`]="{ item }">
          <div class="user-img cursor-pointer" @click="userPush(item.receiver)">
          <img :src="$getAvatarImage(item.receiver)">
          {{ item.receiver.profile.name }}
        </div>
        </template>

      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {getActivityById, getAgencyById} from "@/api/agencyRequest";
import PreloaderPage from "@/components/PreloaderPage.vue";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import {mapGetters} from "vuex";
import router from "@/routes/router";

export default {
  name: "AgencyLogActivities",
  components: {MyBreadcrumbs, PreloaderPage},
  data: () => ({
    currentTab: 3,
    activityLoading: false,
    activityLoadingStatic: false,
    agencyName: '',
    activity: [],
    headersActivity: [
      {text: 'ID', align: 'start', value: 'id', sortable: true},
      {text: 'Sender', value: 'sender', sortable: false},
      {text: 'Receiver', value: 'receiver', sortable: false},
      {text: 'Created', value: 'createdAt', sortable: false},
      {text: 'Type', value: 'eventType', sortable: false},
    ],
  }),
  async created() {
    this.headersActivity = [
      {text: 'ID', align: 'start', value: 'id', sortable: true},
      {text: this.translationsAgencyLogActivities.headers.sender, value: 'sender', sortable: false},
      {text: this.translationsAgencyLogActivities.headers.receiver, value: 'receiver', sortable: false},
      {text: this.translationsAgencyLogActivities.headers.created_at, value: 'createdAt', sortable: false},
      {text: this.translationsAgencyLogActivities.headers.type, value: 'eventType', sortable: false},
    ]
    if (this.currentAgency?.name === undefined) {
      this.activityLoadingStatic = true;
      await this.$store.dispatch("Agencies/fetchAgencyById", {id: this.$route.params.id})
      this.activityLoadingStatic = false;
    }
    await this.fetchActivity()
  },
  computed: {
    ...mapGetters('Agencies', [
      'getterDataById', 'getterAgencyById'
    ]),
    translations() {
      return this.$t('translation')
    },
    translationsAgencyLog() {
      return this.translations.AGENCY.AGENCY_LOG
    },
    translationsAgencyLogActivities() {
      return this.translationsAgencyLog.table_activities
    },
    currentAgency() {
      return this.getterAgencyById(+this.$route.params.id)
    },

    breadcrumbs() {
      return this.$auth.isAdmin ?  [
        { text: this.translations.NAVBAR.DASHBOARD.title, href: '/' },
        { text: this.translations.AGENCY.AGENCIES.title, href: '/agencies' },
        { text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff' },
        { text: this.translationsAgencyLogActivities.title, disabled: true,}
      ] : [
        {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff'},
        {text: this.translationsAgencyLogActivities.title, disabled: true,}
      ];
    },
  },
  methods: {
    userPush(user) {
      if (this.$auth.isAdmin) {
        router.push({name: 'user-log', params: {id: user.id, targetUser: user}})
      }
      else {
        if (user.agency && Number(user.agency.agencyId) === Number(this.$route.params.id)) {
          router.push({name: 'user-log', params: {id: user.id, targetUser: user}})
        }
      }

    },
    async fetchActivity() {
      this.activityLoading = true
      try {
        let request = await getActivityById(this.$route.params.id);
        this.activity = request.map(e => {
          switch (e.eventType) {
            case 'message':
              return {
                eventType: e.eventType,
                sender: e.event.sender,
                receiver: e.receiver,
                id: e.event.id,
                createdAt: e.createdAt
              };
            case 'flirt':
              return {
                eventType: e.eventType,
                sender: e.event.creator,
                receiver: e.event.receiver,
                id: e.event.id,
                createdAt: e.createdAt
              };
            case 'like':
              return {
                eventType: e.eventType,
                sender: e.event.from,
                receiver: e.event.to,
                id: e.event.id,
                createdAt: e.createdAt
              };
          }
        });
        await this.$store.dispatch("Agencies/addData", { key: 'activity', id: this.$route.params.id, value: this.activity })

      } finally {
        this.activityLoading = false
      }

    },
  }
}
</script>

<style scoped>
.preloader {
  position: absolute;
  top: 0;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}
.disabled {
  opacity: .6;
  pointer-events: none;
}
</style>