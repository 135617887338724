import { render, staticRenderFns } from "./WithdrawalOfFunds.vue?vue&type=template&id=5880961a&scoped=true"
import script from "./WithdrawalOfFunds.vue?vue&type=script&lang=js"
export * from "./WithdrawalOfFunds.vue?vue&type=script&lang=js"
import style0 from "./WithdrawalOfFunds.vue?vue&type=style&index=0&id=5880961a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5880961a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VChip,VCol,VContainer,VDataTable,VSelect,VToolbar,VToolbarTitle})
