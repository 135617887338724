<template>
  <div>
    <v-card-title>
      Filter
    </v-card-title>

    <v-row class="ma-0">
      <v-col cols="4">
        <v-select
            outlined
            hide-details
            v-model="filter.selectedIsAgency"
            :items="optionsIsAgency"
            :label="translationsEmailManagerEdit.filter_block.is_agency_label"
        ></v-select>
      </v-col>
      <v-col  cols="4">
        <v-select
            outlined
            hide-details
            v-model="filter.selectedIsBoy"
            :items="optionsIsBoy"
            :label="translationsEmailManagerEdit.filter_block.gender_label"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
            outlined
            hide-details
            v-model="filter.selectedTariff"
            :items="optionsTariff"
            :label="translationsEmailManagerEdit.filter_block.tariff_label"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="ma-0">
<!--      <v-col cols="4">-->
<!--        <v-select-->
<!--            outlined-->
<!--            hide-details-->
<!--            v-model="filter.selectedStatus"-->
<!--            :items="optionsStatus"-->
<!--            :label="translationsEmailManagerEdit.filter_block.status_label"-->
<!--        ></v-select>-->
<!--      </v-col>-->
      <v-col cols="4">
        <v-autocomplete
            outlined
            hide-details
            v-model="filter.selectedCountry"
            :items="$getCountries()"
            item-text="name"
            item-value="code"
            :label="translationsEmailManagerEdit.filter_block.country_label"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
            hide-details
            outlined
            :label="translationsEmailManagerEdit.filter_block.agency_label"
            :items="allAgencies"
            item-text="name"
            item-value="id"
            v-model="filter.selectedAgency"

        />
      </v-col>
    </v-row>
    <v-divider class="ma-2"/>
    <v-card-subtitle class="pa-0 pl-3">
      {{ translationsEmailManagerEdit.filter_block.balance_range_label }}
    </v-card-subtitle>
    <v-row class="ma-0">
      <v-col>
        <v-range-slider
            v-model="filter.balanceValue"
            max="10000"
            min="0"
            hide-details
            class="align-center"
        >
          <template v-slot:prepend>
            <v-text-field
                :value="filter.balanceValue[0]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
                :value="filter.balanceValue[1]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
            ></v-text-field>
          </template>
        </v-range-slider>

      </v-col>
    </v-row>
    <v-divider class="ma-2"/>
    <v-card-subtitle class="pa-0 pl-3">
      {{ translationsEmailManagerEdit.filter_block.age_range_label }}
    </v-card-subtitle>
    <v-row class="ma-0">
      <v-col>
        <v-range-slider
            v-model="filter.ageValue"
            max="99"
            min="0"
            hide-details
            class="align-center"
        >
          <template v-slot:prepend>
            <v-text-field
                :value="filter.ageValue[0]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
                :value="filter.ageValue[1]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
            ></v-text-field>
          </template>
        </v-range-slider>

      </v-col>

    </v-row>

    <v-divider class="ma-2"/>

    <v-row class="ma-0">
      <v-col>
        <v-autocomplete
            :label="translationsEmailManagerEdit.filter_block.name_or_email_label"
            v-model="autoCompleteInput"
            :search-input.sync="searchUser"
            :items="users"
            :item-text="getItemText"
            item-value="id"
            color="primary"
            multiple
            return-object
            outlined

        >
          <template v-slot:selection="data">
            {{ data = '' }}
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar>
              <img :src="$getAvatarImage(item)">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
            </v-list-item-content>

          </template>
        </v-autocomplete>

      </v-col>

    </v-row>

    <v-row class="ma-0">
      <v-col>
        <v-chip
            v-for="user in autoCompleteInput"
            :key="user.id"
            color="primary"
            class="mr-2 mb-2"
            close
            @click:close="onChipClose(user.id)"

        >
          <v-avatar left>
            <v-img :src="$getAvatarImage(user)"></v-img>
          </v-avatar>
          {{ user.name }} ({{ user.email }})
        </v-chip>

      </v-col>

    </v-row>
  </div>
</template>

<script>
import {api} from "@/providers/api";
import {mapActions} from "vuex";

export default {
  name: "CampaignsFilter",
  props: {
    usersStatic: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    optionsTariff: [],
    changeUsersStatic: false,
    autoCompleteInput: [],
    searchUser: null,
    users: [],
    optionsIsAgency: [{text: '', value: ''}, {text: 'Yes', value: true}, {text: 'No', value: false}],
    optionsIsBoy: [{text: '', value: ''}, {text: 'Man', value: true}, {text: 'Woman', value: false}],
    optionsStatus: [{text: '', value: ''}, {text: 'Active', value: 1}, {text: 'Review', value: 0}, {
      text: 'Blocked',
      value: 2
    }],
  }),
  async created() {
    await this.fetchData();
    this.changeUsers();
  },
  watch: {
    usersStatic() {
      this.changeUsers();
    },
    autoCompleteInput() {
      if(!this.changeUsersStatic) {
        this.$emit('users',this.autoCompleteInput)
      }
    },
    searchUser: {
      handler() {
        this.syncSearchUsersFromApi()
      },
      deep: true
    },
  },
  computed: {
    filter() {
      return this.data
    },
    translations() {
      return this.$t('translation')
    },
    translationsEmailManagerEdit() {
      return this.translations.EMAIL_MANAGER.email_manager_edit_page
    },
    allAgencies() {
      return this.$store.state.Agencies.allAgencies
    },
  },
  methods: {
    ...mapActions('Agencies', ['fetchAllAgencies']),
    changeUsers() {
      this.changeUsersStatic = true;
      this.autoCompleteInput = this.usersStatic
      setTimeout(() => {
        this.changeUsersStatic = false;
      }, 250)
    },
    async fetchData() {
      await this.fetchAllAgencies();
      await this.fetchTariffs();
    },
    async fetchTariffs() {
      const response = await api.get('/membership-tariffs')
      const oldTarrif = response.map(r => {
        return {
          text: r.name,
          value: r.id
        }
      });
      oldTarrif.unshift({text: '', value: ''});
      this.optionsTariff = oldTarrif
    },
    onChipClose(userId) {
      this.autoCompleteInput = this.autoCompleteInput.filter(user => user.id !== userId);
    },
    getItemText(item) {
      return `${item.name} (${item.email})`;
    },
    async syncSearchUsersFromApi() {
      if (this.searchUser.trim().length <= 0) {
        this.users = []
      } else {
        try {
          const response = await api.get('/admin/users', {
            params: {
              search: this.searchUser.trim()
            }
          });
          this.users = response.data
          console.log('this.users', this.users)

        } catch (e) {
          console.log('error', e);

        } finally {

        }
      }

    },

  }
}
</script>

<style scoped>

</style>